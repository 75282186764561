import { React, useState } from 'react'
import axios from 'axios'
import {Button, Grid, TextField, Paper, Link } from '@material-ui/core'

import {textfield90, title} from './styles.js'
import {URLS} from './util.js'
import { notify } from './notifications.js'
import SocialLogin from './SocialLogin'
import Header from './Header'



export default function SignIn() {
    const [ email, setEmail ] = useState();
    const [ password, setPassword ] = useState();

    const signin = async (e)  => {
        e.preventDefault()
        try {
            const r =  await axios.post(URLS.SERVER + '/auth/', {email: email, password: password})
            localStorage.setItem('token', r.data.token);
            localStorage.setItem('name', r.data.name);
            localStorage.setItem('position', r.data.position);
            window.location.href = URLS.FEED
        } catch (e) {
            try {            
                notify(e.response.data, 'warning')
            } catch (e) {
                notify('There is no connection to server. Please try again later.', 'danger')
            }
        }

    }

    return (
        <Grid container style={{height: '100vh'}}>
            <Header hide={true}/>
            <Grid item xs={false} sm={false} md={3} lg={4}></Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} component={Paper} elevation={5} style={{height: '80vh', marginBottom: 20}}>
            <div style={{alignItems: 'center'}}>
                <div style={{marginTop: 20, marginBottom: 20}}>
                    <span style={title}> Sign In </span>
                </div>
                <form onSubmit={signin}>
                    <TextField variant="outlined" margin="normal" required style={textfield90}
                        fullWidth label="Email" name="email" size='small'
                        autoComplete="email" onChange={e => setEmail(e.target.value)} />
                    <TextField variant="outlined" margin="normal" required fullWidth style={textfield90} size='small'
                        name="password" label="Password" type="password" autoComplete="current-password" 
                        onChange={e => setPassword(e.target.value)}/>
                    <br/> <br/> 
                    <Button type="submit" fullWidth variant="contained" style={{width: '90%', marginBottom: 10}} >
                        Sign In
                    </Button>
                    <br/>
                    <Grid container>
                        <Grid item xs={7} sm={8} md={7} lg={7} style={{paddingLeft: 0}}>
                            <Link href={URLS.SIGNUP} >
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                        <Grid item xs={4} sm={3} md={4} lg={4} style={{marginRight: 10}}>
                            <Link href={URLS.FORGOT} >
                                {"Forgot password? "}
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid container style={{marginTop: 40}}>
                        <Grid item xs={false} sm={4} md={3} lg={3} ></Grid>
                        <Grid item xs={7} sm={6} md={6}>
                            <div>
                                <span style={{fontSize: '1.2rem', color: '#0492C1', marginLeft: 20}}> Sign in with</span>
                                <SocialLogin user={{}} notify={notify} signup={false} />
                            </div>
                        </Grid>
                    </Grid>
                    </form>
                </div> 
            </Grid>
        </Grid>
    )
}