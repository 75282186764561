import { React, useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import axios from 'axios'
import {FaMapMarker} from 'react-icons/fa'
import {MdEmail} from 'react-icons/md'
import { Button, Grid, Link, Badge } from '@material-ui/core'  
import {CgWebsite} from 'react-icons/cg'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import GetRenders, {URLS} from './util.js'
import {notify} from './notifications'
import Header from './Header'
import Footer from './Footer'
import { Label, titleSmall, leftContainerResource, avatarContainer, subtitle,
        rightContainer, tab, titleSmallBlue, title}  from './styles.js'
import { StaticMultipleSelect } from './StaticMultipleSelect.js';
import { Description } from './Description.js';
import { Multimedia } from './Multimedia.js';
import ScientificDomainMap from './ScientificDomainMap.js'
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    badge: {
            height: 30,
            width: 200
    },
})


export default function Resource() {
    const classes = useStyles()
    const params = useParams()
    const [resource, setResource] = useState()
    const [open, setOpen] = useState(false)
    const [edit, setEdit] = useState(false)
    const [value, setValue] = useState('two')
    const [resourceContact, setResourceContact] = useState({}) 
    const tablet = GetRenders().DOWN_SM
    const md = GetRenders().DOWN_MD

    
    useEffect(() => {
        async function fetch() {
            try {
                    const r = await axios.get(URLS.SERVER + '/resources/resource_version/?id=' + params['id'] + '&date=' + params['update'], 
                                    {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                    setResource(r.data)
            } catch (e) {
                    console.log(e)
                    try {            
                            notify(e.response.data, 'warning')
                    } catch (e) {
                            notify('There is no connection to server. Please try again later.', 'danger')
                    }
            }
        }
            fetch()
    }, [])


    if (resource && params['edit']) {
    return (
        <Grid container style={{height: '110vh', opacity: open ? 0.3 : 1}}>
            <Header/>
            <Grid container style={avatarContainer}>
                <img style={{marginLeft: 30, width: 130, marginTop: 5}} src={resource['logo'] || resource['logo'] !== null ? resource.logo : '/placeholder1.png'}/>
                <Grid item xs={5} sm={7} md={7} lg={8} style={{marginTop: 10}} >
                    <span style={title}> {resource.name} </span>
                    <br/>
                    <span style={subtitle}> {resource.tagline}</span> 
                </Grid>
                <Grid item xs={false} sm={2} md={2} lg={2} >
                    <span style={titleSmallBlue}> Version</span> 
                    <br/>
                    <Badge badgeContent={params['update']} color='primary' classes={{badge: classes.badge}}/>
                </Grid>
            </Grid>
            <Grid item xs={11} sm={11} md={4} lg={5} style={leftContainerResource}>
                <div style={{borderBottom: '1px solid', borderColor: '#E8E8E8', paddingBottom: 20, marginBottom: 5, marginTop: 20}}>
                    <Description value={resource.description} profile={true}/>
                    {resource.tags.length !== 0 ?
                        <>
                                <br/>
                                {resource.tags.map((tag, i) => 
                                        <Link key={i} > #{tag} </Link>
                                )} 
                        </>
                    :null}
                </div>
                <br/>
                <div style={{borderBottom: '1px solid', borderColor: '#E8E8E8', marginBottom: 10, marginLeft: -5, width: '99.4%', paddingBottom: 10}}>
                        <Label value='Available ' disableUnderline readOnly/>
                        {resource.geographical_availability.map((item, i) => 
                            <span key={i}>{item} </span>
                            )} 
                        {resource['access_type'].length !== 0 ? 
                        <>
                            <br/>
                            <Label value='Access type ' disableUnderline readOnly/> 
                            {resource.access_type.map((item, i) => 
                                <span key={i}>{item} </span>
                                )} 
                        </>
                        : null} 
                    {resource['access_mode'].length !== 0 ? 
                    <>
                        <br/>
                        <Label value='Access mode ' disableUnderline readOnly/> 
                        {resource.access_mode.map((item, i) => 
                            <span key={i}>{item} </span>
                            )} 
                    </>
                    : null}    
                </div>
                <div style={{borderBottom: '1px solid', borderColor: '#E8E8E8', marginBottom: 10, marginLeft: -5, width: '99.4%', paddingBottom: 10}}>
                    <Label value='Language ' disableUnderline readOnly/> 
                    {resource.language_availability.map((item, i) => 
                        <span key={i}>{item} </span>
                        )}   
                </div>  
                <div style={{borderBottom: '1px solid', borderColor: '#E8E8E8', marginBottom: 10, marginLeft: -5, width: '99.4%', paddingBottom: 10}}>
                    <Label value='Target users ' disableUnderline readOnly/> 
                    {resource.target_users.map((item, i) => 
                        <span key={i}>{item} </span>
                        )} 
                </div>   
                {resource.required_resources.length !== 0 ?
                    <div style={{borderBottom: '1px solid', borderColor: '#E8E8E8', marginBottom: 10, marginLeft: -5, width: '99.4%', paddingBottom: 10}}>
                        <Label value='Required resources ' disableUnderline readOnly/> 
                        {resource.required_resources.map((item, i) => 
                            <span key={i}>{item} </span>
                            )} 
                    </div>   
                 : null}     
                    <br/> <br/>
                    <Grid container style={{backgroundColor: 'white', borderRadius: 10, marginLeft: -5, paddingBottom: 10}}>
                        <div style={{borderBottom: '1px solid',  borderColor: '#0A50C7',  marginLeft: 10,  width: '96%', 
                                    marginTop: 12,  marginBottom: 10, paddingBottom: 3}}>
                            <span style={titleSmall}> Contact information </span> 
                        </div>
                        <Button variant='filled' startIcon={<CgWebsite size={20} style={{color: '#0492C1', width: '1.8vw', height: '1.8vw', maxWidth: 50,
                                                                                        minWidth: 25, minHeight: 25, }}/>} 
                                    style={{textTransform: 'none', marginLeft: 10}} onClick={() =>  window.location.href=resource.website}>
                            {resource.website.split('//www.').slice(1)}
                        </Button>
                        {Object.keys(resourceContact).length !== 0 ? 
                            <Button variant='filled' startIcon={<MdEmail style={{color: '#0492C1', width: '1.8vw', height: '1.8vw', maxWidth: 50,
                                                    minWidth: 25, minHeight: 25, }}/>} 
                                style={{textTransform: 'none', marginLeft: 10}} href={'mailto:' + resourceContact['email']}>
                                {resourceContact['email']}
                            </Button>
                        : null} 
                         {resource.resource_geographical_location.length !== 0 ?
                                <Button variant='filled' startIcon={<FaMapMarker size={20} style={{color: '#0492C1', width: '1.8vw', height: '1.8vw', maxWidth: 50,
                                                                                        minWidth: 25, minHeight: 25, }}/>} 
                                    style={{textTransform: 'none', marginLeft: 10}} >
                                    {resource.resource_geographical_location}
                                </Button>
                        : null}
                    </Grid>
            </Grid>
            <Grid item xs={11} sm={11} md={8} lg={6} >
                <Tabs value={value} onChange={(e, newValue) => setValue(newValue)} style={{backgroundColor: 'white', width: tablet ? '100%' : '83%', borderLeft: `1px solid`, borderColor: '#E8E8E8', borderRadius: 10}} 
                        orientation='horizontal' textColor="secondary">
                    {resource.multimedia.length !== 0 ? 
                        <Tab value="one" label="Photos & videos" style={tab} />
                    : null}
                    <Tab value="two" label="About" style={tab} />
                    {resource['lifecycle_status'] ||  resource['standards']   ? 
                    <Tab value='three' label='Maturity' style={{textTransform: 'none'}}/>
                    : null}
                </Tabs>
                <Grid item xs={12} sm={12} md={10} lg={10} style={rightContainer}>
                {value === 'one' ?
                    <Multimedia media={resource.multimedia} resource_id={resource.uuid}/>
                : null}
                {value === 'two' ?
                    <Grid container style={{marginLeft: -25}}>  
                        {resource.scientific_domain.length !== 0 ?
                            <Grid container >
                                    <Grid item xs={12} ms={12} md={12} lg={12} >  
                                            <span style={titleSmallBlue}>Scientific domain</span>
                                    </Grid>
                                    {resource.scientific_domain.map((item, i) => 
                                            <Grid item xs={12} ms={3} md={3} lg={3}>  
                                                    <ScientificDomainMap dm={item} key={i}/>
                                            </Grid>
                                    )}
                            </Grid>
                        : null}
                        <Grid item xs={12} ms={12} md={12} lg={12} >
                                <StaticMultipleSelect value={resource.scientific_subdomain} title='Scientific subdomain' />
                        </Grid>
                        <Grid item xs={12} ms={12} md={12} lg={12} >
                                <StaticMultipleSelect value={resource.category} title='Category' />
                        </Grid>
                        <Grid item xs={12} ms={12} md={12} lg={12} >
                                <StaticMultipleSelect value={resource.subcategory} title='Subcategory' />
                        </Grid>
                        {resource.use_cases.length !== 0 ?
                            <Grid item xs={12} ms={6} md={6} lg={6} >
                                <StaticMultipleSelect value={resource.use_cases} title='Use cases' />
                            </Grid>
                        : null}
                        {resource['certifications'].length !== 0  ? 
                        <Grid item xs={12} ms={6} md={6} lg={6} >
                            <StaticMultipleSelect value={resource.certifications} title='Certifications' /> 
                        </Grid>
                    : null} 
                    </Grid> 
                : null }
            {value === 'three' ? 
               <Grid container style={{marginLeft: -25}}>  
                    <Grid item xs={12} sm={12} md={12} lg={126} >
                        <StaticMultipleSelect value={[resource.technology_readiness_level]} title="Technology readiness level" />
                    </Grid>
                    {resource['lifecycle_status'] ? 
                        <Grid item xs={12} sm={12} md={12} lg={126} >
                            <StaticMultipleSelect value={[resource.lifecycle_status]} title='Lifecycle status' />
                        </Grid>
                    : null} 
                    {resource['standards'].length !== 0 ? 
                        <Grid item xs={12} sm={12} md={12} lg={12} >
                            <StaticMultipleSelect value={resource.standards} title='Standards' /> 
                        </Grid>
                    : null}  
               </Grid> :null}
            </Grid>
            <Grid item xs={1} ms={1} md={1} lg={1}>
                <button className="exitButton" onClick={() =>  window.location.href= '../../..' + '/resource/' + resource.uuid} >
                    Go to resource
                </button>
            </Grid> 
        </Grid>
        <Footer/>
    </Grid>
    )} else {
        return null
    }
}