import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { LinkedIn, LinkedInPopUp } from 'react-linkedin-login-oauth2';
import axios from 'axios'
import {URLS} from './util'
import {SiFacebook} from 'react-icons/si';
import {FcGoogle} from 'react-icons/fc';

import './App.css'


export default function SocialLogin(props) {

  const signUp =  async () => {
    try {
      props.user['is_maincontact'] = true
      const r =  await axios.post(URLS.SERVER + '/signup/', props.user)
      localStorage.setItem('token', r.data.token);
      localStorage.setItem('name', r.data.name);
      localStorage.setItem('picture', props.user['picture']); 
      localStorage.setItem('first', true);
      window.location.href = URLS.FEED
    } catch (e) {
        try {            
            props.notify(e.response.data, 'warning')
        } catch (e) {
            props.notify('There is no connection to server. Please try again later.', 'danger')
        }
    }
  }

  const signIn =  async () => {
    try {
        const r =  await axios.post(URLS.SERVER + '/auth/', props.user)
        localStorage.setItem('token', r.data.token);
        localStorage.setItem('name', r.data.name);
        localStorage.setItem('picture', props.user['picture']);
        window.location.href = URLS.FEED
          } catch (e) {
        try {            
            props.notify(e.response.data, 'warning')
        } catch (e) {
            props.notify('There is no connection to server. Please try again later.', 'danger')
        }
    }
  }

  const responseFacebook = (response) => {
      try {
        if (response.accessToken) {
          const name = response['name'].split(' ')
          props.user['first_name'] = name[0]
          props.user['last_name'] = name[1]
          props.user['picture'] = response['picture']['data']['url']
          props.user['email'] = response['email']
          props.user['password'] = response['id']
          props.user['comply_to_terms'] = true
          props.user['social_logged_in'] = 1
          if (props.signup) {
            signUp()
          } else {
            signIn()
          }
        } else {
          props.notify('Facebook login is not available at the moment.', 'warning')
        }
      } catch (e) {
        console.log(e)
      }
    }

    const responseGoogle = (response) => {
      try {
        if (response) {
          const cred = response['profileObj']
          props.user['first_name'] = cred['givenName']
          props.user['last_name'] = cred['familyName']
          props.user['picture'] = cred['imageUrl']
          props.user['email'] = cred['email']
          props.user['password'] = response['googleId']
          props.user['comply_to_terms'] = true
          props.user['social_logged_in'] = 1
          if (props.signup) {
            signUp()
          } else {
            signIn()
          }
        } else {
          props.notify('Google login is not available at the moment.', 'warning')
        }
      } catch (e) {
        console.log(e)
      }
    }

      const responseLinkedin = async (response) => {
        console.log('eee', response.code)
        try {
          const r = await axios.post('https://www.linkedin.com/oauth/v2/accessToken' + 'grant_type=authorization_code&code=' + response['code'] + 
            '&redirect_uri=http://localhost:3000/linkedin/&client_id=786lgvorjeneos&client_secret=vGRcf068yTJEHfvl&scope=r_emailaddress r_liteprofile')
          console.log(r.data)
        } catch (e) {
          console.log(e)
          props.notify('An unexpected error occured. Please try again later.', 'warning')
        }
      }
       

      return (
        <div style={{ marginLeft: 10}}> 
            <FacebookLogin appId="1191084921373285" textButton={false}
                fields="name,email,picture" callback={responseFacebook} 
                icon={<SiFacebook size={29} style={{borderRadius: '30%', background: '#0000', marginBottom: 1}}/>} /> 
            <GoogleLogin
                clientId="950718650673-itrbkfe6flqbkbgrppobf2m3rjhor7fj.apps.googleusercontent.com"
                buttonText={false} onSuccess={responseGoogle} onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'} buttonText={false} 
                render={renderProps => (
                  <FcGoogle onClick={renderProps.onClick} size={32} style={{marginLeft: 20, marginTop: 5}}/>
                )} />
            {/* <LinkedIn clientId="786lgvorjeneos" scope="r_liteprofile r_emailaddress"
                onFailure={responseLinkedin} onSuccess={responseLinkedin}
                redirectUri="http://localhost:3000/linkedin/"
                renderElement={({ onClick, disabled }) => (
                  <SiLinkedin size={30} onClick={onClick} style={{color: '#0077B5'}} />
                )} /> */}
        </div>
      )
}