import { React, useEffect, useState } from 'react'
import axios from 'axios'
import {Grid, Tooltip, Zoom} from '@material-ui/core'    
import Loader from "react-loader-spinner";

import {URLS} from './util.js'
import {notify} from './notifications'
import Footer from './Footer'
import Header from './Header'
import { blackText } from './styles.js'
import { ImageCarousel } from './ImageCarousel'



export default function Partners() {
    const [showLoader, setShowLoader] = useState(false) 

    const partners = [
        'https://www.dissco.eu/wp-content/uploads/dissco-logo-web.svg',
        'https://www.arl-net.de/sites/all/themes/arl/assets/images/logo.svg',
        'https://www.necen.nl/assets/c4928474/img/NeCEN-logo.png',
        'https://cei.iscte-iul.pt/wp-content/uploads/2013/12/CEI-IUL.png',
        'https://www.psi.ch/themes/custom/design/psi.svg',
        'https://www.etw.de/uploads/tpl-buddy-011/img/logo.svg',
        'https://ecrin.org/sites/default/files/logo.jpg',
        'https://www.obs-nancay.fr/wp-content/uploads/2020/05/logo_station5_a.png',
        'https://www7.obs-mip.fr/wp-content-aeris/uploads/sites/66/2020/06/Logo_Color.png',
        'https://www.crg.eu/sites/default/files/logo_1.png',
        'https://www.infrafrontier.eu/sites/infrafrontier.eu/themes/custom/infrafrontier/img/logo-infrafrontier.png',
        'https://www.bbmri-eric.eu/wp-content/themes/bbmri-eric/img/BBMRI-ERIC-gateway-for-health.svg',
        'https://www.arronax-nantes.fr/wp-content/uploads/2018/10/logo-for-sticky-152x74.png',
        'https://www.agendo.science/assets/images/official-logo-19-1014x335.png',
        'https://www.ceric-eric.eu/wp-content/themes/ceric/dist/images/ceric-logo.svg',
        'https://www.ustem.tuwien.ac.at/fileadmin/USTEM/images/logo.jpg'
    ]

    return (
        <Grid container style={{opacity: showLoader ? 0.3 : 1, flexGrow: 1, height: '100vh'}}>
            <Header hide={true}/>
            <Loader type="Oval" color="#0A50C7" height={100} width={100} visible={showLoader} style={{position: 'absolute', top: '50%', left: '50%'}}/>
            <Grid item xs={12} ms={12} md={12} lg={12} style={{paddingBottom: 10}}>
                <span style={{  fontSize: '1.7rem', color: '#0A50C7'}}> Partners  </span>
                <br/>
                <span style={blackText}>
                    Partners are the Providers of resources in Wizbee platform. They are annotated in our platform by this symbol
                </span>
                <Tooltip title='Provider' TransitionComponent={Zoom} placement='top' >
                    <img src='/prov.svg' style={{width: 40, height: 40, marginLeft: 20, paddingTop: 10}}/>
                </Tooltip>
                <p style={blackText}>
                    They were carefully selected by Wizbee team to excel in their domain and offer high quality resources.
                </p>
            </Grid>
            <Grid item xs={12} ms={12} md={12} lg={12} >
                <button className="bigButton" onClick={() => window.location.href=URLS.SIGNUP}> Be a provider now </button>
            </Grid>
            <br/><br/> <br/><br/>
            <Grid item xs={12} ms={12} md={12} lg={12} style={{marginRight: 'auto', width: '94vw', textAlign: 'center', paddingTop: 70, marginTop: -10, paddingBottom: 70}}>
                <span style={{  fontSize: '1.3rem', color: '#0A50C7'}}> Wizbee trusted partners  </span>
                <div >
                    <ImageCarousel images={partners.map((par) => par)}/>
                </div>
            </Grid>
           <Footer />
        </Grid>
    )
}