import { Carousel } from 'react-responsive-carousel';
import './App.css'

import ReactPlayer from 'react-player'


export const Multimedia = (props) => {
    
    const video = (item) => {
        if (item.includes('youtu') || item.includes('vimeo') || 
            item.includes('.mp4') || item.includes('.mpeg') ||
            item.includes('.mpg') || item.includes('.mov') ||
            item.includes('.avi') || item.includes('.webm') ) {
            return true
        } else {
            return false
        }
    }


    return (
        <>
            {props.media.length !== 0 ? 
                <Carousel autoPlay interval={3000} showStatus={false} showThumbs={false} centerMode centerSlidePercentage={100} 
                    showArrows showIndicators={false} infiniteLoop>
                {props.media && props.media.map((item, index) => {
                    if (video(item)) {
                        return (
                            <div style={{paddingTop: '55%', position: 'relative', width: '100%'}} key={index}>
                                <ReactPlayer url={item} width={'100%'} height={'100%'} style={{position: 'absolute', top: 0, left: 0, bottom: 0, right: 0}} playing={false}/>
                            </div>
                        )
                    } else {
                        return (
                            <div style={{paddingTop: '55%', position: 'relative', width: '100%'}} key={index}>
                                <img src={item} style={{width: '90%', position: 'absolute', top: 0, left: 40, bottom: 0, right: 0}}/>
                            </div>
                        )
                    }
                })}
                </Carousel>
            : null}
        </>
    )



}