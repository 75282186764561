import { React, useState } from 'react'
import axios from 'axios'
import {Button, Grid, TextField } from '@material-ui/core'    
import { useParams } from "react-router-dom";

import {textfield} from './styles.js'
import {URLS} from './util.js'
import { notify } from './notifications.js'


export default function ProviderDetails() {
    const params = useParams()    
    const [providerContact, setProviderContact] = useState({}) 
    const [providerLocation, setProviderLocation] = useState({}) 

   
    const add = async (event) => {
        event.preventDefault()
        try {
                console.log(providerContact, providerLocation, providerLocation !== {})
                if(providerLocation !== {}) {
                    const r = await axios.post(URLS.SERVER + '/providers_details/add_location/?name=' + params['name'], {...providerLocation}, 
                                            {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                }
                if (providerContact !== {}) {
                    const r1 = await axios.post(URLS.SERVER + '/providers_details/add_contact/?name=' + params['name'], {...providerContact}, 
                            {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                }
                window.location.href = URLS.RESOURCES + "/add/" + params['name'];
        } catch (error) {
                try {            
                        notify(error.response.data, 'warning')
                } catch (e) {
                        console.log(error)
                        if(error.response.status === 403) {
                                notify('You are not allowed to do this action.', 'danger')
                        } else {
                                notify('There is no connection to server. Please try again later.', 'danger')
                        }
                }
        }
    }    

    return (
        <Grid container component="main" style={{height: '100vh'}}>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={() => window.location.href = URLS.RESOURCES + "/add/" + params['name']}>
                        Skip
                    </Button>
                </Grid>
                <form style={{width: '90vw'}}>
                    <h3> Add provider location </h3> 
                    <Grid container>
                        <Grid item xs={12} ms={6} md={6} lg={6}>
                            <TextField variant="outlined" margin="normal" required
                                    label="Address" name="address" autoFocus 
                                    onChange={(e) => providerLocation[e.target.name] = e.target.value} 
                                    style={textfield} defaultValue={providerLocation['address']}/>  
                        </Grid>
                        <Grid item xs={12} ms={6} md={6} lg={6}>
                            <TextField variant="outlined" margin="normal" required
                                    label="Postal code" name="postal_code" autoFocus 
                                    onChange={(e) => providerLocation[e.target.name] = e.target.value} 
                                    style={textfield} defaultValue={providerLocation['postal_code']}/>  
                        </Grid>
                        <Grid item xs={12} ms={6} md={6} lg={6}>
                            <TextField variant="outlined" margin="normal" required
                                    label="City" name="city" autoFocus 
                                    onChange={(e) => providerLocation[e.target.name] = e.target.value} 
                                    style={textfield} defaultValue={providerLocation['city']}/>  
                        </Grid>
                        <Grid item xs={12} ms={6} md={6} lg={6}>
                            <TextField variant="outlined" margin="normal" required
                                    label="Country" name="country" autoFocus 
                                    onChange={(e) => providerLocation[e.target.name] = e.target.value} 
                                    style={textfield} defaultValue={providerLocation['country']}/>  
                        </Grid>  
                        <Grid item xs={12} ms={6} md={6} lg={6}>
                            <TextField variant="outlined" margin="normal" 
                                    label="Region" name="region" autoFocus 
                                    onChange={(e) => providerLocation[e.target.name] = e.target.value} 
                                    style={textfield} defaultValue={providerLocation['region']}/>  
                        </Grid>                      
                        <Grid item xs={12} ms={12} md={12} lg={12}>
                            <TextField variant="outlined" margin="normal" required
                                    label="Billing Address" name="billing_address" autoFocus 
                                    onChange={(e) => providerLocation[e.target.name] = e.target.value} 
                                    style={textfield} defaultValue={providerLocation['billing_address']}/>  
                        </Grid>
                    </Grid>                                                            
                </form>
                <form style={{width: '90vw'}}>
                    <h3> Add provider public contact </h3> 
                    <Grid container>
                        <Grid item xs={12} ms={6} md={6} lg={6}>
                            <TextField variant="outlined" margin="normal" required
                                    label="First name" name="first_name" autoFocus                                     
                                    onChange={(e) => providerContact[e.target.name] = e.target.value} 
                                    style={textfield} defaultValue={providerContact['first_name']}/>  
                        </Grid>
                        <Grid item xs={12} ms={6} md={6} lg={6}>
                            <TextField variant="outlined" margin="normal" required
                                    label="Last name" name="last_name" autoFocus onChange={() => {}} 
                                    style={textfield} defaultValue={providerContact['last_name']}/>  
                        </Grid>
                        <Grid item xs={12} ms={6} md={6} lg={6}>
                            <TextField variant="outlined" margin="normal" required
                                    label="Email" name="email" autoFocus 
                                    onChange={(e) => providerContact[e.target.name] = e.target.value} 
                                    style={textfield} defaultValue={providerContact['email']}/>  
                        </Grid>
                        <Grid item xs={12} ms={6} md={6} lg={6}>
                            <TextField variant="outlined" margin="normal" required
                                    label="Phone" name="phone" autoFocus 
                                    onChange={(e) => providerContact[e.target.name] = e.target.value}  
                                    style={textfield} defaultValue={providerContact['phone']}/>  
                        </Grid>
                        <Grid item xs={12} ms={6} md={6} lg={6}>
                            <TextField variant="outlined" margin="normal" 
                                    label="Position" name="position" autoFocus 
                                    onChange={(e) => providerContact[e.target.name] = e.target.value} 
                                    style={textfield} defaultValue={providerContact['position']}/>  
                        </Grid>
                    </Grid>                                                            
                </form>
                <Button fullWidth variant="contained" color="primary" onClick={add}>
                    Add
                </Button> 
        </Grid> 
    )
}