import { React, useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { Button, Grid, Tabs, Tab, Link, Badge} from '@material-ui/core'  
import {CgWebsite} from 'react-icons/cg'
import {AiOutlineInstagram, AiOutlineFacebook, AiOutlineLinkedin, 
        AiOutlineYoutube} from 'react-icons/ai'
import {FaMapMarker} from 'react-icons/fa'
import {MdEmail} from 'react-icons/md'
import axios from 'axios'
import GetRenders, {URLS} from './util.js'
import {notify} from './notifications'
import './App.css'
import Header from './Header'
import Footer from './Footer'
import { StaticMultipleSelect } from './StaticMultipleSelect.js';
import { Description } from './Description.js';
import { Multimedia } from './Multimedia.js';
import { titleSmallBlue, title, divWithBorder,
        avatarContainerProv, titleSmall, tab, smallSubtitle} from './styles.js'
import ScientificDomainMap from './ScientificDomainMap.js'
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    badge: {
            height: 30,
            width: 200
    },
})


export default function Provider() {
        const classes = useStyles()
        const params = useParams()
        const [value, setValue] = useState('one')
        const [provider, setProvider] = useState()
        const [edit, setEdit] = useState(false)
        const [providerContact, setProviderContact] = useState({}) 
        const [providerLocation, setProviderLocation] = useState({}) 
        const [open, setOpen] = useState(false)
        const [hasMulti, setHasMulti] = useState(false)


        useEffect(() => {
                async function fetch() {
                try {
                        const r = await axios.get(URLS.SERVER + '/providers/provider_version/?id=' + params['id'] + '&date=' + params['update'], 
                                        {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                        setProvider(r.data)
                        if (r.data.multimedia.length !== 0) {
                                setValue('zero')
                                setHasMulti(true)
                                }
                } catch (e) {
                        console.log(e)
                        try {            
                                notify(e.response.data, 'warning')
                        } catch (e) {
                                notify('There is no connection to server. Please try again later.', 'danger')
                        }
                }
        }
        
        fetch()
        }, [])


        if (provider && params['edit']) {
                return (
                <Grid container style={{maxHeight: '110vh', opacity: open ? 0.3 : 1}}>
                <Header/>
                <Grid container style={avatarContainerProv}>
                        <img style={{marginLeft: '2vw', width: 130, marginTop: 5}} src={provider['logo'] ? provider.logo : '/placeholder1.png'}/>
                        <Grid item xs={false} sm={7} md={8} lg={8} >
                                <span style={title} className='step1'> {provider.abbreviation} </span>
                                <br/>
                                {provider.abbreviation !== provider.name ? 
                                <>
                                        <span style={titleSmallBlue}> {provider.name}</span> 
                                        <br/> <br/>
                                </>
                                : null} 
                                {provider.linkedin ? 
                                <AiOutlineLinkedin size={25} onClick={() =>  window.location.href=provider.linkedin} style={{marginRight: 10}}/>
                                : null}
                                {provider.fb ?
                                <AiOutlineFacebook size={25} onClick={() =>  window.location.href=provider.fb} style={{marginRight: 10}}/>
                                : null}
                                {provider.insta ? 
                                <AiOutlineInstagram size={25} onClick={() =>  window.location.href=provider.insta} style={{marginRight: 10}}/>
                                : null}
                                {provider.yt ?
                                <AiOutlineYoutube size={25} onClick={() =>  window.location.href=provider.yt} style={{marginRight: 10}}/>
                                : null}
                        </Grid>
                        <Grid item xs={false} sm={2} md={2} lg={2} >
                                 <span style={titleSmallBlue}> Version</span> 
                                 <br/>
                                 <Badge badgeContent={params['update']} color='primary' classes={{badge: classes.badge}}/>
                        </Grid>
                </Grid>
                <Grid item xs={11} sm={11} md={5} lg={5} style={{paddingBottom: 10, marginRight: 20}}>
                        <Description value={provider.description} profile={true}/>
                        <br/>
                        {provider.tags.length !== 0 ?
                                <>
                                        {provider.tags.map((tag, i) => 
                                                <Link key={i} > #{tag} </Link>
                                        )} 
                                        <br/> <br/>
                                </>
                        :null}
                        <Grid container style={{backgroundColor: 'white', borderRadius: '10px'}}>
                                <Tabs value={value} onChange={(e, newValue) => setValue(newValue)} 
                                        style={{float: 'right', borderLeft: `1px solid`, borderColor: '#E8E8E8'}}
                                                orientation='horizontal'  textColor="secondary" >
                                        {hasMulti ? 
                                                <Tab value="zero" label="Photos & videos" style={tab}/>
                                        : null}
                                        <Tab value="one" label="About" style={tab}/>
                                        {provider['partners'] || provider['participating_countries'].length !== 0 || provider['affiliations'].length !== 0 
                                                || provider['networks'].length !== 0  || provider['areas_of_activity'].length !== 0 || provider['esfri_domains'].length !== 0 ? 
                                        <Tab value="two" label="Other" style={{textTransform: 'none'}}/>
                                        : null}
                                </Tabs>
                                <Grid item xs={12} sm={12} md={12} lg={12} style={{minHeight: 430, paddingBottom: 20}}>
                                        {value === 'zero' ?
                                                <div style={{marginLeft: -30}}>
                                                        <Multimedia media={provider.multimedia} provider_id={provider.uuid}/>
                                                </div>
                                                : null}
                                        {value === 'one' ?
                                                <Grid container style={{backgroundColor: 'white', borderRadius: '10px', padding: 5, marginLeft: -30}}>
                                                        {provider.scientific_domain.length !== 0 ?
                                                                <Grid container>  
                                                                        <Grid item xs={12} ms={12} md={12} lg={12} >  
                                                                                <span style={titleSmallBlue}>Scientific domain</span>
                                                                        </Grid>
                                                                        {provider.scientific_domain.map((item, i) => 
                                                                                <Grid item xs={12} ms={3} md={3} lg={3}>  
                                                                                        <ScientificDomainMap dm={item} key={i}/>
                                                                                </Grid>
                                                                        )}
                                                                </Grid>
                                                        : null}
                                                        {provider.scientific_subdomain.length !== 0 ?
                                                                <Grid item xs={12} ms={12} md={12} lg={12} >  
                                                                        <StaticMultipleSelect value={provider.scientific_subdomain} title='Scientific subdomain' />
                                                                </Grid> 
                                                        : null}
                                                        {provider['expertise'].length !== 0 ? 
                                                        <Grid item xs={12} sm={6} md={6} lg={6} >
                                                                <StaticMultipleSelect value={provider.expertise} title='Expertise' />        
                                                        </Grid>
                                                        : null}
                                                        {provider['certifications'].length !== 0 ? 
                                                        <Grid item xs={12} sm={6} md={6} lg={6} >
                                                                <StaticMultipleSelect value={provider.certifications} title='Certifications' />                   
                                                        </Grid>
                                                        : null}
                                                </Grid>
                                        : null}  
                                        {value === 'two' ? 
                                                <Grid container style={{backgroundColor: 'white', borderRadius: '10px', padding: 5, marginLeft: -30}}>
                                                        {provider['partners'] ? 
                                                        <Grid item xs={12} sm={12} md={12} lg={12} >
                                                                <StaticMultipleSelect value={[provider.partners]} title='Partners' />   
                                                        </Grid>
                                                        : null}
                                                        {provider.participating_countries.length !== 0 ? 
                                                        <Grid item xs={12} sm={12} md={12} lg={12} >
                                                                <StaticMultipleSelect value={provider.participating_countries} title='Participating countries' />
                                                        </Grid>
                                                        : null}
                                                        {provider['affiliations'].length !== 0 ? 
                                                        <Grid item xs={12} sm={12} md={12} lg={12} >
                                                                <StaticMultipleSelect value={provider.affiliations} title='Affiliations' />
                                                        </Grid>
                                                        : null}
                                                        {provider['networks'].length !== 0 ? 
                                                        <Grid item xs={12} sm={12} md={12} lg={12} >
                                                                <StaticMultipleSelect value={provider.networks} title='Networks' />
                                                        </Grid>
                                                        : null}
                                                        {provider['areas_of_activity'].length !== 0 ? 
                                                        <Grid item xs={12} sm={6} md={6} lg={6} >
                                                                <StaticMultipleSelect value={provider.areas_of_activity} title='Areas of activity' />
                                                        </Grid>
                                                        : null}
                                                        {provider['esfri_domains'].length !== 0 ? 
                                                        <Grid item xs={12} sm={6} md={6} lg={6} >
                                                                <StaticMultipleSelect value={provider.esfri_domains} title='Esfri domains' />
                                                        </Grid>
                                                        : null}
                                                </Grid>
                                        : null}
                                </Grid>
                        </Grid>
                </Grid>
                <Grid item xs={11} sm={11} md={6} lg={5} >
                        <div style={{backgroundColor: 'white', borderRadius: '10px 10px 10px 10px', padding: 5, marginTop: 0, height: 300}}>
                                <div style={divWithBorder}>
                                        <span style={titleSmall}> Resources </span> 
                                </div>
                                <Grid item xs={false} sm={3} md={2} lg={2} style={{marginLeft: 10}}>
                                 <button className="exitButton" onClick={() =>  window.location.href=URLS.PROVIDER  + provider.uuid} 
                                         style={{width: 200}}>
                                        Go to provider
                                 </button>
                         </Grid>
                        </div>
                        <br/> <br/>
                        <Grid container style={{backgroundColor: 'white', borderRadius: 10, minHeight: 100, }}>
                                <div style={{borderBottom: '1px solid', borderColor: '#0A50C7',  width: '96%',  marginTop: 15, marginBottom: 10, marginLeft: 10,
                                        paddingBottom: 0}}>
                                        <span style={titleSmall}> Contact information </span> 
                                </div>
                                <Button variant='filled' startIcon={<CgWebsite style={{color: '#0492C1', width: '1.8vw', height: '1.8vw', maxWidth: 50,
                                                                                        minWidth: 25, minHeight: 25, }}/>} 
                                        style={{textTransform: 'none', marginLeft: 10}} onClick={() =>  window.location.href=provider.website}>
                                        {provider.website.split('//www.').slice(1)}
                                </Button>
                                {Object.keys(providerContact).length !== 0 ? 
                                 <Button variant='filled' startIcon={<MdEmail style={{color: '#0492C1', width: '1.8vw', height: '1.8vw', maxWidth: 50,
                                                                        minWidth: 25, minHeight: 25, }}/>} 
                                        style={{textTransform: 'none', marginLeft: 10}} href={'mailto:' + providerContact['email']}>
                                        {providerContact['email']}
                                        </Button>
                                : null}
                                {Object.keys(providerLocation).length !== 0 ?
                                <Button variant='filled' startIcon={<FaMapMarker style={{color: '#0492C1', width: '1.8vw', height: '1.8vw', maxWidth: 50,
                                                                minWidth: 25, minHeight: 25, }}/>} 
                                                style={{textTransform: 'none', marginLeft: 10}} >
                                        {providerLocation['city'] + ', ' + providerLocation['country']} 
                                </Button>
                                : null}
                        </Grid>
                </Grid>
                <Footer/>
        </Grid>
        )
        } else {
                return null
        }
}
    

