import { React, useState } from 'react'
import {Grid, Link} from '@material-ui/core'    
import Loader from "react-loader-spinner";

import {URLS} from './util.js'
import Footer from './Footer'
import Header from './Header'
import WhatWeOffer from './WhatWeOffer'
import { blackText, titleSmaller } from './styles.js'
import Domains from './Domains'


export default function About() {
    const [showLoader, setShowLoader] = useState(false)  

   

    return (
        <Grid container style={{opacity: showLoader ? 0.3 : 1, flexGrow: 1, height: '120vh'}}>
            <Header hide={true}/>
            <Loader type="Oval" color="#0A50C7" height={100} width={100} visible={showLoader} style={{position: 'absolute', top: '50%', left: '50%'}}/>
            <Grid item xs={12} ms={12} md={12} lg={12} style={{paddingBottom: 10, paddingRight: 40}}>
                <span style={{  fontSize: '1.7rem', color: '#0A50C7'}}> About Us </span>
                <br/>
                <p style={blackText}>
                    Wizbee is a platform that bridges the gap between science and business. It gives access to university resources, lab facilities, technical equipment
                    remotely with just one click! These are called the Providers.
                </p>
                <button className="bigButton" onClick={() => window.location.href=URLS.FEED}> Browse now </button>
                <br/> <br/> 
            </Grid>
            <Grid item xs={12} ms={12} md={12} lg={12} >
                <span style={titleSmaller}> At Wizbee you can </span>
            </Grid>
            <Grid item xs={12} ms={12} md={12} lg={12} style={{marginBottom: '5vh', backgroundColor: 'white'}}>
                <WhatWeOffer about={true}/>
                <br/>
            </Grid>
            <Grid item xs={12} ms={12} md={12} lg={10} style={{paddingBottom: 10, paddingRight: 40}}>
                <span style={titleSmaller}> Providers </span>
                <br/>
                <p style={blackText}>
                    <Link href={URLS.PARTNERS} style={{color: '#0492C1'}}> Providers </Link>
                    register to wizbee to provider their resources to users.                     
                </p>
                <br/> <br/>
                <span style={titleSmaller}> Resources </span>
                <br/>
                <p style={blackText}>
                    Resources added to wizbee cover the following scientific domains.
                </p>
                <Domains />
            </Grid>
           <Footer />
        </Grid>
    )
}