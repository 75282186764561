import { React, useState } from 'react'
import {Grid} from '@material-ui/core'    

import {URLS} from './util.js'
import Footer from './Footer'
import Header from './Header'
import { blackText, titleSmaller } from './styles.js'



export default function Terms() {
    const [showLoader, setShowLoader] = useState(false) 


    return (
        <Grid container style={{opacity: showLoader ? 0.3 : 1, flexGrow: 1, height: '100vh'}}>
            <Header hide={true}/>
            <Grid item xs={12} ms={12} md={12} lg={12} >
                <span style={{  fontSize: '1.7rem', color: '#0A50C7'}}> Terms of Use </span>
            </Grid>
            <Grid item xs={12} ms={12} md={12} lg={12} style={{paddingBottom: 10, paddingRight: 40}}>
                <p style={blackText}> These Terms of Use apply to all users of this Site. By using this Site you are agreeing to comply with and be bound by these Terms of Use. 
                If you do not agree to these Terms of Use, you may not access or use this Site.
                </p>
                <br/>
                <span style={titleSmaller}> DEFINITIONS </span>
                <p style={blackText}>
                “You” and “your” refer to you, as a user of the Site. <br/>
                A “user” is someone who accesses, browses, crawls, scrapes, or in any way uses the Site. <br/>
                “We”, “us”, and “our” refer to Wizbee.
                </p>
                <br/>
                <span style={titleSmaller}> Content </span>
                <p style={blackText}>
                “Content” means text, images, photos, audio, video, location data, and all other forms of data or communication. “Your Content” means Content that you submit or transmit to, through, or in connection with the Site. “User Content” means Content that users submit or transmit to, through, or in connection with the Site. “Wizbee Content” means Content that we create and make available in connection with the Site. “Third Party Content” means Content that originates from parties other than Wizbee or its users, which is made available in connection with the Site. “Site Content” means all of the Content that is made available in connection with the Site, including Your Content, User Content, Third Party Content, and Wizbee Content
                </p>
                <br/>
                <span style={titleSmaller}> Your Consent to Other Agreements </span>
                <p style={blackText}>
                When you sign up to use a special feature of this Site, you may be asked to agree to special terms governing your use of the special feature. In such cases, you may be asked to expressly consent to the special terms, for example, by checking a box or clicking on a button marked “I agree.” This type of agreement is known as a “click-through” agreement. If any of the terms of the click-through agreement are different than the terms of these Terms of Use, the terms of the click-through agreement will supplement or amend these Terms of Use, but only with respect to the matters governed by the “click-through agreement.”
                </p>
                <br/>
                <span style={titleSmaller}> Ownership of this Site and its Content </span>
                <p style={blackText}>
                This Site, including all its Content are protected under applicable intellectual property and other laws, including without limitation the laws of Greece and other countries. All Content and intellectual property rights therein are the property of Wizbee or the material is included with the permission of the rights owner and is protected pursuant to applicable copyright and trademark laws. <br/>
                The presence of any Content on this Site does not constitute a waiver of any right in such Content. You do not acquire ownership rights to any such Content viewed through this Site. Except as otherwise provided herein, none of this Content may be used, copied, reproduced, distributed, republished, downloaded, modified, displayed, posted or transmitted in any form or by any means, including, but not limited to, electronic, mechanical, photocopying, recording, or otherwise, without our express prior written permission. <br/>
                Permission is hereby granted to the extent necessary to lawfully access and use this Site and to display, download, or print portions of this Site on a temporary basis and for your personal, educational, noncommercial use only, provided that you (i) do not modify the Content; (ii) you retain any and all copyright and other proprietary notices contained in the Content; and (iii) you do not copy or post the Content on any network computer or broadcast the Content in any media. <br/>
                </p>
                <br/>
                <span style={titleSmaller}> Links to This Site </span>
                <p style={blackText}>
                You may link to individual pages within this Site, but you must do so in a way that presents the page in its entirety. You may not cause a page from this Site to appear within the frame of another site nor may you link to individual page elements such as a graphic or photograph. You may not link directly to an audio file or to an audio stream; you must link to the page from which the file is accessed. Links to pages within this Site may be presented in text format only. 
                </p>
                <br/>
                <span style={titleSmaller}> Responsibility for User-Generated Content Posted on or Through this Site </span>
                <p style={blackText}>
                You are responsible for User-Generated Content that you post. Under no circumstances will we be liable in any way for any User-Generated Content. <br/>
                This means that you, not Wizbee, are entirely responsible for all User-Generated Content that you post and that you can be held personally liable for comments that are defamatory, obscene, or libelous, or that violate these Terms of Use, an obligation of confidentiality, or the rights of others. If any part of the User-Generated Content you post is not your original work, it is your responsibility to obtain any necessary permission to post it.
                </p>
                <br/>
                <span style={titleSmaller}> DISCLAIMERS </span>
                <p style={blackText}>
                WE MAKE NO REPRESENTATIONS OR WARRANTIES WITH RESPECT TO THIS SITE OR ITS CONTENT, OR ANY PRODUCT OR SERVICE AVAILABLE ON OR PROMOTED THROUGH THIS SITE. THIS SITE AND ALL OF ITS CONTENT (INCLUDING USER-GENERATED CONTENT) ARE PROVIDED ON AN “AS IS,” “AS AVAILABLE” BASIS, WITHOUT REPRESENTATIONS OR WARRANTIES OF ANY KIND. TO THE FULLEST EXTENT PERMITTED BY LAW, Wizbee, ITS AFFILIATES, AND THEIR SERVICE PROVIDERS AND LICENSORS DISCLAIM ANY AND ALL REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS, IMPLIED, ARISING BY STATUTE, CUSTOM, COURSE OF DEALING, COURSE OF PERFORMANCE OR IN ANY OTHER WAY, WITH RESPECT TO THIS SITE, ITS CONTENT, AND ANY PRODUCTS OR SERVICES AVAILABLE OR PROMOTED THROUGH THIS SITE. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WIZBEE, ITS AFFILIATES, AND THEIR SERVICE PROVIDERS AND LICENSORS DISCLAIM ALL REPRESENTATIONS AND WARRANTIES (A) OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE; (B) RELATING TO THE SECURITY OF THIS SITE; (C) THAT THE CONTENT OF THIS SITE IS ACCURATE, COMPLETE OR CURRENT; OR (D) THAT THIS SITE WILL OPERATE SECURELY OR WITHOUT INTERRUPTION OR ERROR. <br/>
                WE DO NOT REPRESENT OR WARRANT THAT THIS SITE, ITS SERVERS, OR ANY TRANSMISSIONS SENT FROM US OR THROUGH THIS SITE WILL BE FREE OF ANY HARMFUL COMPONENTS (INCLUDING VIRUSES). <br/>
                WIZBEE does not endorse and is not responsible for statements, advice and opinions made by anyone other than authorized WIZBEE spokespersons. WE DO NOT ENDORSE AND ARE NOT RESPONSIBLE FOR ANY statements, advice or opinions CONTAINED IN USER-GENERATED CONTENT AND SUCH statements, advice AND opinions DO NOT IN ANY WAY REFLECT THE STATEMENTS, ADVICE AND OPINIONS OF WIZBEE. WE DO NOT MAKE ANY REPRESENTATIONS OR WARRANTIES AGAINST THE POSSIBILITY OF DELETION, MISDELIVERY OR FAILURE TO STORE COMMUNICATIONS, PERSONALIZED SETTINGS, OR OTHER DATA. YOU ACCEPT THAT OUR SHAREHOLDERS, OWNERS, OFFICERS, DIRECTORS, EMPLOYEES AND OTHER REPRESENTATIVES SHALL HAVE THE BENEFIT OF THIS CLAUSE. <br/>
                APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OF CERTAIN WARRANTIES, SO ALL OR PART OF THIS DISCLAIMER OF WARRANTIES MAY NOT APPLY TO YOU.
                </p>
                <br/>
                <span style={titleSmaller}> LIMITATION OF LIABILITY </span>
                <p style={blackText}>
                WE ARE NOT RESPONSIBLE OR LIABLE IN ANY MANNER FOR ANY USER-GENERATED CONTENT. ALTHOUGH WE INCLUDE STRICT PROVISIONS REGARDING USER-GENERATED CONTENT IN THESE TERMS OF USE, WE DO NOT CONTROL AND ARE NOT RESPONSIBLE FOR WHAT USERS POST USING THIS SITE AND ARE NOT RESPONSIBLE FOR ANY OFFENSIVE, INAPPROPRIATE, OBSCENE, UNLAWFUL, INFRINGING OR OTHERWISE OBJECTIONABLE OR ILLEGAL USER-GENERATED CONTENT YOU MAY ENCOUNTER IN CONNECTION WITH YOUR USE OF THIS SITE.
                </p>
                <br/>
                <span style={titleSmaller}> Waiver </span>
                <p style={blackText}>
                Our failure at any time to require performance of any provision of these Terms of Use or to exercise any right provided for herein will not be deemed a waiver of such provision or such right. All waivers must be in writing. Unless the written waiver contains an express statement to the contrary, no waiver by Wizbee of any breach of any provision of these Terms of Use or of any right provided for herein will be construed as a waiver of any continuing or succeeding breach of such provision, a waiver of the provision itself, or a waiver of any right under these Terms of Use.
                </p>
                <br/>
                <span style={titleSmaller}> Severability</span>
                <p style={blackText}>
                f any provision of these Terms of Use is held by a court of competent jurisdiction to be contrary to law, such provision will be changed and interpreted so as to best accomplish the objectives of the original provision to the fullest extent allowed by law and the remaining provisions of these Terms of Use will remain in full force and effect.
                </p>
                <br/>
                <span style={titleSmaller}> Indemnification </span>
                <p style={blackText}>
                It is hereby expressly agreed that in the event that any claim or action, whether administrative or judicial, is filed against Wizbee Internet site by reason or as a result of the visitor /user's breach of any nature whatsoever, the visitor /user undertakes to enter a caveat and to indemnify Wizbee’s Internet site for any loss, damage or other expense incurred to it by reason or as a result of the above.
                </p>
                <br/>
                <span style={titleSmaller}> Governing law and other provisions </span>
                <p style={blackText}>
                This Agreement is governed by the provisions of Greek law, the Rules and Directives of European law and relevant international provisions, and shall be construed in accordance with the rules of good faith, equitable principles and the economic and social purpose of the right. <br/>
                Should any of the terms hereof be declared contrary to the law and therefore void or voidable it shall ipso jure cease to be effective, but the remaining terms hereof shall under no circumstances be affected and shall continue to remain in full force and effect. No amendment to any of the terms hereof shall be valid or constitute a part hereof unless it is formulated in writing and incorporated into this Agreement. Any dispute arising hereunder shall be subject to the competent jurisdiction of the Athens courts.
                </p>
                <br/>
                <span style={titleSmaller}> These Terms of Use May Change </span>
                <p style={blackText}>
                We reserve the right to update or modify these Terms of Use at any time, without prior notice, by posting the revised version of these Terms of Use behind the link marked “Terms of Use” at the bottom of each page of this Site. These changes will be effective as of the date we post the revised version on this Site. Your continued use of this Site after we have posted the revised Terms of Use constitutes your agreement to be bound by the revised Terms of Use.
                </p>
                <br/>
                <span style={titleSmaller}> Entire Agreement </span>
                <p style={blackText}>
                These Terms of Use (together with our Privacy Policy and any click-through agreements applicable to you) contain the entire understanding and agreement between you and Wizbee with respect to this Site and supersede all previous communications, negotiations, and agreements, whether oral, written, or electronic, between you and Wizbee with respect to this Site and your use of this Site.
                </p>
                <br/>
                <span style={titleSmaller}> Your Acceptance of Our Privacy Policy </span>
                <p style={blackText}>
                By agreeing to these Terms of Use, you agree to the terms of our Privacy Policy and any applicable Privacy Notices, which are expressly incorporated herein. Before using this Site, please carefully review our Privacy Policy. All Personal Information provided to us as a result of your use of this Site will be handled in accordance with our Privacy Policy. To the extent there are inconsistencies between these Terms of Use and our Privacy Policy, these Terms of Use control.
                </p>
                <br/>
                <span style={titleSmaller}> Questions </span>
                <p style={blackText}>
                If you have any questions about this Site or these Terms of Use, please contact us using the following information: info@wizbee.com
                </p>
            </Grid>

           <Footer />
        </Grid>
    )
}