import { NativeSelect, FormControl, InputLabel } from '@material-ui/core'    



export const OrderResources = ({open, orderField, setOpen, changeOrder}) => {


    return (
        <FormControl style={{float: 'right',}}>
            <NativeSelect value={orderField} open={open} onClose={() => setOpen(!open)} onClick={() => setOpen(!open)}
                    onChange={changeOrder}  disableUnderline style={{marginRight: 20, width: 150}} >
                <option value={''}>Order</option>
                <option value={'recent'}>Recently added</option>
                <option value={'quality'}>Quality rating </option>
                <option value={'communication'}>Communication rating </option>
                <option value={'price'}>Price rating </option>
            </NativeSelect>
        </FormControl >
    )
}