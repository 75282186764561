import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'


export const notify = (message, type, status) => {
    
    if (status) {
        if (status === 401) {
            store.addNotification({ message: 'You can\'t perform this action. You have to sign in', type: 'warning',
                insert: "top-left", container: "top-left",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: { duration: 5000, } });
        } else if (status === 500) {
            store.addNotification({ message: 'There is a problem connecting to server.', type: 'warning',
                insert: "top-left", container: "top-left",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: { duration: 5000, } });
        } else if (status === 400) {
            store.addNotification({ message: 'There is a problem in this request.', type: 'warning',
                insert: "top-left", container: "top-left",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: { duration: 5000, } });
        } else {
            console.log(status)
        }
    } else {
        store.addNotification({  
                message: message, type: type,
                insert: "top-left", container: "top-left",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: { duration: 5000} });
    }
}