import { React } from 'react'
import { Tooltip, Zoom } from '@material-ui/core'


export default function ScientificDomainMap({dm}) {
    const dict = {
            'Natural Sciences' : '/natural.png',
            'Engineering & Technology' : '/tech.png',
            'Engineering And Technology' : '/tech.png',
            'Social Sciences' : '/socialnew.png',
            'Medical & Health Sciences': '/health.png',
            'Medical And Health Sciences': '/health.png',
            'Agricultural Sciences' : '/agricultural.png',
            'Humanities' : '/humanities.png',
            'Other': null,
            'Generic': null
        }
    
    
    const getDomainPicture = (domain) => {
        try {
            return dict[domain]
        } catch(e) {
            return null
        }
    }

    if (getDomainPicture(dm) !== null) { 
        return (
                <Tooltip title={dm} TransitionComponent={Zoom} placement='top' >
                    <img src={getDomainPicture(dm)} style={{width: 100}}/>
                </Tooltip>
        )
    } else {
        return (
            <>
            <span style={{backgroundColor: '#E8E8E8', padding: 5, marginRight: 5, borderRadius: '10%', fontSize: '0.775rem '}}> {dm} </span>
            <br/> <br/>
            </>
        )
    }
}
        