import { React, useEffect, useState } from 'react'
import axios from 'axios'
import { titleSmall, blackNormalText, smallSubtitle } from './styles.js'
import { Button, Avatar, Grid, Tooltip, Zoom, Link, List } from '@material-ui/core'
import {BsPlusCircle} from 'react-icons/bs'
import {URLS} from './util.js'
import {notify} from './notifications'


export default function MyProviders(props) {
    const [myProviders, setMyProviders] = useState([])
    const [showMore, setShowMore] = useState([])
    const [myResources, setMyResources] = useState([])


    useEffect(() => {
        async function fetch() {
            try {
                const r1 = await axios.get(URLS.SERVER + '/providers/by_user/' ,
                        {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                if (r1.data.length > 2) {
                    setShowMore(true)
                }
                setMyProviders(r1.data)
                if (r1.data.length > 0) {
                    const r3 = await axios.get(URLS.SERVER + '/providers/resources?id=' + r1.data[0].uuid, {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                    setMyResources(r3.data) 
                }
            } catch (e) {
                try {            
                    notify(e.response.data, 'warning')
                } catch (e) {
                    notify('There is no connection to server. Please try again later.', 'danger')
                }
            }
        }
        fetch()
    }, [])

    
    
    return (
        <>
        <div style={{backgroundColor: 'white', borderRadius: '10px', padding: props.two ? 15 : null}}>
            <div style={{marginBottom: 10, width: '99.4%',}}>
                    <div style={{borderBottom: '1px solid', borderColor: '#0A50C7', width: '100%', marginBottom: 10, paddingBottom: 5,}}>
                        <span style={titleSmall}> My providers </span> 
                    </div> 
                    {myProviders.length !== 0 ?  
                    <List style={{height: props.two ? 230 : 600, overflow: 'auto', width: '100%'}} >
                        {myProviders.map((prov, i) => {
                            if (!props.two || (props.two && i < 2)) {
                                return (
                                    <Grid container key={i}>
                                        <Grid item style={{marginBottom: 5}} key={i} xs={7} sm={7} md={7} lg={7}>
                                            <Button variant='filled' href={'/provider/' + prov.uuid} style={{marginLeft: -40,}}>
                                                <img style={{marginRight: 10, width: 55, }} src={prov.logo}/>
                                                <div>
                                                    <span style={blackNormalText}> {prov.abbreviation} </span>
                                                    <br/>
                                                    <span style={smallSubtitle}> Resources {prov.count} </span>
                                                </div>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1} lg={1}>
                                            <Tooltip title='Add new resource' TransitionComponent={Zoom} placement='top' style={{height: 30}}>
                                                    <Button startIcon={<BsPlusCircle size={20} style={{marginTop: 30, color: '#FF8300'}}/>} 
                                                            onClick={() => window.location.href=URLS.RESOURCES + '/add/' + prov['name']}/>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                )} 
                        })}
                        {props.two ? 
                            <Grid item xs={11} sm={11} md={11} lg={11}>
                                <Link href={"/user/" + localStorage.getItem('name') + '/one'} style={{float: 'right', color: 'gray', fontSize: '0.8rem',}}> Show more</Link>
                            </Grid>
                        : null }
                    </List>
                    : 
                    <Button variant='contained' onClick={() => window.location.href=URLS.ONBOARD}
                            style={{borderRadius: '0 20px 20px 20px', height: 30}}>
                        Add provider
                    </Button>
                    }
            </div>
        </div>
        <br/> 
    </>
    )
}
        