import { React, useState } from 'react'
import axios from 'axios'
import validator from 'validator'
import {Button, Grid, TextField, Paper, Link, List,
        Checkbox, FormControlLabel } from '@material-ui/core'

import {URLS, checkPassword, checkName} from './util.js'
import {textfield90, title} from './styles.js'
import Loader from "react-loader-spinner";
import { notify } from './notifications.js'
import SocialLogin from './SocialLogin'
import './App.css'
import Header from './Header'


export default function SignUp() {
    const [ user, setUser] = useState({})
    const [showLoader, setShowLoader] = useState(false)  
    const [comply, setComply] = useState(false)

    const handleChangeEmail = (event) => {
        if(event.target.value === "") {

        } else if (validator.isURL(event.target.value)) {
            user[event.target.name] = event.target.value
        } else {
            notify('This is not a valid email.', 'warning')
        }
    }

    const signup = async (e)  => {
        e.preventDefault()
        if (comply) {
            if ( checkName(user['first_name'], notify, 'first_name') &&
                checkName(user['last_name'], notify, 'last_name')  && checkPassword(user['password'], notify)){
                try {
                    setShowLoader(true)
                    user['is_maincontact'] = true
                    user['comply_to_terms'] = comply
                    const r =  await axios.post(URLS.SERVER + '/signup/', user)
                    localStorage.setItem('token', r.data.token);
                    localStorage.setItem('name', r.data.name);
                    localStorage.setItem('first', true); 
                    setShowLoader(false)
                    window.location.href = URLS.FEED
                } catch (e) {
                    console.log(e)
                    setShowLoader(false)
                    try {            
                        notify(e.response.data, 'warning')
                    } catch (e) {
                        notify('There is no connection to server. Please try again later.', 'danger')
                    }
                }
            } 
        } else  {
            notify('Please accept terms of use.', 'warning')
        } 
    }

    return (
        <Grid container style={{height: '100vh',}}>
            <Header hide={true}/>
            <Loader type="Oval" color="#0A50C7" height={100} width={100} visible={showLoader} style={{position: 'fixed', top: '50%', left: '46%'}}/>
            <Grid item xs={false} sm={false} md={3} lg={4}></Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} component={Paper} elevation={5} style={{height: '80vh', marginBottom: 20, opacity: showLoader ? 0.3 : 1}}>
                <div style={{alignItems: 'center', }}>
                    <div style={{marginTop: 20}}> 
                        <span style={title}> Sign Up </span>
                    </div>
                <List>
                    <TextField variant="outlined" margin="normal" required style={textfield90}
                        fullWidth label="First name" name="first_name" size='small'
                        autoFocus onChange={(e) => user[e.target.name] = e.target.value} />
                    <TextField variant="outlined" margin="normal" required style={textfield90}
                        fullWidth label="Last name" name="last_name" size='small'
                        autoFocus onChange={(e) => user[e.target.name] = e.target.value} />
                    <TextField variant="outlined" margin="normal" required style={textfield90}
                        fullWidth label="Email" name="email" size='small'
                        autoFocus onChange={(e) => user['email']=e.target.value} onBlur={handleChangeEmail} />
                    <TextField variant="outlined" margin="normal" required type="password" style={textfield90}
                        fullWidth label="Password" name="password" size='small'
                        autoFocus onChange={(e) => user['password']=e.target.value}/>
                    <br/>
                    <FormControlLabel control={
                        <Checkbox name='comply_to_terms' onChange={e => setComply(!comply)}/> 
                    } label='Accept terms *' labelPlacement="start" style={{marginLeft: -2}}/>
                    <br/>
                    <Button type="submit" variant="contained" style={{width: '90%', marginBottom: 10}} onClick={signup}>
                        Sign Up
                    </Button>
                    <br/>
                    <Link href={URLS.SIGNIN}>
                        {"Already a user? Sign In"}
                    </Link>
                    <Grid container style={{marginTop: 25}}>
                        <Grid item xs={false} sm={2} md={2} lg={2} ></Grid>
                        <Grid item xs={7} sm={6} md={5}>
                            <div style={{textAlign: 'center'}}>
                                <span style={{fontSize: '1.2rem', color: '#0492C1', marginLeft: 30}}> Sign up with</span>
                                <SocialLogin user={user} notify={notify} signup={true}/>
                            </div>
                        </Grid>                      
                    </Grid>
                </List>
                </div>
            </Grid>
        </Grid>
    )
}