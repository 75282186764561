import { modal} from './styles.js'
import Modal from 'react-modal';
import {TiDelete} from 'react-icons/ti'
import { Button, TextField } from '@material-ui/core'    
import { notify } from './notifications.js'
import axios from 'axios'
import {URLS} from './util.js'


export default function OtherFilter({open, setOpen, proposal, setProposal, other}) {
    
    const addOther = async () => {
        try {
            await axios.post(URLS.SERVER + '/other/add/', {'main_type': other, 'value': proposal }, 
                            {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
            setOpen(false)
        } catch (e) {
            try {            
                notify(e.response.data, 'warning')
            } catch (e) {
                notify('There is no connection to server. Please try again later.', 'danger')
            }
        }

    }

    return (
        <Modal isOpen={open} style={modal} >
            <TiDelete style={{float: 'right', width: 30, height: 30}} onClick={()  => setOpen(false)} />
            <h4>Please propose another desired {other}.</h4>
            <TextField onChange={(e) => setProposal(e.target.value)}></TextField>
            <br/>
            <Button onClick={addOther} variant="outlined" style={{marginTop: 10, width: 100, height: 40, textAlign: 'center', float: 'right'}}> Add </Button>
        </Modal>
    )
}