import { React, useState } from 'react'
import {Grid} from '@material-ui/core'    

import {URLS} from './util.js'
import { blackText } from './styles.js'



export default function Domains() {


    return (
        <Grid container style={{marginLeft: -50}}>
            <Grid item xs={6} sm={3} md={3} lg={3}>
                <img src='/natural.png' style={{width: '80%', minWidth: 100}} />
                <div style={{  textAlign:  'center', width: '12vw', maxWidth: 250}}>
                    <span style={blackText}> Natural Sciences</span>
                </div>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
                <img src='/tech.png' style={{width: '80%'}} />
                <div style={{  textAlign:  'center', width: '12vw', maxWidth: 250}}>
                    <span style={blackText}> Engineering Sciences</span>
                </div>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
                <img src='/health.png' style={{width: '80%', minWidth: 100}} />
                <div style={{  textAlign:  'center', width: '12vw', maxWidth: 250}}>
                    <span style={blackText}> Medical Sciences</span>
                </div>
            </Grid>
            <Grid item xs={false} sm={1} md={1} lg={1}></Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
                <img src='/agricultural.png' style={{width: '80%', minWidth: 100}} />
                <div style={{  textAlign:  'center', width: '12vw', maxWidth: 250}}>
                    <span style={blackText}> Agricultural Sciences</span>
                </div>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
                <img src='/socialnew.png' style={{width: '80%', minWidth: 100}} />
                <div style={{  textAlign:  'center', width: '12vw', maxWidth: 250}}>
                    <span style={blackText}> Social Sciences</span>
                </div>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
                <img src='/humanities.png' style={{width: '80%', minWidth: 100}} />
                <div style={{  textAlign:  'center', width: '12vw', maxWidth: 300}}>
                    <span style={blackText}> Humanities</span>
                </div>
            </Grid>  
        </Grid>
    )
}