import { React, useEffect, useState } from 'react'
import axios from 'axios'
import { title, blackNormalText, smallSubtitle, subtitleSmallPetrol, divWithBorder } from './styles.js'
import { Avatar, Grid, Link, Button, Tooltip, Zoom } from '@material-ui/core'
import GetRenders, {URLS} from './util.js'
import {notify} from './notifications'
import {AiFillSetting} from 'react-icons/ai'


export default function MyProfile(props) {
    const [stats, setStats] = useState({'providers_sum': 0, 'resources_sum': 0})


    useEffect(() => {
        async function fetch() {
            try {
                const r1 = await axios.get(URLS.SERVER + '/users/stats/', 
                    {headers: {"Authorization": "Token " + localStorage.getItem('token')}})    
                setStats(r1.data)
            } catch (e) {
                try {            
                    notify(e.response.data, 'warning')
                } catch (e) {
                    notify('There is no connection to server. Please try again later.', 'danger')
                }
            }
        }
        fetch()
    }, [])
    
    
    return (
        <div style={{backgroundColor: 'white', borderRadius: '10px'}}>
            <Grid container style={{ width: '99.4%',}}>
                {props.profile ? 
                <div style={divWithBorder}>
                    <span style={title}> Profile </span> 
                    <Tooltip title='Edit settings' TransitionComponent={Zoom} placement='top' >
                        <Button variant='none' endIcon={ <AiFillSetting size={25} style={{color: 'gray', marginTop: -5}} /> } 
                            onClick={() =>  props.setOpen(true)} style={{float: 'right'}}/>         
                    </Tooltip>
                </div>
                : null}
                <Avatar src={localStorage['picture']} style={{width: 80, height: 80, marginLeft: 15, marginTop: 15, marginBottom : 10}}/>
                <div style={{marginTop: 35, marginLeft: 10}}>
                    <Link style={{fontSize: '1.2rem', fontWeight: 'bold', color: 'black'}} onClick={() => window.location.href = "/user/" + localStorage.getItem('name') + '/one'}> 
                        {localStorage['name']} 
                    </Link>
                    <br/>
                    {localStorage.getItem('position') !== 'null' ? 
                        <span style={smallSubtitle}> {localStorage.getItem('position')} </span>
                    : null}
                </div>
                {!props.profile && GetRenders().XS ? null 
                : 
                <>
                    <div style={{borderTop: '1px solid', borderColor: '#F9F7F7', width: '100%', marginBottom: 10, padding: 15, marginTop: 10}}>
                        <span style={smallSubtitle}> Providers added </span>
                        <span style={subtitleSmallPetrol}> {stats.providers_sum} </span>
                        <br/>
                        <span style={smallSubtitle}> Resources added </span>
                        <span style={subtitleSmallPetrol}> {stats.resources_sum} </span>
                        <br/> <br/>
                        <Link style={{float: 'right', fontSize: '0.7rem'}} onClick={() => window.location.href=URLS.ONBOARD}> Add new provider</Link>
                    </div>
                    <div style={{borderTop: '1px solid', borderColor: '#F9F7F7', width: '100%', marginBottom: 10, padding: 15}}>
                        <span style={smallSubtitle}> Interactions with my Providers </span>
                        <span style={subtitleSmallPetrol}> {stats.providers_eng} </span>
                        <br/> <br/>
                        <Link style={{float: 'right', fontSize: '0.7rem'}} onClick={() => window.location.href = "/user/" + localStorage.getItem('name') + '/one'}> 
                            Edit profiles to boost engagement
                        </Link>
                    </div>
                </>
                }
                <div style={{borderTop: '1px solid', borderColor: '#F9F7F7', width: '100%', marginBottom: 10, paddingBottom: 5, padding: 15}}>
                    <span style={blackNormalText}> Enjoy personalized experience and insights</span>
                    <br/> <br/>
                    <button className="bigButton" > Upgrade to premium </button>
                </div>
            </Grid>
        </div> 
    )
}
        