import { React, useEffect, useState } from 'react'
import { Avatar, Grid, Paper, Button, Hidden, Select } from '@material-ui/core'  
import axios from 'axios'
import GetRenders, {URLS} from './util.js'
import { WhiteLabel} from './styles.js'
import ReactNotification from 'react-notifications-component'
import Autosuggest from 'react-autosuggest'
import './App.css'


export default function Header(props) {
    const [dropdown, setDropdown] = useState(false)
    const [recommended, setRecommended] = useState()
    const [value, setValue] = useState()
    const [open, setOpen] = useState(false)
    const [valueS, setValueS] = useState("")
    const [suggestions, setSuggestions] = useState([])
    const tablet = GetRenders().DOWN_SM

    const rec = [
        {
            title: "Providers",
            items: []
            },
        {
            title: "Resources",
            items: []
        },
    ]

    

    const navigate = (e) => {
        setValue(e.target.value) 
        if (e.target.value === 'profile') {
            window.location.href = "/user/" + localStorage.getItem('name') + '/one'
        } else if ( e.target.value === 'providers') {
            window.location.href="/user/" + localStorage.getItem('name') + '/one'
        } else if ( e.target.value === 'add provider') {
            window.location.href=URLS.ONBOARD
        } else {
            localStorage.clear(); 
            window.location.href= URLS.SIGNIN
        } 
    }

    async function loadRecommended(e) {
        try {
            const r = await axios.get(URLS.SERVER + '/search/recommended/?query=False&q=' + valueS,
                                    {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
            setRecommended(r.data)
            setOpen(true)
        } catch(e) {
            setRecommended(rec)
            if (e.response.status === 404){
                setOpen(false)
            }
        }
    }

    useEffect(() => {
        loadRecommended()
    }, [])

    function getSuggestions() {
        loadRecommended()
        try {
      
            const return_value = recommended
            .map(section => {
                return {
                title: section.title,
                items: section.items.filter(language => language.name
                )
                };
            })
            .filter(section => section.items.length > 0);
        
            return return_value;
        } catch(e) {
        }
      }

    const onSuggestionsFetchRequested = ({ value }) => {
        setValueS(value);
        setSuggestions(getSuggestions())
    }
    
    const onSuggestionsClearRequested = () => {
        setSuggestions([])
    }
      
    function renderSuggestion(suggestion) {
        return <span>{suggestion.name}</span>;
    }

    function renderSectionTitle(section) {
        return <strong>{section.title}</strong>;
    }

    function getSectionSuggestions(section) {
        return section.items;
    } 
      
    function getSuggestionValue(suggestion) {
        return suggestion.name;
    }
      
    function renderSuggestion(suggestion) {
        return (
            <Button onClick={() => window.location.href=URLS.PROVIDER + suggestion.uuid} style={{color: 'black', fontSize: '0.9rem'}}>{suggestion.name}</Button>         
        );
    }

    const onChange = (event, { newValue, method }) => {
        setValueS(newValue);
        return newValue;
    };
    

    const inputProps = {
        placeholder: 'Search',
        value: valueS,
        onChange: onChange
    };      


    return (
        <Grid container style={{paddingBottom: 5, marginBottom: 20, maxHeight: 100, width: '100%', backgroundColor: '#172E65', borderRadius: 0}} component={Paper} elevation={6}>
            <ReactNotification/> 
            <Grid item xs={8} sm={4} md={4} lg={4}> 
                <img src='/logo.png' style={{width: '15vw', minWidth: 200, marginBottom: 5, marginTop: tablet ? 10 : 0, cursor: 'pointer'}} 
                     onClick={() => window.location.href=URLS.FEED}/>
                <br/>
                <span style={{color:'white', fontSize: '0.875rem', float: 'left', marginLeft: 8}}> make science yours</span>
            </Grid>
            {!props.hide ? 
            <> 
            {GetRenders().XS ? null : 
                <Grid item xs={6} sm={5} md={5} lg={6}>
                    <Autosuggest suggestions={suggestions} onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={onSuggestionsClearRequested} getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion} renderSectionTitle={renderSectionTitle}
                        getSectionSuggestions={getSectionSuggestions} inputProps={inputProps} multiSection={true} />
                </Grid> 
            }
            <Grid item xs={1} sm={1} md={1} lg={1} style={{marginTop: 20}}>
                <Avatar src={localStorage['picture']} onClick={() => setDropdown(!dropdown)} style={{cursor: 'pointer'}}/>
                <Select value={value} onChange={navigate} open={dropdown} onClose={() => setDropdown(!dropdown)}
                    IconComponent={() => null} disableUnderline>
                    <Button variant='filled' style={{margin: 5}} value={'profile'}>Profile</Button>
                    <br/>
                    <Button variant='filled' style={{margin: 5}} value={'providers'}>My providers</Button>
                    <br/>
                    <Button variant='filled' style={{margin: 5}} value={'add provider'}>Add provider</Button>
                    <br/>
                    <Button variant='filled' style={{margin: 5}} value={'logout'} >Logout</Button>
                </Select>
            </Grid>
            <Grid item xs={1} sm={2} md={2} lg={1} style={{marginTop: 5}}>
                <Hidden only="xs">
                    <WhiteLabel value={localStorage['name'].split(' ')[0]} disableUnderline readOnly style={{marginLeft: tablet ? '-3vw' : '-4.4vw', marginTop: 20}}/>
                </Hidden>
            </Grid>
            </>
            : null}
        </Grid>
    )

}