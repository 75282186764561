import { createMuiTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import {Input, OutlinedInput, Badge} from '@material-ui/core';


export const avatarContainerProv = {
  borderBottom: '1px solid', 
  borderColor: '#E8E8E8', 
  marginLeft: -5, 
  paddingBottom: 20,
  marginBottom: 10,
  maxHeight: 180,
}

export const avatarContainer = {
  borderBottom: '1px solid', 
  borderColor: '#E8E8E8', 
  marginLeft: -5, 
  paddingBottom: 20,
  marginBottom: 20,
  maxHeight: 170
}


export const leftContainer = {
  marginTop: 25, 
  backgroundColor: 'white', 
  borderRadius: '10px',  
  minHeight: 855, 
}

export const leftContainerResource = {
  marginTop: -5, 
  width: '80%',
}

export const rightContainer = {
  backgroundColor: 'white', 
  borderRadius: 10, 
  padding: 15, 
  minHeight: 435,
}

export const tabs = {
  backgroundColor: 'white', 
  borderLeft: `1px solid`, 
  borderColor: '#E8E8E8', 
  maxWidth: '75%',
  borderRadius: 10 ,
}

export const tab = {
  textTransform: 'none',
  borderBottom: '1px solid #E8E8E8'
}

export const optionColours = {//styles for select option
    control: styles => ({ ...styles, backgroundColor: '#E8E8E8', width: '80%'}),
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? 'white' : 'white', 
        ':active': {
          ...styles[':active'],
          backgroundColor: (isSelected ? 'blue' : null),
        }
        }},
    menu: styles => ({backgroundColor: 'white', position: 'absolute', zIndex: 100  }),
    }
  

export const controlStyles = { //styles for select label
      background: 'none',
      color: 'black',
    };

export const textfield = { //editable
  width: '80%',
  backgroundColor: '#E8E8E8',
  borderRadius: '5px',
}

export const textfield70 = { //editable
  width: '70%',
  backgroundColor: '#E8E8E8',
  borderRadius: '5px',
}

export const textfield90 = { //editable
  width: '90%',
  backgroundColor: '#E8E8E8',
  borderRadius: '5px',
}

export const textfield95 = { //editable
  width: '95%',
  backgroundColor: '#E8E8E8',
  borderRadius: '5px',
}


export const textfield100 = { //editable
  width: '100%',
  backgroundColor: '#E8E8E8',
  borderRadius: '5px',
}


export const textfield_nogrid = {
  width: '40%', 
  minWidth: '200px', 
  backgroundColor: '#E8E8E8',
  marginRight: '20px', 
  borderRadius: '5px',
}

export const textfield_noform = { //editable
  width: '30%',
  backgroundColor: '#E8E8E8',
  borderRadius: '5px',
}

export const modal = {
  overlay: {
    bottom: '25%', 
    top: '25%', 
    right: '30%', 
    left: '30%', 
    backgroundColor: 'transparent', 
    color: '#0A50C7',
    fontSize: '1em',
    maxHeight: 500,
    minHeight: 300, 
    minWidth: 300
  },
  content: {
    background: '#E8E8E8',
    opacity: 0.9,
    position: 'absolute'
  }
}

export const modalFilters = {
  overlay: {
    bottom: '1%', 
    top: '1%', 
    right: '15%', 
    left: '13%', 
    backgroundColor: 'transparent', 
    color: '#0A50C7',
    fontSize: '1em',
    minWidth: 400,
    borderRadius: '10%'
    
  },
  content: {
    background: 'white',
    opacity: 1,
  }
}

export const staticfield = { // not editable
  marginTop: 15,
}

export const selectstatic = { 
  paddingTop: 15
}


export const Title = styled(Input) `
  color: #0A50C7;
  font-size: 1.1rem;
  margin-top: 10px;
  margin-bottom: 0px;
`;

export const TitleNoMargin = styled(Input) `
  color: #0A50C7;
  font-size: 1.7vw;
  margin-top: 10px;
  margin-bottom: 0px;
`;

export const TitleWithMargin = styled(Input) `
  color: #0A50C7;
  font-size: 20px;
  margin-top: 50px;
`;

export const TitleOrange = styled(Input) `
  color: #FF8300;
  font-size: 16pt;
`;


export const Label = styled(Input) `
  color: black;
  margin: 5;
  font-size: 1rem;
`;

export const LabelSmall = styled(Input) `
  color: #0A50C7;
  font-size: 12pt;
`;


export const WhiteLabel = styled(Input) `
  color: white;
`;

export const divIconWithText = {
  display: 'inline-block', 
  width: '90%' ,
}

export const divWithBorder = {
  borderBottom: '1px solid', 
  borderColor: '#0A50C7', 
  marginLeft: 10, 
  width: '96%', 
  marginTop: 5, 
  marginBottom: 10, 
}

export const iconLeftFromText = {
  color: '#0492C1', 
  marginLeft: 20, 
  marginRight: 10, 
  marginTop: '2vh',
  width: '1.8vw',
  height: '1.8vw',
  maxWidth: 30,
  maxHeight: 30,
  minWidth: 25,
  minHeight: 25, 
}

export const iconLeftFromTextForm = {
  color: '#0492C1', 
  marginLeft: 0, 
  marginRight: 10, 
  marginTop: '2vh',
  width: '1.8vw',
  height: '1.8vw',
  maxWidth: 30,
  minWidth: 25,
  minHeight: 25, 
}

export const titleSmallWhite = {
  fontSize: '1vw',
  color: 'white',
}

export const title = {
  fontSize: '1.5rem',
  color: 'black',
  fontWeight: 'bold'
}

export const titleWhite = {
  fontSize: '1.8vw',
  color: 'white'
}

export const titleSmaller = {
  fontSize: '1.1rem',
  color: 'black',
  fontWeight: 'bold'
}


export const titleSmall = {
  fontSize: '1.2rem',
  fontWeight: 'bold'
}

export const titleSmallBlue = { //not blue anymore
  fontSize: '1rem',
  color: 'black',
  fontWeight: 'bold',
}

export const titleSmallBlueCenter = {
  fontSize: '1.2rem',
  color: '#0492C1',
  textAlign: 'center'
}

export const titleSmallPetrol = {
  fontSize: '1.2rem',
  color: '#0492C1',
}


export const subtitle = {
  fontSize: '1rem',
  color: '#808080'
}

export const subtitleSmallPetrol = {
  fontSize: '0.8rem',
  color: '#0492C1',
  float: 'right'
}

export const smallSubtitle = {
  fontSize: '0.7rem',
  color: '#808080'
}

export const blackNormalText = {
  fontSize: '0.875rem',
  textAlign: 'justify',  
}

export const blackTextPhone = {
  fontSize: '0.875rem',
  textAlign: 'justify',  
  fontWeight: 'bold'
}

export const blackText = {
  fontSize: '1rem',
  textAlign: 'justify',  
  fontWeight: 'bold'
}

export const Text = styled(Input) `
  color: #0492C1;
  padding-right: 40px;
  font-size: 0.8em;
`;

export const StyledBadge = styled(Badge) `
    margin-left: -40px;
`;

export const theme = createMuiTheme({
  typography: {
    fontFamily: 'Comfortaa',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '*::-webkit-scrollbar': {
          width: '0.2em',
        },
        '*::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: '#0492C1',
          outline: '1px solid slategrey',
          height: 100
        }
      }
    },
    MuiIcon: {
      root: {
        overflow: 'none'
      }
    },
    MuiSelect: {
      icon: {
        color: '#0492C1',
        fontSize: 30, 
        marginTop: -10
      }
    },
    MuiFormControlLabel: {
      root: {
        color: 'black'
      }
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        fontSize: '0.975rem',
        "&:hover": {
          backgroundColor: "transparent"
      }
      },
      text: {
        color: 'white',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      outlined: {
        backgroundColor: '#FF8300',
        color: 'white',
        borderRadius: '40px',
        textTransform: 'none',
        height: 70,
        width: '20vw',
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        borderColor: 'transparent',
        '&:hover': {
          color:  '#FF8300',
          backgroundColor: 'transparent',
          border: '2px solid #FF8300',
          transitionDuration: '0.5s',
        }
      },
      contained : {
        backgroundColor: '#0492C1',
        color: 'white',
        borderRadius: '20px',
        textTransform: 'none',
        width: 200,
        height: 30,
        '&:hover': {
          color:  '#0492C1',
          backgroundColor: 'transparent' ,
          border: '2px solid #0492C1',
          transitionDuration: '0.5s',
        }
      },      
    },
    MuiGrid: {
      item: {
        margin: 0,
        padding: 0,
        paddingLeft: 30,
        position: 'relative',
        marginTop: 10
      }
    },
    MuiDivider: {
      root: {
        marginTop: 30,
        backgroundColor: '#0A50C7',
        width: 450
      }
    },
    MuiMenu: {
      list: {
        width: 200,
      }
    },
    MuiBadge: {
      badge: {
        padding: 0,
        width: 150,
      }
    },
    MuiPaper: {
      elevation6: {
        boxShadow: '0px 3px 5px -1px rgb(232, 232, 232)',
      }
    },
    MuiNativeSelect: {
      icon: {
        color: '#0492C1'
      }
    },
    MuiTooltip : {
      tooltipPlacement: {
        fontSize: 20
      }
    },
    MuiLink: {
      underlineHover: {
        cursor: 'pointer'
      }
    }
  },
  palette: {
    background: {
      default: "#F9F7F7"
    },
    primary: {
      main: '#0A50C7',
    },
    secondary: {
      main: '#0492C1',
    },
  },
  
});
