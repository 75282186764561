import { React, useState, useRef, useEffect } from 'react'
import axios from 'axios'
import { useParams } from "react-router-dom";
import CreatableSelect from 'react-select/creatable'
import Select, {components} from 'react-select'
import validator from 'validator'
import {Button, Grid, TextField,} from '@material-ui/core'  
import {CgWebsite} from 'react-icons/cg'  
import {AiOutlineInstagram, AiOutlineFacebook, AiOutlineLinkedin, AiOutlineYoutube} from 'react-icons/ai'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Loader from "react-loader-spinner";

import {SCIENTIFIC_DOMAIN, SCIENTIFIC_SUBDOMAIN, LEGAL_STATUS, GEOGRAPHIC_LOCATION}  from './constants.js'
import GetRenders, {URLS, convertToOptions} from './util.js'
import { notify } from './notifications.js'
import Header from './Header'
import CustomAvatar from "./CustomAvatar";
import Footer from './Footer'
import OtherFilter from './OtherFilter'
import {optionColours, textfield, controlStyles, rightContainer, 
        tab, divWithBorder, titleSmall, iconLeftFromText, divIconWithText} from './styles.js'
import {EditableTextField} from './EditableTextField'
import {Versions} from './Versions'



export default function ProviderProfile() {
        const params = useParams()    
        const [other, setOther] = useState('')
        const [open, setOpen] = useState(false) 
        const [proposal, setProposal] = useState('')
        const [sso, setSso] = useState([])
        const ref = useRef(null)
        const [provider, setProvider] = useState({})
        const [updates, setUpdates] = useState([])
        const [rerender, setRerender] = useState(0)
        const [value, setValue] = useState('one')
        const [providerContact, setProviderContact] = useState({}) 
        const [providerLocation, setProviderLocation] = useState({}) 
        const [logo, setLogo] = useState('')  
        const [edit, setEdit] = useState(false)  
        const [showLoader, setShowLoader] = useState(false)  
        const mobile = GetRenders().DOWN_SM
                
        useEffect(() => {
                async function fetch() {
                        try {
                                const r = await axios.get(URLS.SERVER + '/providers/provider/?id=' + params['id'], 
                                                {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                                setProvider(r.data)
                                setLogo(r.data['logo'])
                                handleChangeListOptionsInitial(r.data['scientific_domain'])
                                const r1 = await axios.get(URLS.SERVER + '/providers/edit_dates/?id=' + params['id'], 
                                                {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                                setUpdates(r1.data)
                                const r2 = await axios.get(URLS.SERVER + '/providers_details/by/?id=' + params['id'], 
                                                {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                                setProviderContact(r2.data['contact'])
                                setProviderLocation(r2.data['location'])
                                const r3 = await axios.get(URLS.SERVER + '/providers/edit/?id=' + params['id'], 
                                        {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                                setEdit(r3.data)
                                if (!r3.data) {
                                        setShowLoader(true)
                                        window.location.href = URLS.PROVIDER + params['id']
                                }
                        } catch (e) {
                                console.log(e)
                                try {            
                                        notify(e.response.data, 'warning')
                                } catch (e) {
                                        notify('There is no connection to server. Please try again later.', 'danger')
                                }
                        }
                }
                fetch()
        }, [])

                                        
   const handleChangeUrl = (event) => {
        if(event.target.value === "") {

        } else if (validator.isURL(event.target.value)) {
            if (event.target.value.startsWith('http')) {
                provider[event.target.name] = event.target.value
            } else {
                provider[event.target.name] = 'http://' + event.target.value
                }
        } else {
                notify('This is not a valid url.', 'warning')
        }
    }
    
    const handleChange = (event) => provider[event.target.name] = event.target.value
    const handleChangeList = (value, name) => { 
        var lista = []
        value.map((item, i) => {
            if (item.value === 'Other') {
                setOther(name)
                setOpen(true)
            }
            lista = [...lista, item.value] 
        })
        provider[name] = lista
        setRerender(rerender+1)
    } 

    const handleChangeListOptionsInitial = (value) => { 
         var scientific_subdomain_options = []
         value.map((item, i) => {
             scientific_subdomain_options = [...scientific_subdomain_options, {label: item, options:  SCIENTIFIC_SUBDOMAIN[item]}]
         })
         setSso(scientific_subdomain_options)
     }

    const handleChangeListOptions = (value) => { 
       var lista = []
        var scientific_subdomain_options = []
        value.map((item, i) => {
            if (item.value === 'Other') {
                setOther('scientific domain')
                setOpen(true)
            }
            lista = [...lista, item.value] 
            scientific_subdomain_options = [...scientific_subdomain_options, {label: item.value, options:  SCIENTIFIC_SUBDOMAIN[item.value]}]
        })
        setSso(scientific_subdomain_options)
        provider[ref.current.props.name] = lista
        setRerender(rerender+1)
    }

    const saveProvider = async (event) => {
        event.preventDefault()
        try {
                provider['logo'] = logo
                const r = await axios.post(URLS.SERVER + '/providers/update_provider/?id=' + params['id'] , {...provider}, 
                                        {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                setRerender(rerender+1)
                window.location.href = URLS.PROVIDER + params['id']
        } catch (error) {
                try {            
                        notify(error.response.data, 'warning')
                } catch (e) {
                        console.log(error)
                        if(error.response.status === 403) {
                                notify('You are not allowed to do this action.', 'danger')
                        } else {
                                notify('There is no connection to server. Please try again later.', 'danger')
                        }
                }
        }
    }

    const updateContact = async (event) => {
        event.preventDefault()
        try {
                const r = await axios.post(URLS.SERVER + '/providers_details/update_contact/?id=' + params['id'] , {...providerContact}, 
                                        {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                setRerender(rerender+1)
        } catch (error) {
                try {            
                        notify(error.response.data, 'warning')
                } catch (e) {
                        console.log(error)
                        if(error.response.status === 403) {
                                notify('You are not allowed to do this action.', 'danger')
                        } else {
                                notify('There is no connection to server. Please try again later.', 'danger')
                        }
                }
        }
    }

    const updateLocation = async (event) => {
        event.preventDefault()
        try {
                const r = await axios.post(URLS.SERVER + '/providers_details/update_location/?id=' + params['id'] , {...providerLocation}, 
                                        {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                setRerender(rerender+1)
        } catch (error) {
                try {            
                        notify(error.response.data, 'warning')
                } catch (e) {
                        console.log(error)
                        if(error.response.status === 403) {
                                notify('You are not allowed to do this action.', 'danger')
                        } else {
                                notify('There is no connection to server. Please try again later.', 'danger')
                        }
                }
        }
    }


    useEffect(() => {}, [rerender])


    if (edit) { 
    return (
        <Grid container style={{height: '110vh', opacity: open ? 0.3 : 1}}>
                <Header/>
                <OtherFilter open={open} setOpen={setOpen} proposal={proposal} setProposal={setProposal} other={other}/>
                <Grid container style={{borderBottom: '1px solid', borderColor: '#E8E8E8', marginLeft: -5, marginBottom: 10}}>
                        <CustomAvatar logo={logo} setLogo={setLogo}/> 
                        <Grid item xs={12} sm={10} md={3} lg={3} style={{marginBottom: 5, marginTop: -10}}>
                                <EditableTextField value={provider['abbreviation']} label="Abbreviation" handleChange={handleChange} name='abbreviation' />
                                <br/> 
                                <EditableTextField value={provider['name']} label="Name" handleChange={handleChange} name='name' />
                        </Grid>
                </Grid>
                <Grid item xs={12} sm={10} md={4} lg={4} >
                        <TextField variant="outlined" placeholder={provider['description']}
                                InputLabelProps={{ shrink: true,}} multiline={true} rows={3}
                                label="Description" name="description" style={{width: '100%', color: 'white', marginBottom: 20}}
                                onChange={handleChange} InputProps={{style: {color: 'black'}}}/> 
                        <Grid container style={{backgroundColor: 'white', borderRadius: 10}}>
                                <div style={divWithBorder}>
                                        <span style={titleSmall}> Contact information </span> 
                                </div>
                                <div style={divIconWithText} >
                                        <CgWebsite style={iconLeftFromText}/>
                                        <TextField variant="outlined" margin="normal" placeholder={provider['website']} size='small'
                                                        InputLabelProps={{ shrink: true, style: {color: 'black'}}} style={textfield} label="Website" name="website" 
                                                          onBlur={handleChangeUrl} />   
                                </div>
                                <div style={divIconWithText} >
                                        <AiOutlineLinkedin style={iconLeftFromText}/>
                                        <TextField variant="outlined" margin="normal" placeholder={provider['linkedin']} size='small'
                                                InputLabelProps={{ shrink: true, style: {color: 'black'}}} label="LinkedIn" name="linkedin"
                                                onBlur={handleChangeUrl} style={textfield} />
                                </div>
                                <div style={divIconWithText} >
                                        <AiOutlineFacebook style={iconLeftFromText}/>
                                        <TextField variant="outlined" margin="normal" placeholder={provider['fb']} size='small'
                                                InputLabelProps={{ shrink: true, style: {color: 'black'}}} label="Facebook" name="fb"
                                                onBlur={handleChangeUrl} style={textfield} />
                                </div>
                                <div style={divIconWithText} >
                                        <AiOutlineInstagram style={iconLeftFromText}/>
                                        <TextField variant="outlined" margin="normal" placeholder={provider['insta']} size='small'
                                                InputLabelProps={{ shrink: true, style: {color: 'black'}}} label="Instagram" name="insta"
                                                onBlur={handleChangeUrl} style={textfield} />
                                </div>
                                <div style={divIconWithText} >
                                        <AiOutlineYoutube  style={{marginBottom: 20,  color: '#0492C1', marginLeft: 20, marginRight: 10, 
                                                                marginTop: '2vh', width: '1.8vw', height: '2vw', maxWidth: 50, minWidth: 25,
                                                                minHeight: 25}}/>
                                        <TextField variant="outlined" margin="normal" placeholder={provider['yt']} size='small'
                                                InputLabelProps={{ shrink: true, style: {color: 'black'}}} label="Youtube" name="yt"
                                                onBlur={handleChangeUrl} style={textfield} />
                                </div>
                        </Grid>
                </Grid>
                <Grid item xs={12} sm={11} md={8} lg={8}>
                        <Tabs value={value} onChange={(e, newValue) => setValue(newValue)} variant='scrollable'
                                style={{backgroundColor: 'white',  borderLeft: `1px solid`,  borderColor: '#E8E8E8', 
                                        maxWidth: '92%', borderRadius: 10 }} 
                                orientation='horizontal'  textColor="secondary" >
                                <Tab value="one" label="About " style={tab}/>
                                <Tab value="two" label="Legal" style={tab}/>
                                <Tab value="three" label="Other" style={tab}/>
                                <Tab value="four" label="Location" style={tab}/>
                                <Tab value="five" label="Contact" style={tab}/>
                                <Tab value="six" label="Versions" style={{textTransform: 'none'}}/>
                        </Tabs>
                        <Grid item xs={11} sm={11} md={11} lg={11} style={rightContainer}>
                        {value === 'one' ?
                                <Grid container style={{marginLeft: -15}} >
                                        <Grid item xs={12} ms={3} md={5} lg={4}>
                                                <img src='/multimedia.jpg' style={{width: 200}} />
                                        </Grid>
                                        <Grid item xs={12} sm={9} md={7} lg={8}>                      
                                                <CreatableSelect isMulti name='multimedia' onChange={(e) => handleChangeList(e, 'multimedia')} noOptionsMessage={() => null}
                                                        styles={optionColours} 
                                                        placeholder='Add video/photo urls' value={convertToOptions(provider['multimedia'])} 
                                                        components={{DropdownIndicator:() => null, IndicatorSeparator:() => null, Control: props => <div style={controlStyles}>
                                                        {<p> Add photo/video urls</p>}
                                                        <components.Control {...props} />
                                                        </div> }} /> 
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={6} > 
                                                <Select isMulti name="scientific_domain" ref={ref} value={convertToOptions(provider['scientific_domain'])}
                                                        onChange={handleChangeListOptions}  options={SCIENTIFIC_DOMAIN} 
                                                        styles={optionColours} placeholder='Scientific Domain' components={{Control: props => <div style={controlStyles}>
                                                        {<p> Scientific domain</p>}
                                                        <components.Control {...props} />
                                                        </div> }} /> 
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={6} > 
                                                <Select isMulti name="scientific_subdomain" value={convertToOptions(provider['scientific_subdomain'])}
                                                        onChange={(e) => handleChangeList(e, 'scientific_subdomain')} options={sso}
                                                        styles={optionColours} placeholder='Scientific Subdomain' components={{Control: props => <div style={controlStyles}>
                                                        {<p> Scientific subdomain</p>}
                                                        <components.Control {...props} />
                                                        </div> }} /> 
                                        </Grid> 
                                        <Grid item xs={12} sm={12} md={12} lg={6} > 
                                                <CreatableSelect isMulti name='tags' onChange={(e) => handleChangeList(e, 'tags')} noOptionsMessage={() => null}
                                                        styles={optionColours}  placeholder='Tags' value={convertToOptions(provider['tags'])} 
                                                        components={{DropdownIndicator:() => null, IndicatorSeparator:() => null, Control: props => <div style={controlStyles}>
                                                        {<p> Tags</p>}
                                                        <components.Control {...props} />
                                                        </div> }} /> 
                                        </Grid>  
                                </Grid>
                        : null}
                        {value === 'two' ?
                                <Grid container style={{marginLeft: -15, marginTop: -10}} >
                                        <Grid item xs={12} sm={12} md={12} lg={6} > 
                                                <EditableTextField value={provider['tax_id']} label="Tax Id" handleChange={handleChange} name='tax_id' />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={6} > 
                                                <EditableTextField value={provider['company_registration_name']} label="Company Registration Name" 
                                                        handleChange={handleChange} name='company_registration_name' /> 
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={6} > 
                                                <Select isMulti name="legal_status" style={textfield}
                                                        onChange={(e) => handleChangeList(e, 'legal_status')} options={LEGAL_STATUS}
                                                        styles={optionColours} value={convertToOptions(provider['legal_status'])}
                                                        placeholder='Legal status' components={{ Control: props => <div style={controlStyles}>
                                                        {<p> Legal status</p>}
                                                        <components.Control {...props} />
                                                        </div> }} />              
                                        </Grid>
                                </Grid>
                        : null}
                        {value === 'three' ? 
                                <Grid container style={{marginLeft: -15, marginTop: -20}} >
                                        <Grid item xs={12} sm={12} md={6} lg={6} > 
                                                <CreatableSelect isMulti name='certifications' onChange={(e) => handleChangeList(e, 'certifications')}
                                                        noOptionsMessage={() => null} styles={optionColours} 
                                                        placeholder='Certifications' value={convertToOptions(provider['certifications'])} 
                                                        components={{DropdownIndicator:() => null, IndicatorSeparator:() => null, Control: props => <div style={controlStyles}>
                                                        {<p> Certifications</p>}
                                                        <components.Control {...props} />
                                                        </div> }} />                     
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} > 
                                                <CreatableSelect isMulti name='participating_countries' onChange={(e) => handleChangeList(e, 'participating_countries')} styles={optionColours} 
                                                        noOptionsMessage={() => null} placeholder='Participating Countries' value={convertToOptions(provider['participating_countries'])}
                                                        components={{DropdownIndicator:() => null, IndicatorSeparator:() => null,  Control: props => <div style={controlStyles}>
                                                        {<p> Participating countries</p>}
                                                        <components.Control {...props} />
                                                        </div> }} />                    
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} > 
                                                <CreatableSelect isMulti name='affiliations' onChange={(e) => handleChangeList(e, 'affiliations')} styles={optionColours} 
                                                        noOptionsMessage={() => null} placeholder='Affiliations' value={convertToOptions(provider['affiliations'])} 
                                                        components={{DropdownIndicator:() => null, IndicatorSeparator:() => null, Control: props => <div style={controlStyles}>
                                                        {<p> Affiliations</p>}
                                                        <components.Control {...props} />
                                                        </div> }} />                     
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} > 
                                                <CreatableSelect isMulti name='networks' onChange={(e) => handleChangeList(e, 'networks')} styles={optionColours} 
                                                        noOptionsMessage={() => null} placeholder='Networks' value={convertToOptions(provider['networks'])} 
                                                        components={{DropdownIndicator:() => null, IndicatorSeparator:() => null, Control: props => <div style={controlStyles}>
                                                        {<p> Networks</p>}
                                                        <components.Control {...props} />
                                                        </div> }} />                   
                                        </Grid>                
                                        <Grid item xs={12} sm={12} md={6} lg={6} > 
                                                <CreatableSelect isMulti name='expertise' onChange={(e) => handleChangeList(e, 'expertise')} noOptionsMessage={() => null}
                                                        placeholder='Expertise' value={convertToOptions(provider['expertise'])} styles={optionColours} 
                                                        components={{DropdownIndicator:() => null, IndicatorSeparator:() => null, Control: props => <div style={controlStyles}>
                                                        {<p> Expertise</p>}
                                                        <components.Control {...props} />
                                                        </div> }} />          
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} > 
                                                <CreatableSelect isMulti name='areas_of_activity' onChange={(e) => handleChangeList(e, 'areas_of_activity')} noOptionsMessage={() => null}
                                                        placeholder='Areas of Activity' value={convertToOptions(provider['areas_of_activity'])} styles={optionColours} 
                                                        components={{DropdownIndicator:() => null, IndicatorSeparator:() => null, Control: props => <div style={controlStyles}>
                                                        {<p> Areas of activity</p>}
                                                        <components.Control {...props} />
                                                        </div> }} />  
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} > 
                                                <CreatableSelect isMulti name='esfri_domains' onChange={(e) => handleChangeList(e, 'esfri_domains')} styles={optionColours} 
                                                        noOptionsMessage={() => null} placeholder='Esfri Domains' value={convertToOptions(provider['esfri_domains'])} 
                                                        components={{DropdownIndicator:() => null, IndicatorSeparator:() => null, Control: props => <div style={controlStyles}>
                                                        {<p> Esfri domains</p>}
                                                        <components.Control {...props} />
                                                        </div> }} /> 
                                        </Grid>                                        
                                </Grid>
                                : null}
                                {value === 'four' ? 
                                        <Grid container style={{marginLeft: -15, marginTop: -10}} >
                                                <Grid item xs={12} sm={12} md={6} lg={6} > 
                                                        <TextField variant="outlined" margin="normal" required
                                                                label="Address" name="address"  InputLabelProps={{ shrink: true, style: {color: 'black'}}}
                                                                onChange={(e) => providerLocation[e.target.name] = e.target.value} size='small'
                                                                style={textfield} placeholder={providerLocation ? providerLocation['address'] : 'Address'} />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6} > 
                                                        <TextField variant="outlined" margin="normal" required
                                                                label="Postal code" name="postal_code"  InputLabelProps={{ shrink: true, style: {color: 'black'}}}
                                                                onChange={(e) => providerLocation[e.target.name] = e.target.value} size='small'
                                                                style={textfield} placeholder={providerLocation ? providerLocation['postal_code'] : 'Postal Code'} />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6} > 
                                                        <TextField variant="outlined" margin="normal" required
                                                                label="City" name="city"  InputLabelProps={{ shrink: true, style: {color: 'black'}}}
                                                                onChange={(e) => providerLocation[e.target.name] = e.target.value} size='small'
                                                                style={textfield} placeholder={providerLocation ? providerLocation['city'] : 'City'} />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6} > 
                                                        <TextField variant="outlined" margin="normal" 
                                                                label="Region" name="region"  InputLabelProps={{ shrink: true, style: {color: 'black'}}}
                                                                onChange={(e) => providerLocation[e.target.name] = e.target.value} size='small'
                                                                style={textfield} placeholder={providerLocation ? providerLocation['region'] : 'Region'} />
                                                </Grid> 
                                                <Grid item xs={12} sm={12} md={6} lg={6} > 
                                                        <TextField variant="outlined" margin="normal" required  InputLabelProps={{ shrink: true, style: {color: 'black'}}}
                                                                label="Billing Address" name="billing_address"  size='small'
                                                                onChange={(e) => providerLocation[e.target.name] = e.target.value} 
                                                                style={textfield} placeholder={providerLocation ? providerLocation['billing_address'] : 'Billing Address'} />
                                                </Grid>
                                                <Grid item xs={false} ms={6} md={false} lg={6}></Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6} style={{marginTop: -3}}> 
                                                        <Select name="country"  onChange={(e) => {providerLocation['country'] = e.value; setRerender(rerender+1)}} 
                                                                options={GEOGRAPHIC_LOCATION} styles={optionColours} 
                                                                noOptionsMessage={() => null}  value={{label: providerLocation['country'], value: providerLocation['country']}} 
                                                                components={{MultiValueRemove: () => null, IndicatorSeparator:() => null, Control: props => <div style={controlStyles}>
                                                                {<p> Country*</p>}
                                                                <components.Control {...props} />
                                                                </div> }} />  
                                                </Grid>  
                                        </Grid>
                                : null}
                                {value === 'five' ? 
                                        <Grid container style={{marginLeft: -15, marginTop: -10}} >
                                                <Grid item xs={12} sm={12} md={6} lg={6} > 
                                                        <TextField variant="outlined" margin="normal" required
                                                                label="First name" name="first_name" InputLabelProps={{ shrink: true, style: {color: 'black'}}}                                 
                                                                onChange={(e) => providerContact[e.target.name] = e.target.value} size='small'
                                                                style={textfield} placeholder={providerContact ? providerContact['first_name'] : 'First Name'} />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6} > 
                                                        <TextField variant="outlined" margin="normal" required
                                                                label="Last name" name="last_name" InputLabelProps={{ shrink: true, style: {color: 'black'}}}
                                                                onChange={(e) => providerContact[e.target.name] = e.target.value} size='small'
                                                                style={textfield} placeholder={providerContact ? providerContact['last_name'] : 'Last Name'} />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6} > 
                                                        <TextField variant="outlined" margin="normal" required
                                                                label="Email" name="email" InputLabelProps={{ shrink: true, style: {color: 'black'}}}
                                                                onChange={(e) => providerContact[e.target.name] = e.target.value} size='small'
                                                                style={textfield} placeholder={providerContact ? providerContact['email'] : 'Email'} />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6} > 
                                                        <TextField variant="outlined" margin="normal" 
                                                                label="Phone" name="phone" InputLabelProps={{ shrink: true, style: {color: 'black'}}}
                                                                onChange={(e) => providerContact[e.target.name] = e.target.value}  size='small'
                                                                style={textfield} placeholder={providerContact ? providerContact['phone'] : 'Phone'} />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6} > 
                                                        <TextField variant="outlined" margin="normal" 
                                                                label="Position" name="position" InputLabelProps={{ shrink: true, style: {color: 'black'}}}
                                                                onChange={(e) => providerContact[e.target.name] = e.target.value} size='small'
                                                                style={textfield} placeholder={providerContact ? providerContact['position'] : 'Position'} />
                                                </Grid>
                                                <Grid item xs={false} ms={6} md={6} lg={6}></Grid>
                                        </Grid>  
                                : null}
                                {value === 'six' ? 
                                        <Versions updates={updates} provider={true} edit={edit}/>
                                : null}
                        </Grid>
                        <Grid item xs={10} sm={11} md={11} lg={11} style={{marginTop: 20}}>
                                <Button variant='outlined' onClick={(e) => {saveProvider(e); 
                                                                        if (Object.keys(providerLocation).length !== 0) {updateLocation(e) }
                                                                        if (Object.keys(providerContact).length !== 0 ) {updateContact(e)}
                                                                }} 
                                                style={{ width: 200, height: 40, float: 'right'}}> 
                                        Save & Exit 
                                </Button>
                                <button className="exitButton" onClick={(e) =>  window.location.href = URLS.PROVIDER + params['id']} > 
                                        Exit without saving
                                </button>
                        </Grid>
                </Grid>
                <Footer/> 
        </Grid> 
        ) } else {
                return (
                        <Loader type="Oval" color="#0A50C7" height={100} width={100} visible={showLoader} style={{position: 'fixed', top: '50%', left: '50%'}}/>
                )}

}