import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './App.css'
import GetRenders from './util';

export const ImageCarousel = (props) => {
    const phone = GetRenders().DOWN_SM

    return (
        <Carousel autoPlay interval={1000} showStatus={false} showThumbs={false} centerMode centerSlidePercentage={phone ? 100 : 300 / props.images.length} className='carouselLanding'
                showArrows showIndicators={false} infiniteLoop>
            {props.images.map(image => 
                <img style={{ width: 100,  margin: 5, paddingTop: 30 }} src={image}/>
                )}
        </Carousel>
    )
}