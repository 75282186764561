import { titleSmallBlue} from './styles.js'



export const StaticMultipleSelect = ({value, title}) => {

    return (
        <div>
            <span style={titleSmallBlue}>{title}</span>
            <br/> <br/>
            <div>
                {value.map((item, i) => 
                    <span key={i} style={{backgroundColor: '#E8E8E8', padding: 5, marginRight: 5, borderRadius: '10%', fontSize: '0.775rem '}}> {item} </span>
                )}
            </div>
            <br/> 
        </div>
    )
} 