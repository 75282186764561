import { React } from 'react'
import {Grid, Link, Button, Tooltip, Zoom, Paper } from '@material-ui/core'
import { useParams } from "react-router-dom";

import {AiFillSetting} from 'react-icons/ai'
import GetRenders, {URLS} from './util.js'
import { blackNormalText, titleSmaller} from './styles.js'


export default function ResourceCard ({item, edit}) {
    const params = useParams()
    const lg = GetRenders().LG


    return (
        <Grid container style={{width: lg ? '89%' : '82%', marginBottom: 30, marginLeft: 30, minHeight: 100, padding: 10}} component={Paper} elevation={3}>
            <Grid item xs={2} sm={2} md={2} lg={1} xl={1}>
                <img src={!item.logo ? '/placeholder.png' : item.logo } style={{width: 70, marginLeft: -30}}/>
            </Grid>
            <Grid item xs={7} sm={8} md={8} lg={10} style={{marginBottom: 8}}>
                <div >
                    <Link href={URLS.RESOURCES + '/resource/' + item.uuid} style={titleSmaller}>
                        {item.name} 
                    </Link>  
                    <br/>
                    <span style={blackNormalText}> {item.tagline}</span>  
                    <br/> <br/>
                    {item.tags.map((tag, i) => 
                        <Link key={i} > #{tag} </Link>
                    )} 
                </div>                
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1}>
                <Tooltip title='Resource' TransitionComponent={Zoom} placement='top' >
                    <img src='/res1.svg' style={{width: 30, height: 30, float: 'right', marginTop: -5}}/>
                </Tooltip>
                <br/>
                {edit ?
                    <Tooltip title='Edit resource' TransitionComponent={Zoom} placement='bottom' style={{width: 10}}>
                        <Button variant='none' endIcon={ <AiFillSetting size={20} style={{color: 'gray', marginBottom: 7, marginLeft: lg ? -46 : '-6vw'}}/> } 
                                onClick={() =>  window.location.href='/resources/edit/' + item.uuid} className={'step3'}/> 
                    </Tooltip>
                : null}
            </Grid>
        </Grid>
    )

}
