import { React } from 'react'
import {Grid, } from '@material-ui/core'    
import { titleSmallBlue, blackTextPhone } from './styles.js'
import GetRenders from './util.js'


export default function WhatWeOffer(props) {
    const phone = GetRenders().XS


    return (
        <>
        <Grid container style={{marginBottom: 50, marginLeft: 'auto', marginRight: 'auto'}} >
            <Grid item xs={6} sm={6} md={3} lg={3} >
                <img src='/landing1.png' style={{height: phone ? 130 : 200}} />
                <br/>
                <div style={{marginLeft: '1.5vw'}}>
                    <span style={phone ? blackTextPhone : titleSmallBlue}> Discover services to </span>
                    <br/>
                    <span style={phone ? blackTextPhone : titleSmallBlue}> support your R&D</span>
                </div>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} >
                <img src='/landing2.png' style={{height: phone ? 130 : 200}} />
                <br/>
                <div style={{marginLeft: '1vw'}}>
                    <span style={phone ? blackTextPhone : titleSmallBlue}> Showcase your expert </span>
                    <br/>
                    <span style={phone ? blackTextPhone : titleSmallBlue}> services and resources</span>
                </div>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} >
                <img src='/landing3.png' style={{height: phone ? 130 : 200}} />
                <br/>
                <div style={{marginLeft: '1vw'}}>
                    <span style={phone ? blackTextPhone : titleSmallBlue}> Find specialized </span>
                    <br/>
                    <span style={phone ? blackTextPhone : titleSmallBlue}> partners</span>
                </div>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} >
                <img src='/landing4.png' style={{height: phone ? 130 : 200}} />
                <br/>
                <div style={{marginLeft: '0vw'}}>
                    <span style={phone ? blackTextPhone : titleSmallBlue}> Team up with science and </span>
                    <br/>
                    <span style={phone ? blackTextPhone : titleSmallBlue}> technology experts</span>
                </div>
            </Grid>
        </Grid>
        </>
    )
}