import { React } from 'react'
import { ArrowForwardIos } from '@material-ui/icons';
import { Button, Checkbox, Grid, FormControlLabel, Paper } from '@material-ui/core'


export default function FilteringExpandable(props) {


    return (
        <Grid item style={{marginLeft: -55}}>
        {props.expand ? 
            <Grid container style={{ marginTop: -30, marginBottom: 10}}>
                <Grid item xs={12} ms={12} md={12} lg={12} style={{borderBottom: '1px solid #0A50C7', marginRight: 0}}>
                    <Button variant='none' startIcon={<ArrowForwardIos size={20} style={{color: '#0A50C7'}}/>}  style={{marginLeft: -20, color: 'black'}}
                        onClick={() => {props.removeParameters(props.subdict[props.expand], props.type); props.setExpand('');  props.filter()}}>
                        {props.expand}
                    </Button>
                </Grid>
                {props.subdict[props.expand].map((item, i) => 
                    <Grid item xs={12} sm={6} md={4} lg={4} key={i} >
                        <FormControlLabel control={
                            <Checkbox name={item.value} name={item.value} onChange={(e) => {props.addFilterParameter(e, 's' + props.type); props.filter()}}
                                disabled={props.disabled ? props.disabled : false} 
                                checked={props.checked_subdict[item.value] && props.checked_subdict[item.value] !== 'undefined' ? true : false}/> }
                            label={<span style={{fontSize: '0.9rem'}}>{item.value} </span>} />
                    </Grid>
                )}
            </Grid>
            : 
            <Grid container style={{marginTop: -40}}>
                {props.dict.map((item, i) => {
                if (item.value !== props.expand) { 
                    return (
                        <Grid item xs={12} sm={12} md={12} lg={6} key={i} style={{marginLeft: -20}}>
                            <Button variant='none' startIcon={<ArrowForwardIos size={20} style={{color: '#0A50C7'}} 
                                onClick={() => props.disabled ? props.setAlertAuth(true) : props.setExpand(item.value)}/>}>
                                <FormControlLabel control={
                                    <Checkbox name={item.value} onChange={(e) => {props.addFilterParameter(e, props.type); props.filter()}}
                                        disabled={props.disabled ? props.disabled : false} checked={props.checked_dict} 
                                        checked={props.checked_dict[item.value] && props.checked_dict[item.value] !== 'undefined' ?  true : false}/> }
                                    label={item.value} />
                            </Button>
                        </Grid>
                    )}}
                )}
            </Grid>}
        </Grid>
    )
}