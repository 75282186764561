import { React, useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import axios from 'axios'
import {AiFillSetting, AiFillStar, AiOutlineStar} from 'react-icons/ai'
import {RiBookmarkFill, RiBookmarkLine} from 'react-icons/ri'
import {FaMapMarker} from 'react-icons/fa'
import {MdEmail} from 'react-icons/md'
import {TiDelete} from 'react-icons/ti'
import { Button, Grid, Tooltip, Zoom, Icon, Link } from '@material-ui/core'  
import Rating from '@material-ui/lab/Rating';
import Modal from 'react-modal';
import {CgWebsite} from 'react-icons/cg'
import {RiStarSFill} from 'react-icons/ri'
import ProgressBar from "@ramonak/react-progress-bar";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import GetRenders, {URLS} from './util.js'
import {notify} from './notifications'
import {modal, StyledBadge, titleSmaller} from './styles.js'
import Header from './Header'
import Footer from './Footer'
import { Label, titleSmall, leftContainerResource, avatarContainer, subtitle,
        rightContainer, tab, titleSmallBlue, title,}  from './styles.js'
import { StaticMultipleSelect } from './StaticMultipleSelect.js';
import { Description } from './Description.js';
import { Multimedia } from './Multimedia.js';
import ScientificDomainMap from './ScientificDomainMap.js'
import ResourceInfo from './ResourceInfo.js';


export default function Resource() {
    const params = useParams()
    const [isFav, setIsFav] = useState() 
    const [isRated, setIsRated] = useState()
    const [resource, setResource] = useState()
    const [open, setOpen] = useState(false)
    const [quality, setQuality] = useState(null)
    const [price, setPrice] = useState(null)
    const [communication, setCommunication] = useState(null)
    const [avgRating, setAvgRating] = useState()
    const [edit, setEdit] = useState(false)
    const [completed, setCompleted] = useState(0)
    const [value, setValue] = useState('two')
    const [resourceContact, setResourceContact] = useState({}) 
    const tablet = GetRenders().DOWN_SM
    const md = GetRenders().DOWN_MD

    
    useEffect(() => {
        async function fetch() {
            try {
                const r = await axios.get(URLS.SERVER + '/resources/resource/?id=' + params['id'], 
                                {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                setResource(r.data)
                if (r.data.multimedia.length !== 0) {
                    setValue('one')
                }
                const rounded = Math.round(r.data['cnt'] / 0.38) 
                setCompleted(rounded)
                setIsFav(r.data['isFav'])
                setIsRated(r.data['isRated'])
                const r1 = await axios.get(URLS.SERVER + '/ratings/by?resource_id=' + params['id'], 
                                    {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                setAvgRating(r1.data)
                const r2 = await axios.get(URLS.SERVER + '/providers/edit/?id=' + r.data['provider']['uuid'], 
                                {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                setEdit(r2.data)
                const r3 = await axios.get(URLS.SERVER + '/resources_details/by/?id=' + params['id'], 
                                                {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                setResourceContact(r3.data['contact'])
            } catch (e) {
                console.log(e)
                try {            
                    notify(e.response.data, 'warning')
                } catch (e) {
                    notify('There is no connection to server. Please try again later.', 'danger')
                }
            }
        }
        fetch()
    }, [])

    useEffect(() => {

    }, [isFav, isRated])

    const addFavourite = async (id) => {
        try {
            const r = await axios.post(URLS.SERVER + '/favourites/add/', {'resource_id': id}, 
                            {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
            setIsFav(1)
        } catch (e) {
            console.log(e)
            try {            
                notify(e.response.data, 'warning')
            } catch (e) {
                notify('There is no connection to server. Please try again later.', 'danger')
            }
        }

    }
    
    const removeFavourite = async (id) => {
        try {
            const r = await axios.post(URLS.SERVER + '/favourites/remove/', {'resource_id': id}, 
                            {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
            setIsFav(0)
        } catch (e) {
            try {            
                notify(e.response.data, 'warning')
            } catch (e) {
                notify('There is no connection to server. Please try again later.', 'danger')
            }
        }
    }

    const addRate = async () => {
        if (quality && price && communication) {
            try {
                const r = await axios.post(URLS.SERVER + '/ratings/add/', {'resource_id': resource.uuid,
                                                                        'price': price, 'quality': quality, 'communication': communication}, 
                                {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                setIsRated(1)
                setOpen(false)
            } catch (e) {
                try {            
                    notify(e.response.data, 'warning')
                } catch (e) {
                    notify('There is no connection to server. Please try again later.', 'danger')
                }
            }
        } else {
            notify('Please add all fields.', 'warning')
        }
    }

    const ProviderIcon = (
        <Icon style={{color: '#E8E8E8', marginLeft: -15, marginBottom: 20, width: 30, height: 10}}>
          <img src="/prov.svg" />
        </Icon>
      );


    if (resource) {
    return (
        <Grid container style={{height: '110vh', opacity: open ? 0.3 : 1}}>
            <Header/>
            <Modal isOpen={open} onRequestClose={() => setOpen(false)}  style={modal}>
                <TiDelete style={{float: 'right', width: 30, height: 30}} onClick={() => {setPrice(null); setCommunication(null); setQuality(null); setOpen(false)}}/>
                <h3 style={{color: 'black'}}>Add your rate</h3>
                <label style={{marginRight: 10}}> Quality</label>
                <div style={{marginTop: 10}}>
                    <Rating value={quality} onChange={(e, newValue) => setQuality(newValue)}/>
                </div>
                <br/>
                <label> Price</label> 
                <div style={{marginTop: 10}}>
                    <Rating value={price} onChange={(e, newValue) => setPrice(newValue)}/>
                </div>
                <br/>
                <label> Communication</label>
                <div style={{marginTop: 10}}>
                    <Rating value={communication} onChange={(e, newValue) => setCommunication(newValue)}/>
                </div>
                <div style={{float: 'right'}}>
                    <Button onClick={addRate} variant="contained" style={{marginTop: 10, width: 70, height: 50, textAlign: 'center', float: 'right'}}> Add </Button>
                </div>
            </Modal>
            <Grid container style={avatarContainer}>
                <img style={{marginLeft: 30, width: 130, marginTop: 5}} src={resource['logo'] || resource['logo'] !== null ? resource.logo : '/placeholder1.png'}/>
                <Grid item xs={5} sm={5} md={7} lg={edit? 7 : 8} style={{marginTop: 10}} >
                    <span style={title}> {resource.name} </span>
                    <br/>
                    <span style={{fontSize: '1rem', color: 'black', }}> {resource.tagline}</span> 
                </Grid>
                {!edit ?
                    <Grid item xs={false} sm={3} md={2} lg={2} >
                        {isRated ? 
                            <Tooltip title='Thank you for rating this resource' TransitionComponent={Zoom} placement='bottom' style={{width: 40}}>
                                <Button variant='none' startIcon={<AiFillStar size={25} style={{marginLeft: 20, marginTop: 4, color: '#FF8300'}}/>} />
                            </Tooltip>
                                
                        : 
                            <Tooltip title='Rate resource' TransitionComponent={Zoom} placement='right-end' style={{width: 40}}>
                                <Button variant='none' startIcon={<AiOutlineStar size={25} style={{marginLeft: 20, marginTop: 4, color: '#FF8300'}}/>} onClick={() => setOpen(true)}/>
                            </Tooltip>}
                        {isFav ? 
                            <Tooltip title='Remove resource from favourites' TransitionComponent={Zoom} placement='bottom' style={{width: 40}}>
                                <Button variant='none' startIcon={<RiBookmarkFill size={25} style={{color: '#0A50C7', marginTop: 4}}/>} onClick={() => removeFavourite(resource.uuid)}/>
                            </Tooltip>
                        :
                            <Tooltip title='Add resource to favourites' TransitionComponent={Zoom} placement='bottom' style={{width: 40}}>
                                    <Button variant='none' startIcon={<RiBookmarkLine size={25} style={{color: '#0A50C7', marginTop: 4}}/>} onClick={() =>  addFavourite(resource.uuid) } />
                            </Tooltip>
                        }
                    </Grid>
                : 
                <Grid item xs={false} sm={4} md={3} lg={3} >
                    <div>
                        <Tooltip title='Add more information to increase the resource performance' TransitionComponent={Zoom} placement='bottom'>
                            <Button variant='filled'>
                                    <ProgressBar completed={completed} width={150} height={20} bgColor='#0A50C7' />
                            </Button>
                        </Tooltip> 
                        <Tooltip title='Edit resource' TransitionComponent={Zoom} placement='top' >
                            <Button variant='filled' endIcon={ <AiFillSetting size={25} style={{color: 'gray'}} /> } 
                                onClick={() =>  window.location.href=URLS.RESOURCES + '/edit/' + resource['uuid']} 
                                style={{minWidth: 0, padding: 0}}/>
                        </Tooltip>
                    </div>
                </Grid> }
            </Grid>
            <Grid item xs={11} sm={11} md={4} lg={5} style={leftContainerResource}>
                <div style={{borderBottom: '1px solid', borderColor: '#E8E8E8', paddingBottom: 20, marginBottom: 5, marginTop: 5}}>
                    <Description value={resource.description} profile={true}/>
                    {resource.tags.length !== 0 ?
                        <>
                                <br/>
                                {resource.tags.map((tag, i) => 
                                        <Link key={i} > #{tag} </Link>
                                )} 
                        </>
                    :null}
                </div>
                <br/>
                <div style={{borderBottom: '1px solid', borderColor: '#E8E8E8', marginBottom: 10, width: '99.4%', marginTop: -20}}>
                    <Button variant='filled' startIcon={ProviderIcon} style={{textTransform: 'none', color: 'black', fontSize: '1.1rem'}}
                        href={URLS.PROVIDER + resource.provider.uuid} target="_blank">
                            {resource.provider.abbreviation} 
                    </Button>
                </div>
                <ResourceInfo dict={resource.geographical_availability} title='Available'/> 
                {resource['access_type'].length !== 0 ? 
                    <ResourceInfo dict={resource.access_type} title='Access type'/> 
                : null}
                {resource['access_mode'].length !== 0 ? 
                    <ResourceInfo dict={resource.access_mode} title='Access mode'/> 
                : null}    
                <ResourceInfo dict={resource.language_availability} title='Language'/> 
                <ResourceInfo dict={resource.target_users} title='Target users'/>
                {resource.required_resources.length !== 0 ?
                    <ResourceInfo dict={resource.required_resources} title='Required resources'/> 
                 : null}     
                    <br/> <br/>
                    <Grid container style={{backgroundColor: 'white', borderRadius: 10, marginLeft: -5, paddingBottom: 10}}>
                        <div style={{borderBottom: '1px solid',  borderColor: '#0A50C7',  marginLeft: 10,  width: '96%', 
                                    marginTop: 12,  marginBottom: 10, paddingBottom: 3}}>
                            <span style={titleSmall}> Contact information </span> 
                        </div>
                        <Button variant='filled' startIcon={<CgWebsite size={20} style={{color: '#0492C1', width: '1.8vw', height: '1.8vw', maxWidth: 50,
                                                                                        minWidth: 25, minHeight: 25, }}/>} 
                                    style={{textTransform: 'none', marginLeft: 10}} onClick={() =>  window.open(resource.website, '_blank' )}>
                            {resource.website.split('//').slice(1)}
                        </Button>
                        {Object.keys(resourceContact).length !== 0 ? 
                            <Button variant='filled' startIcon={<MdEmail style={{color: '#0492C1', width: '1.8vw', height: '1.8vw', maxWidth: 50,
                                                    minWidth: 25, minHeight: 25, }}/>} 
                                style={{textTransform: 'none', marginLeft: 10}} href={'mailto:' + resourceContact['email']}>
                                {resourceContact['email']}
                            </Button>
                        : null} 
                         {resource.resource_geographical_location.length !== 0 ?
                                <Button variant='filled' startIcon={<FaMapMarker size={20} style={{color: '#0492C1', width: '1.8vw', height: '1.8vw', maxWidth: 50,
                                                                                        minWidth: 25, minHeight: 25, }}/>} 
                                    style={{textTransform: 'none', marginLeft: 10}} >
                                    {resource.resource_geographical_location}
                                </Button>
                        : null}
                    </Grid>
            </Grid>
            <Grid item xs={11} sm={11} md={8} lg={6} >
                <Tabs value={value} onChange={(e, newValue) => setValue(newValue)} style={{backgroundColor: 'white', width: tablet ? '100%' : '83%', borderLeft: `1px solid`, 
                                                                                            borderColor: '#E8E8E8', borderRadius: 10, marginTop: -10}} 
                        orientation='horizontal' textColor="secondary">
                    {resource.multimedia.length !== 0 ? 
                        <Tab value="one" label="Photos & videos" style={tab} />
                    : null}
                    <Tab value="two" label="About" style={tab} />
                    {resource['lifecycle_status'] ||  resource['standards']   ? 
                    <Tab value='three' label='Maturity' style={{textTransform: 'none'}}/>
                    : null}
                </Tabs>
                <Grid item xs={12} sm={12} md={10} lg={10} style={rightContainer}>
                {value === 'one' ?
                    <Multimedia media={resource.multimedia} resource_id={resource.uuid}/>
                : null}
                {value === 'two' ?
                    <Grid container style={{marginLeft: -25}}>  
                        {resource.scientific_domain.length !== 0 ?
                            <Grid container >
                                    <Grid item xs={12} ms={12} md={12} lg={12} >  
                                            <span style={titleSmallBlue}>Scientific domain</span>
                                    </Grid>
                                    {resource.scientific_domain.map((item, i) => 
                                            <Grid item xs={12} ms={3} md={3} lg={3}>  
                                                    <ScientificDomainMap dm={item} key={i}/>
                                            </Grid>
                                    )}
                            </Grid>
                        : null}
                        <Grid item xs={12} ms={12} md={12} lg={12} >
                                <StaticMultipleSelect value={resource.scientific_subdomain} title='Scientific subdomain' />
                        </Grid>
                        <Grid item xs={12} ms={12} md={12} lg={12} >
                                <StaticMultipleSelect value={resource.category} title='Category' />
                        </Grid>
                        <Grid item xs={12} ms={12} md={12} lg={12} >
                                <StaticMultipleSelect value={resource.subcategory} title='Subcategory' />
                        </Grid>
                        {resource.use_cases.length !== 0 ?
                            <Grid item xs={12} ms={6} md={6} lg={6} >
                                <StaticMultipleSelect value={resource.use_cases} title='Use cases' />
                            </Grid>
                        : null}
                        {resource['certifications'].length !== 0  ? 
                        <Grid item xs={12} ms={6} md={6} lg={6} >
                            <StaticMultipleSelect value={resource.certifications} title='Certifications' /> 
                        </Grid>
                    : null} 
                    </Grid> 
                : null }
            {value === 'three' ? 
               <Grid container style={{marginLeft: -25}}>  
                    <Grid item xs={12} sm={12} md={12} lg={126} >
                        <StaticMultipleSelect value={[resource.technology_readiness_level]} title="Technology readiness level" />
                    </Grid>
                    {resource['lifecycle_status'] ? 
                        <Grid item xs={12} sm={12} md={12} lg={126} >
                            <StaticMultipleSelect value={[resource.lifecycle_status]} title='Lifecycle status' />
                        </Grid>
                    : null} 
                    {resource['standards'].length !== 0 ? 
                        <Grid item xs={12} sm={12} md={12} lg={12} >
                            <StaticMultipleSelect value={resource.standards} title='Standards' /> 
                        </Grid>
                    : null}  
               </Grid> :null}
            </Grid>
            {avgRating && avgRating['ratings_cnt'] !== 0 ? 
            <Grid item xs={12} sm={12} md={10} lg={10} style={{backgroundColor: 'white', borderRadius: 10, minHeight: 105, paddingBottom: 25, paddingTop: 5}}>
                <div style={{borderBottom: '1px solid', borderColor: '#0A50C7',  width: '100%',  marginTop: 5, marginBottom: 20, marginLeft: -15,
                            paddingBottom: 5}}>
                        <span style={titleSmall}> Resource's rating </span> 
                </div>
                <Grid container style={{marginLeft: -40, marginTop: -10}}>
                    <Grid item xs={12} sm={12} md={12} lg={4} >
                            <span style={{fontSize: '0.875rem', marginRight: md ? 120 : '4vw'}}>Quality</span>
                            <StyledBadge badgeContent={avgRating['quality__avg']} >
                                    <RiStarSFill style={{color: '#FF8300'}} size={20}/>
                            </StyledBadge>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4} >
                            <span style={{fontSize: '0.875rem', marginRight: md ? 140 : '5vw',}}>Price</span>
                            <StyledBadge badgeContent={avgRating['price__avg']}>
                                    <RiStarSFill style={{color: '#FF8300'}} size={20}/>
                            </StyledBadge>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4} >
                            <span style={{fontSize: '0.875rem', marginRight: md ? 60: '4vw',}}>Communication</span>
                            <StyledBadge badgeContent={avgRating['communication__avg']} style={{marginRight: 20, }}>
                                    <RiStarSFill style={{color: '#FF8300'}} size={20}/>
                            </StyledBadge>
                    </Grid>
                </Grid>
            </Grid>
            : null}
        </Grid>
        <Footer/>
    </Grid>
    )} else {
        return null
    }
}