import { React, useState, useRef } from 'react'
import axios from 'axios'
import { useParams } from "react-router-dom";
import CreatableSelect from 'react-select/creatable'
import Select from 'react-select'
import validator from 'validator'
import { TextField, Button, Grid, Tooltip } from '@material-ui/core'    
import {AiOutlineQuestion} from 'react-icons/ai'
import {SCIENTIFIC_DOMAIN, SCIENTIFIC_SUBDOMAIN, CATEGORY, SUBCATEGORY, 
        TARGET_USERS, LIFE_CYCLE_STATUS, GEOGRAPHICAL_AVAILABILITY, LANGUAGE_AVAILABILITY, TRL, 
        RESOURCE_ORDER_TYPE, GEOGRAPHIC_LOCATION, 
        }  from './constants.js'
import {optionColours, textfield, Title, divWithBorder, titleSmall} from './styles.js'
import {URLS} from './util.js'
import { notify } from './notifications.js'
import Header from './Header'
import CustomAvatar from './CustomAvatar'
import Footer from './Footer'
import OtherFilter from './OtherFilter'


export default function AddResource() {
    const params = useParams()    

    const [sso, setSso] = useState([])
    const [sco, setSco] = useState([])
    const [other, setOther] = useState('')
    const [open, setOpen] = useState(false)
    const [proposal, setProposal] = useState('')
    const refSo = useRef(null)
    const refCo = useRef(null)
    const [logo, setLogo] = useState('')
    const [resource, setResource] = useState({'name': '', 'website': '', 'description': '', 'tagline': '',
                                            'logo': '', 'multimedia': [], 'use_cases': [], 'category': '',
                                            'subcategory': '', 'target_users': '', 'access_type': [], 'access_mode': [],
                                            'tags': [], 'geographical_availability': '', 'language_availability': '', 
                                            'resource_geographical_location': [], 'helpdesk_email': '', 'security_contact_email': null,
                                            'technology_readiness_level': '', 'lifecycle_status': null, 'certifications': [], 
                                            'standards': [], 'open_source_technologies': [], 'version': null, 
                                            'required_resources': [], 'related_resources': [], 'related_platforms': [],
                                            'funding_body': [], 'funding_program': [], 'grant_project_name': [], 'helpdesk_page': null,
                                            'user_manual': null, 'terms_of_use': null, 'privacy_policy': null, 'access_policy': null, 
                                            'service_level': null, 'training_information': null, 'status_monitoring': null, 'order_type': '',
                                            'order': null, 'payment_model': null, 'pricing': null, 'scientific_domain': '', 'scientific_subdomain': '',
                                            'provider_name': params['name']})

    
    const handleChangeUrl = (event) => {
        if(event.target.value === "") {

        } else if (validator.isURL(event.target.value)) {
            if (event.target.value.startsWith('http')) {
                resource[event.target.name] = event.target.value
            } else {
                resource[event.target.name] = 'http://' + event.target.value
            }
        } else {
            notify('This is not a valid url.', 'warning')
        }
    }

    const handleChangeEmail = (event) => {
        if(event.target.value === "") {

        } else if (validator.isURL(event.target.value)) {
            resource[event.target.name] = event.target.value
        } else {
            notify('This is not a valid email.', 'warning')
        }
    }
    
    const handleChange = (event) => resource[event.target.name] = event.target.value

    const handleChangeLogo = (event) => {
        if (event.target.value === "" || event.target.value === " ") {
            resource[event.target.name] = null  
        } else {
            resource[event.target.name] = event.target.value
            setLogo(event.target.value)
    }}

    const handleChangeList = (value, name) => {
        var lista = []
        value.map((item, i) => {
            if (item.value === 'Other') {
                setOther(name)
                setOpen(true)
            }
            lista = [...lista, item.value] 
        })
        resource[name] = lista
    }

    const handleChangeOne = (event, name) => resource[name] = event.value


    const handleChangeListOptionsSso = (value) => { 
        var lista = []
        var scientific_subdomain_options = []
        value.map((item, i) => {
            if (item.value === 'Other') {
                setOther('scientific domain')
                setOpen(true)
            }
            lista = [...lista, item.value] 
            scientific_subdomain_options = [...scientific_subdomain_options, {label: item.value, options:  SCIENTIFIC_SUBDOMAIN[item.value]}]
        })
        setSso(scientific_subdomain_options)
        resource[refSo.current.props.name] = lista
    }

    const handleChangeListOptionsSco = (value) => { 
        var lista = []
        var subcategory_options = []
        value.map((item, i) => {
            if (item.value === 'Other') {
                setOther('category')
                setOpen(true)
            }
            lista = [...lista, item.value] 
            subcategory_options = [...subcategory_options, {label: item.value, options:  SUBCATEGORY[item.value]}]
        })
        setSco(subcategory_options)
        resource[refCo.current.props.name] = lista
    }

    const addResource = async (event) => {
        event.preventDefault()
        if(resource['category'] && resource['subcategory'] && resource['target_users'] &&
            resource['geographical_availability'] && resource['language_availability'] &&
            resource['technology_readiness_level'] && resource['order_type'] &&
            resource['scientific_domain'] && resource['scientific_subdomain'] && logo) {
            try {
                resource['logo'] = logo
                const r = await axios.post(URLS.SERVER + '/resources/add/', {...resource}, 
                                            {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                window.location.href = URLS.RESOURCES + '/resource/' + r.data;
            } catch (error) {
                try {            
                        console.log(error.response)
                        notify(error.response.data, 'warning')
                } catch (e) {
                        console.log(error)
                        if(error.response.status === 403) {
                                notify('You are not allowed to do this action.', 'danger')
                        } else {
                                notify('There is no connection to server. Please try again later.', 'danger')
                        }
                }
            }
        } else if (!logo) {
            notify('Please add a logo.', 'warning')      
        } else {
            notify('Please fill out all mandatory fields.', 'warning')
        } 
    }


    return (
        <Grid container style={{height: '100vh'}}>
            <Header />
            <OtherFilter open={open} setOpen={setOpen} proposal={proposal} setProposal={setProposal} other={other}/>
            <Grid item sm={1} md={1} lg={3}></Grid>
            <Grid item xs={12} sm={10} md={10} lg={7} style={{marginTop: 40}} >
                <div style={{backgroundColor: 'white', borderRadius: 20, padding: 15, minHeight: 700, maxWidth: 900}}>
                <div style={divWithBorder}>
                        <span style={titleSmall}> Add resource </span>                        
                </div>
                <form onSubmit={addResource} style={{opacity: open ? 0.3 : 1}}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} >
                            <Title value='Basic information' disableUnderline readOnly/> 
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>  
                            <TextField variant="outlined" margin="normal" required size='small'
                                            label="Name" name="name" style={textfield}
                                            onChange={handleChange} /> 
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <TextField variant="outlined" margin="normal" required
                                                label="Helpdesk Email" name="helpdesk_email" style={textfield} size='small'
                                                onBlur={handleChangeEmail} onChange={(e) => resource[e.target.name] = e.target.value}/>
                        </Grid>
                        <Grid item xs={12} sm={5} md={6} lg={6}>
                            <TextField variant="outlined" margin="normal" value={logo}
                                    label="Logo" name="logo" style={{  width: '80%', backgroundColor: '#E8E8E8', borderRadius: '5px',}} 
                                    size='small' onChange={handleChangeLogo} />
                        </Grid>
                        <Tooltip title="You can either upload logo url or upload logo.">
                                <Button variant='none' style={{height: 100, marginLeft: '1vw'}}>
                                        <AiOutlineQuestion size={30}/>
                                </Button> 
                        </Tooltip>
                        <Grid item xs={7} sm={3} md={3} lg={3} style={{paddingLeft: '3vw'}}>
                                <div style={{border: '1px dashed black', width: 170, paddingTop: 10, marginLeft: -40, marginBottom: 10}}>
                                    <CustomAvatar hide={false} logo={logo} setLogo={setLogo}/>   
                                </div>   
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <TextField variant="outlined" margin="normal" required
                                label="Tagline" name="tagline" style={textfield} size='small'
                                onChange={handleChange} /> 
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} >
                                <TextField variant="outlined" margin="normal" required
                                    label="Website" name="website" style={textfield} size='small'
                                    onBlur={handleChangeUrl} />   
                        </Grid>
                        <Grid item xs={12} sm={11} md={12} lg={12}>
                            <TextField variant="outlined" margin="normal" required style={{width: '90%', color: 'white'}}
                                                label="Description" name="description" multiline={true} rows={6}
                                                onChange={handleChange} />  
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} style={{marginTop: 20}}>
                            <Select isMulti isClearable={false} name="geographical_availability" 
                                    onChange={(e) => handleChangeList(e, 'geographical_availability')} options={GEOGRAPHICAL_AVAILABILITY}
                                    styles={optionColours} placeholder='Geographic Availability *'/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} style={{marginTop: 20}}>
                            <Select isMulti isClearable={false} name="language_availability" 
                                    onChange={(e) => handleChangeList(e, 'language_availability')} options={LANGUAGE_AVAILABILITY}
                                    styles={optionColours} placeholder='Language Availability *'/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} style={{marginTop: 20}}>
                            <Select isMulti isClearable={false} name="order_type" 
                                onChange={(e) => handleChangeList(e, 'order_type')}  options={RESOURCE_ORDER_TYPE}
                                styles={optionColours} placeholder='Order Type *'/>
                        </Grid>
                        <Grid item xs={12} ms={12} md={12} lg={12}>
                            <Title value='Classification information' disableUnderline readOnly/> 
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} style={{marginTop: 20}}>
                            <Select isMulti isClearable={false} name="category" ref={refCo} 
                                    onChange={handleChangeListOptionsSco}  options={CATEGORY}
                                    styles={optionColours} placeholder='Category*'/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} style={{marginTop: 20}}>
                            <Select isMulti isClearable={false} name="subcategory" 
                                    onChange={(e) => handleChangeList(e, 'subcategory')} options={sco}
                                    styles={optionColours} placeholder='Subcategory*'/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} style={{marginTop: 20}}>
                            <Select isMulti isClearable={false} name="scientific_domain" ref={refSo} 
                                    onChange={handleChangeListOptionsSso}  options={SCIENTIFIC_DOMAIN}
                                    styles={optionColours} placeholder='Scientific Domain *'/>
                        </Grid> 
                        <Grid item xs={12} sm={12} md={6} lg={6} style={{marginTop: 20}}>
                            <Select isMulti isClearable={false} name="scientific_subdomain" 
                                    onChange={(e) => handleChangeList(e, 'scientific_subdomain')} options={sso}
                                    styles={optionColours} placeholder='Scientific Subdomain *'/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} style={{marginTop: 20}}>
                            <Select isMulti isClearable={false} name="target_users" 
                                    onChange={(e) => handleChangeList(e, 'target_users')} options={TARGET_USERS}
                                    styles={optionColours} placeholder='Target Users *'/>
                        </Grid>
                        <Grid item xs={12} ms={12} md={12} lg={12}>
                            <Title value='Maturity information' disableUnderline readOnly/> 
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} style={{marginTop: 20}}>
                            <Select isClearable={false} name="technology_readiness_level" 
                                    onChange={(e) => handleChangeOne(e, 'technology_readiness_level')} options={TRL}
                                    styles={optionColours} placeholder='Technology readiness level *'/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} style={{marginTop: 20}}>
                            <Select isClearable={false} name="lifecycle_status" 
                                    onChange={(e) => handleChangeOne(e, 'lifecycle_status')} options={LIFE_CYCLE_STATUS}
                                    styles={optionColours} placeholder='Lifecycle status'/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} style={{marginTop: 20}}>
                            <Select isMulti isClearable={false} name="resource_geographical_location" 
                                        onChange={(e) => handleChangeList(e, 'resource_geographical_location')} options={GEOGRAPHIC_LOCATION}
                                        styles={optionColours} placeholder='Resource Geographic Location'/>
                        </Grid>
                        <Grid item xs={12} ms={12} md={12} lg={12}>
                            <Title value='Marketing information' disableUnderline readOnly/> 
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} style={{marginTop: 20}}>
                            <CreatableSelect isMulti isClearable={false} name='multimedia' onChange={(e) => handleChangeList(e, 'multimedia')}
                                components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                placeholder='Multimedia' styles={optionColours}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} style={{marginTop: 20}}>
                            <CreatableSelect isMulti isClearable={false} name='use_cases' onChange={(e) => handleChangeList(e, 'use_cases')}
                                components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }} placeholder='Use Cases'
                                styles={optionColours}/>      
                        </Grid>
                        <Button type="submit" variant='outlined' style={{width: 200, height: 40, marginLeft: 'auto', marginRight: 'auto', marginTop: 40}}>
                            Add
                        </Button>
                    </Grid>
                </form>   
                </div>
            </Grid>       
            <Footer/>
        </Grid>  
    )
}