import { React } from 'react'
import { Grid, Paper, Link, List } from '@material-ui/core'  
import { titleSmall} from './styles.js'
import GetRenders, {URLS} from './util.js'
import './App.css'
import Footer from './Footer'
import { ImageCarousel } from './ImageCarousel'
import WhatWeOffer from './WhatWeOffer'
import Domains from './Domains'

export default function LandingPage() {
    const md = GetRenders().DOWN_MD
    const lg = GetRenders().LG
    const tablet = GetRenders().DOWN_SM
    const phone = GetRenders().XS

    const partners = [
        'https://www.dissco.eu/wp-content/uploads/dissco-logo-web.svg',
        'https://www.arl-net.de/sites/all/themes/arl/assets/images/logo.svg',
        'https://www.necen.nl/assets/c4928474/img/NeCEN-logo.png',
        'https://cei.iscte-iul.pt/wp-content/uploads/2013/12/CEI-IUL.png',
        'https://www.psi.ch/themes/custom/design/psi.svg',
        'https://www.etw.de/uploads/tpl-buddy-011/img/logo.svg',
        'https://ecrin.org/sites/default/files/logo.jpg',
        'https://www.obs-nancay.fr/wp-content/uploads/2020/05/logo_station5_a.png',
        'https://www7.obs-mip.fr/wp-content-aeris/uploads/sites/66/2020/06/Logo_Color.png',
        'https://www.crg.eu/sites/default/files/logo_1.png',
        'https://www.infrafrontier.eu/sites/infrafrontier.eu/themes/custom/infrafrontier/img/logo-infrafrontier.png',
        'https://www.bbmri-eric.eu/wp-content/themes/bbmri-eric/img/BBMRI-ERIC-gateway-for-health.svg',
        'https://www.arronax-nantes.fr/wp-content/uploads/2018/10/logo-for-sticky-152x74.png',
        'https://www.agendo.science/assets/images/official-logo-19-1014x335.png',
        'https://www.ceric-eric.eu/wp-content/themes/ceric/dist/images/ceric-logo.svg',
        'https://www.ustem.tuwien.ac.at/fileadmin/USTEM/images/logo.jpg'
    ]

    const marginTopWhatWeOffer = () => {
        if (lg) { 
            return '12vh'
        } else if (phone) {
            return '-13vh'
        } else if (tablet) {
            return '-5vh'
        } else {
            return '0vh'
        }
    }

    const marginLeftWhatWeOffer = () => {
        if (lg) { 
            return 100
        } else if (phone) {
            return 0
        } else if (tablet) {
            return 70
        } else {
            return -10
        }
    }

    return (
        <Grid container style={{height: '100vh'}}> 
            <Grid container style={{paddingTop: 10, height: 50, width: '100vw', backgroundColor: '#172E65', borderRadius: 0}} component={Paper} elevation={6}>
                <Grid item xs={6} sm={9} md={10} lg={10}></Grid>
                <Grid item xs={6} sm={3} md={2} lg={2}>
                    <Link style={{color: 'white'}} href={URLS.SIGNUP}> Sign Up</Link> 
                    <Link style={{color: 'white'}} disableUnderline> | </Link>
                    <Link style={{color: 'white'}} href={URLS.SIGNIN}> Sign In</Link>
                </Grid>
            </Grid>
            <Grid container style={{marginBottom: 100}}>
                <div style={{height: '50%'}}>
                    <img src='/banner.jpg' style={{position: 'absolute', width: '100%',}}/>
                </div>
                <Grid item xs={12} sm={5} md={4} lg={4} style={{marginLeft: phone ? -20 : null}}> 
                    <img src='/logo.png' style={{width: '24vw', minWidth: 200, marginBottom: 10, paddingTop: lg ? '12vh' : '5vh', marginTop: phone ? -30 : null}}/>
                    <br/>
                    <span style={{color: 'white', marginLeft: 10, fontSize: tablet ? '0.9rem' : '1rem'}}> make science yours</span>
                    {phone ? null : 
                        <> 
                            <br/><br/> 
                            <span style={{ marginLeft: 10, color: 'white', fontSize: tablet ? '1rem' : '1.3rem', position: 'absolute'}}> The new way scientific facilities are offered. </span>
                        </>
                    }
                </Grid>
                {phone ? null : 
                    <Grid item xs={4} sm={12} md={3} lg={4} style={{marginLeft: tablet ? 200: '1vw', paddingTop: md ? '13vh' : '21vh', marginTop: tablet ? '-20vh' : 0}}>
                        <button className="bigButton"  onClick={() => window.location.href = URLS.SIGNUP}> Register </button>
                    </Grid>
                }
            </Grid>
            <Grid container style={{marginTop: marginTopWhatWeOffer(), marginLeft: marginLeftWhatWeOffer()}}>
                <WhatWeOffer />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} style={{backgroundColor: '#E8E8E8', paddingTop: 10, paddingBottom: md ? 40 : 0}}>
                <Grid container item style={{whiteSpace: 'nowrap'}}>
                    <Grid item xs={8} sm={5} md={3} lg={2} style={{display: 'inline-block', verticalAlign: 'middle', paddingTop: 10}}>
                        <span style={{  fontSize: '2rem', color: '#0A50C7'}}> Join our  </span>
                        <br/>
                        <span style={{  fontSize: '2rem', color: '#0A50C7'}}> marketplace  </span>
                        <br/>
                        <span style={{  fontSize: '2rem', color: '#0A50C7'}}> to discover </span>
                    </Grid>
                    <Grid item xs={2} sm={5} md={1} lg={3} style={{display: 'inline-block', verticalAlign: 'middle'}}>
                        <img src='/arrow.png' style={{width: '8vw', height: '80%', paddingRight: 20}}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3} style={{ display: 'inline-block', verticalAlign: 'middle', paddingTop: 10}}>
                        <img src='/prov.svg' style={{width: '3vw', marginBottom: 10}}/>
                        <br/>
                        <span style={{fontSize: '1.2rem', color: '#0492C1'}}> 100 Providers</span>
                        <br/>
                        <span style={{fontSize: '0.9rem', }}> Providers are organisations </span>
                        <br/>
                        <span style={{fontSize: '0.9rem', }}> that manage and deliver </span>
                        <br/>
                        <span style={{fontSize: '0.9rem', }}> Resources to users. </span>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3} style={{ display: 'inline-block', verticalAlign: 'middle', paddingTop: 10}}>
                        <img src='/res.svg' style={{width: '2.5vw', marginBottom: 10}}/>
                        <br/>
                        <span style={{fontSize: '1.2rem', color: '#0492C1',}}> 600 Resources</span>
                        <br/>
                        <span style={{fontSize: '0.9rem', }}> Resources are services, </span>
                        <br/>
                        <span style={{fontSize: '0.9rem', }}> data sources, research products </span>
                        <br/>
                        <span style={{fontSize: '0.9rem', }}> available for users. </span>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} ms={12} md={12} lg={12} style={{backgroundColor: 'white', marginRight: 'auto', width: '94vw', textAlign: 'center', paddingTop: 70, marginTop: -10, paddingBottom: 70}}>
                <span style={{  fontSize: '1.7rem', color: '#0A50C7'}}> Wizbee trusted partners  </span>
                <div >
                    <ImageCarousel images={partners.map((par) => par)}/>
                </div>
            </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8} style={{marginTop: 50, paddingBottom: 50, marginLeft: tablet ? '7vw' : 0}}> 
                    <div style={{borderBottom: '1px solid', borderColor: '#0A50C7', width: '73.2%',marginTop: 10, paddingBottom: 5}}>
                        <span style={titleSmall}> Browse </span> 
                    </div>
                    <Domains />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3} style={{marginTop: tablet ? 0 : 40, backgroundColor: 'white', borderRadius: '10px', marginRight: 20, marginLeft: tablet ? 30 : 0}}> 
                    <div style={{borderBottom: '1px solid', borderColor: '#0A50C7', width: '90%', marginTop: 20, marginBottom: 10, paddingBottom: 5}}>
                        <span style={titleSmall}> News </span> 
                    </div>
                    <div style={{width: '90%'}}>
                        <List style={{maxHeight: 400, overflow: 'auto',}}>
                            <div style={{borderBottom: '1px solid', borderColor: '#E8E8E8', paddingBottom: 5, marginBottom: 20, marginTop: 20, marginLeft: 20, marginRight: 20}}>
                                <span style={{fontSize: '0.775rem'}}> 12.07.21 </span>
                                <b style={{float: 'right', fontSize: '1rem'}}> New resources </b>
                                <img src='/feed.png' style={{width: '100%', marginTop: 10, marginBottom: 10}}/>
                                <span> New resources added! </span>
                            </div>
                            <div style={{borderBottom: '1px solid', borderColor: '#E8E8E8', paddingBottom: 5, marginBottom: 20, marginTop: 20, marginLeft: 20, marginRight: 20}}>
                                <span style={{fontSize: '0.775rem'}}> 10.07.21 </span>
                                <b style={{float: 'right', fontSize: '1rem'}}> New providers </b>
                                <img src='/partners.png' style={{width: '100%', marginTop: 10, marginBottom: 10}}/>
                                <span> New providers are added in wizbee. Enjoy their resources! </span>
                            </div>
                            <div style={{paddingBottom: 5, marginBottom: 20, marginTop: 20, marginLeft: 20, marginRight: 20}}>
                                <span style={{fontSize: '0.775rem'}}> 09.07.21 </span>
                                <b style={{float: 'right', fontSize: '1rem'}}> Wizbee launched </b>
                                <img src='/launch.png' style={{width: '100%', marginTop: 10, marginBottom: 10}}/>
                                <span> Wizbee launched and welcomes new users! </span>
                            </div>
                        </List>
                    </div>
                </Grid>
            <Footer />
        </Grid>

    )
}