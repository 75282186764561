import { Typography } from '@material-ui/core'


export default function Copyright({footer}) {
    return (
      <Typography color="textSecondary" align="center" style={{marginRight: '2vw', marginTop: footer ? 20: 30, color: footer ? 'white' : null, marginLeft: '-2vw'}}>
        {'Copyright © '}
        {new Date().getFullYear()} {' '}
        {'Wizbee'}
        {'.'}
      </Typography>
    );
  }