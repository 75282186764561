import { React } from 'react'
import { Grid } from '@material-ui/core'
import {titleSmallBlue} from './styles'


export default function ResourceInfo(props) {

    return (
        <Grid container style={{borderBottom: '1px solid', borderColor: '#E8E8E8', marginBottom: 10, marginLeft: -35, width: '99.4%', paddingBottom: 10, float: 'left',}}>
            <Grid item xs={4} sm={4} md={4} lg={3}>
                <span style={titleSmallBlue}> {props.title} </span>
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={8}>
                {props.dict.map((item, i) =>{
                    if(i === props.dict.length-1) {
                        return (
                            <span key={i}>{item} </span>
                        )
                    } else {
                        return ( 
                            <span key={i}>{item}, </span>
                    )}
                })} 
            </Grid>
        </Grid> 
    )
}
        