import { Grid, Badge, Divider } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    badge: {
            height: 30,
            width: 200
    },
})

export const Versions = (props) => {
    const classes = useStyles()

    return (
        <Grid item style={{marginTop: 15, marginLeft: 85}}>
            {props.updates.map((item, i) => 
                <div key={i} onClick={() => props.provider ? window.location.href='/provider/version/' + item['uuid'] + '/' + item['update'] + '/' + props.edit
                                                            : window.location.href='/resources/version/' + item['uuid'] + '/' + item['update'] + '/' + props.edit}>
                        <Badge badgeContent={item['update']} color='primary' classes={{badge: classes.badge}}/>
                        { i !== props.updates.length-1 ? 
                                <Divider orientation='vertical' style={{height: 50, marginTop: 0}}/> 
                        : null}
                </div>
                )}
        </Grid> 
    )
}