import { React, useCallback } from 'react'
import { Avatar } from '@material-ui/core' 
import axios from 'axios'   
import {URLS} from './util'
import Dropzone from "./Dropzone";
import ReactNotification from 'react-notifications-component'
import { notify } from './notifications.js'



export default function CustomAvatar(props) {
    const maxLength = 200000;

    const onDrop = useCallback(files => {
        const file = files[0]
        if (files.length === 1 && file.size <= maxLength ) {
            const reader = new FileReader();
            reader.readAsDataURL(file)
            reader.onload = async function () {
                try {
                    const r = await axios.post(URLS.SERVER + '/convert/toUrl/', {'content': reader.result}, 
                                {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                    props.setLogo(r.data)
                } catch (e) {
                    try {            
                        notify(e.response.data, 'warning')
                    } catch (e) {
                        notify('There is no connection to server. Please try again later.', 'danger')
                    }
                }
            };
            
        } else if (files.length > 1) {
            notify('You can upload only one picture.', 'warning')
        } else {
            console.log('in')
            notify('You can upload a picture up to ' + maxLength / 1000 + ' KBytes.', 'warning')
        }

    })

    return (
        <div>
            {!props.hide ? 
                <Avatar style={{width: 100, height: 100, marginLeft: 30}} src={props.logo}>
                    {props.profile ? null :
                    <img src='/placeholder.jpg' style={{width: 130}}/>}
                </Avatar>
            :
                null
            }
            <Dropzone onDrop={onDrop} accept={"image/*"} hide={props.hide} logo={props.logo}/>
            <ReactNotification />

        </div>

    )

}