import { React } from 'react'
import Modal from 'react-modal';
import {TiDelete} from 'react-icons/ti'
import {Button, List, Checkbox, FormControlLabel, Accordion, AccordionSummary, AccordionDetails} from '@material-ui/core'    

import FilteringExpandable from './FilteringExpandable'
import Filtering from './Filtering'
import {SCIENTIFIC_DOMAIN, SCIENTIFIC_SUBDOMAIN, CATEGORY, SUBCATEGORY, 
        ACCESS_TYPE, ACCESS_MODE, GEOGRAPHICAL_AVAILABILITY }  from './constants.js'
import { modalFilters } from './styles.js'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


export default function(props) {    

    return (
        <Modal isOpen={props.open} style={modalFilters}>
            <div style={{marginTop: -5, marginRight: -20}}>
                <Button variant='none' startIcon={<TiDelete size={25} style={{color: '#0A50C7'}} onClick={()  => props.setOpen(false)}/>} 
                        onClick={()  => props.setOpen(false)} style={{float: 'right', width: 30}}/>
                <br/>
            </div>
            <div style={{textAlign: 'center'}}>
                <span style={{fontSize: '1.5rem', color: 'black', fontWeight: 'bold', marginBottom: 10}}> Filters </span>
            </div>
            <List style={{height: '70vh', overflow: 'auto', width: '100%',}} >
                <label style={{fontSize: '1.2rem', color: 'black', fontWeight: 'bold', marginLeft: 20}}> Type </label>
                <br/>
                <FormControlLabel control={
                    <Checkbox name='provider' onChange={(e) => {props.addFilterParameter(e, 'type'); props.filter()}}
                        checked={props.type['provider'] && props.type['provider'] !== 'undefined' ?  true : false}
                        disabled={props.type['resource']} /> } style={{marginLeft: 20}}
                    label='Providers' labelPlacement="end"/>
                <FormControlLabel control={
                    <Checkbox name='resource' onChange={(e) => {props.addFilterParameter(e, 'type'); props.filter()}}
                        checked={props.type['resource'] && props.type['resource'] !== 'undefined' ?  true : false}
                        disabled={props.type['provider']} /> } 
                    label='Resources' labelPlacement="end"/>
                <br/> <br/>
                <label style={{fontSize: '1.2rem', color: 'black', fontWeight: 'bold', marginLeft: 20}}> Access mode </label>
                <Filtering filter={props.filter} dict={ACCESS_MODE} type='am' checked_dict={props.am}
                    addFilterParameter={props.addFilterParameter} disabled={props.disabled? props.disabled : false}/>
                <br/>
                <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <label style={{fontSize: '1.2rem', color: 'black', fontWeight: 'bold'}}> Scientific Domain </label>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FilteringExpandable expand={props.expandSd} setExpand={props.setExpandSd} filter={props.filter} 
                            dict={SCIENTIFIC_DOMAIN} subdict={SCIENTIFIC_SUBDOMAIN} addFilterParameter={props.addFilterParameter}
                            type='sd' removeParameters={props.removeParameters} checked_dict={props.sd} checked_subdict={props.ssd}/>
                    </AccordionDetails>
                </Accordion>
                <br/>
                <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <label style={{fontSize: '1.2rem', color: 'black', fontWeight: 'bold'}}> Category </label>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FilteringExpandable name='category' expand={props.expandC} setExpand={props.setExpandC} filter={props.filter} 
                            dict={CATEGORY} subdict={SUBCATEGORY} type='c' addFilterParameter={props.addFilterParameter}
                            disabled={props.disabled? props.disabled : false} checked_dict={props.c} checked_subdict={props.sc}
                            removeParameters={props.removeParameters} />
                    </AccordionDetails>
                </Accordion>
                <br/>
                <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <label style={{fontSize: '1.2rem', color: 'black', fontWeight: 'bold'}}> Access type </label>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Filtering filter={props.filter} dict={ACCESS_TYPE} type='at' checked_dict={props.at}
                            addFilterParameter={props.addFilterParameter} disabled={props.disabled? props.disabled : false} />
                    </AccordionDetails>
                </Accordion>
                <br/>
                <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <label style={{fontSize: '1.2rem', color: 'black', fontWeight: 'bold'}}> Geographical availability </label>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Filtering filter={props.filter} dict={GEOGRAPHICAL_AVAILABILITY} type='ga' checked_dict={props.ga}
                            addFilterParameter={props.addFilterParameter} disabled={props.disabled? props.disabled : false}/>
                    </AccordionDetails>
                </Accordion>
            </List>
            <div style={{marginTop: 20, float: 'right'}}>
                <Button class='addFilters' onClick={() => props.setOpen(false)}> Add Filters</Button>
            </div>
        </Modal>
    )
}