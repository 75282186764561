import { React, useEffect, useState } from 'react'
import axios from 'axios'
import { titleSmall, blackNormalText, smallSubtitle } from './styles.js'
import { Button, Avatar, Grid, Tooltip, Zoom } from '@material-ui/core'
import {URLS} from './util.js'
import {notify} from './notifications'


export default function MyRecommendations(props) {
    const [recommendations, setRecommendations] = useState([])


    useEffect(() => {
        async function fetch() {
            try {
                const r4 = await axios.get(URLS.SERVER + '/favourites/recommendations?providers=False' ,
                        {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                setRecommendations(r4.data)
            } catch (e) {
                try {            
                    notify(e.response.data, 'warning')
                } catch (e) {
                    notify('There is no connection to server. Please try again later.', 'danger')
                }
            }
        }
        fetch()
    }, [])

    
    return (
        <>
        {recommendations.length !== 0 && recommendations[0] !== -1 ? 
            <div style={{marginTop: 20, marginBottom: 20, width: '100%', backgroundColor: 'white', borderRadius: '10px', padding: 15}}>
                <div style={{borderBottom: '1px solid', borderColor: '#0A50C7', width: '100%', marginBottom: 10, paddingBottom: 5}}>
                    <span style={titleSmall}> Recommended for me </span> 
                </div>
                {recommendations.length !== 0 ? 
                <>
                    {recommendations.map((rec, i) => 
                        <Grid item style={{marginTop: 5}} key={i}>
                            <Button variant='filled' href={URLS.RESOURCES + '/resource/' + rec.uuid} style={{marginLeft: -40}}>
                                <img style={{marginRight: 10, width: 55, }} src={rec.logo}/>
                                <div>
                                    <span style={blackNormalText}> {rec.name} </span>
                                    <br/>
                                    <span style={smallSubtitle}> {rec.tagline} </span>
                                </div>
                            </Button>
                            <br/>
                        </Grid>
                    )}
                </> : 
                <Button variant='contained' style={{borderRadius: '0 20px 20px 20px', height: 30}} 
                        onClick={() => window.location.href = "/user/" + localStorage.getItem('name') + '/one'}>
                    Add your interests!
                </Button> 
                }
            </div>
        : null}
        </>
    )
}
        