import {useState, useEffect, useRef} from 'react'
import { TextField, Button, Grid, Tooltip, Zoom } from '@material-ui/core'
import axios from 'axios'
import { useParams } from "react-router-dom";
import Select from 'react-select'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {divWithBorder, titleSmall, optionColours, textfield95, titleSmallBlue, tab} from './styles.js'
import {AiFillSetting} from 'react-icons/ai'
import Modal from 'react-modal';
import GetRenders, {URLS, convertToOptions} from './util'
import { notify } from './notifications.js'
import Header from './Header'
import CustomAvatar from "./CustomAvatar";
import { StaticMultipleSelect } from './StaticMultipleSelect.js';
import Footer from './Footer'
import {TiDelete} from 'react-icons/ti'
import {SCIENTIFIC_DOMAIN, SCIENTIFIC_SUBDOMAIN, CATEGORY, SUBCATEGORY } from './constants'
import './App.css'
import MyProviders from './MyProviders.js';
import MyFavourites from './MyFavourites.js';
import MyProfile from './MyProfile.js';


export default function Profile() {
    const params = useParams()
    const [ user, setUser] = useState({})
    const [value, setValue] = useState(params['value'])
    const [picture, setPicture] = useState('')  
    const [editInterests, setEditInterests] = useState(false)
    const [rerender, setRerender] = useState(0)  
    const refSo = useRef(null)
    const refCo = useRef(null)
    const [sso, setSso] = useState([])
    const [sco, setSco] = useState([])
    const [open, setOpen] = useState(false)
    const mobile = GetRenders().DOWN_SM


    const handleChangeListOptionsInitialS = (value) => { 
        var scientific_subdomain_options = []
        value.map((item, i) => {
            scientific_subdomain_options = [...scientific_subdomain_options, {label: item, options:  SCIENTIFIC_SUBDOMAIN[item]}]
        })
        setSso(scientific_subdomain_options)
    }

    const handleChangeListOptionsInitialC = (value) => { 
        var subcategory_options = []
        value.map((item, i) => {
            subcategory_options = [...subcategory_options, {label: item.value, options:  SUBCATEGORY[item.value]}]
        })
        setSco(subcategory_options)
    }

    const handleChangeListOptionsSso = (value) => { 
        var lista = []
        var scientific_subdomain_options = []
        value.map((item, i) => {
            lista = [...lista, item.value] 
            scientific_subdomain_options = [...scientific_subdomain_options, {label: item.value, options:  SCIENTIFIC_SUBDOMAIN[item.value]}]
        })
        setSso(scientific_subdomain_options)
        user[refSo.current.props.name] = lista
        setRerender(rerender+1)

    }

    const handleChangeListOptionsSco = (value) => { 
        var lista = []
        var subcategory_options = []
        value.map((item, i) => {
            lista = [...lista, item.value] 
            subcategory_options = [...subcategory_options, {label: item.value, options:  SUBCATEGORY[item.value]}]
        })
        setSco(subcategory_options)
        user[refCo.current.props.name] = lista
        setRerender(rerender+1)

    }

    const handleChangeList = (value, name) => {
        var lista = []
        value.map((item, i) => {
            lista = [...lista, item.value] 
        })
        user[name] = lista
        setRerender(rerender+1)
    }
    

    useEffect( async () => {
        try {
            const r = await axios.get(URLS.SERVER + '/users/user/' ,
                                {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
            setUser(r.data)
            setPicture(r.data['picture'])
            if (r.data['caterogy']) {
                handleChangeListOptionsInitialC(r.data['category'])
            }
            if (r.data['scientific_domain'] ) {
                handleChangeListOptionsInitialS(r.data['scientific_domain'])
            }
        } catch (e) {
            console.log(e)
            try {            
                notify(e.response.data, 'warning')
            } catch (e) {
                notify('There is no connection to server. Please try again later.', 'danger')
            }
        }
    }, [])


    const saveChanges = async () => {
        try {
            user['picture'] = picture
            await axios.post(URLS.SERVER + '/users/update_user/',  {...user},
                        {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
            localStorage.setItem('name', user['first_name'] + ' ' + user['last_name']);
            localStorage.setItem('picture', picture);
            localStorage.setItem('position', user['position']);
            setRerender(rerender+1)
            setOpen(false)
        } catch (e) {
            try {            
                notify(e.response.data, 'warning')
            } catch (e) {
                notify('There is no connection to server. Please try again later.', 'danger')
            }
        }
    }

    const save = async () => {
        try {
            user['picture'] = picture
            await axios.post(URLS.SERVER + '/users/update_user/',  {...user},
                        {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
            localStorage.setItem('name', user['first_name'] + ' ' + user['last_name']);
            localStorage.setItem('picture', picture);
            setRerender(rerender+1)
            setEditInterests(!editInterests)
        } catch (e) {
            try {            
                notify(e.response.data, 'warning')
            } catch (e) {
                notify('There is no connection to server. Please try again later.', 'danger')
            }
        }
    }
    

    useEffect(() => {

    }, [localStorage.getItem('token'), rerender])


    return (
        <Grid container style={{height: '120vh', opacity: open ? 0.2 : null}}>
            <Header/>
            <Modal isOpen={open} onRequestClose={() => setOpen(false)} 
                style={{overlay: {bottom: '5%', top: '10%', right: '35%', left: '30%', backgroundColor: 'transparent', minWidth: 350, 
                                color: '#0A50C7', fontSize: '1em', }, content: {background: 'white', opacity: 1, position: 'absolute'}}}>
                <Grid container style={{marginTop: -10}}>
                    <Grid item xs={12} ms={12} md={12} lg={12} >
                        <TiDelete style={{float: 'right', width: 30, height: 30}} onClick={() => setOpen(false)}/>
                    </Grid>
                    <Grid item xs={12} ms={12} md={12} lg={12} >
                        <CustomAvatar logo={picture} setLogo={setPicture} profile={true}/>
                    </Grid>
                    <Grid item xs={12} ms={12} md={12} lg={12} >
                        <TextField variant="outlined" margin="normal" 
                                    label="First Name" name="first_name" placeholder={user['first_name']}
                                    style={textfield95} onChange={(e) => user[e.target.name] = e.target.value}
                                    InputLabelProps={{ shrink: true,}}/>
                    </Grid>
                    <Grid item xs={12} ms={12} md={12} lg={12} >
                        <TextField variant="outlined" margin="normal" style={textfield95}
                            label="Last Name" name="last_name" placeholder={user['last_name']}
                            onChange={(e) => user[e.target.name] = e.target.value}
                            InputLabelProps={{ shrink: true,}}/>
                    </Grid>
                    <Grid item xs={12} ms={12} md={12} lg={12} >
                        <TextField variant="outlined" margin="normal" placeholder={user['phone']} style={textfield95}
                            label="Phone" name="phone" InputLabelProps={{ shrink: true,}}
                            onChange={e => user[e.target.name] = e.target.value} />
                    </Grid>
                    <Grid item xs={12} ms={12} md={12} lg={12} >
                        <TextField variant="outlined" margin="normal" placeholder={user['position']} style={textfield95}
                            label="Position" name="position" InputLabelProps={{ shrink: true,}}
                            onChange={e => user[e.target.name] = e.target.value} />
                    </Grid>
                    <Grid item xs={12} ms={12} md={12} lg={11} >
                        <Button variant='none' onClick={() => window.location.href='../../..' +URLS.FORGOT} style={{marginTop: 30, width: 200, height: 40, marginLeft: -30  }}> Reset password </Button>
                        <Button variant='outlined' onClick={saveChanges} style={{marginTop: 30, width: 200, height: 40, float: 'right' }}> Save & Exit </Button>
                    </Grid>
                </Grid>
            </Modal>
            <Grid item xs={11} sm={11} md={6} lg={5} >
                <MyProfile profile={true} setOpen={setOpen} />
                <Grid container style={{  marginTop: 20, backgroundColor: 'white', borderRadius: '10px', paddingBottom: 20}}>
                    <div style={divWithBorder}>
                        <span style={titleSmall}> My interests </span> 
                        <Tooltip title='Edit interests' TransitionComponent={Zoom} placement='bottom' >
                            <Button variant='none' endIcon={ <AiFillSetting size={25} style={{color: 'gray', marginTop: -5}} /> } 
                                onClick={() =>  setEditInterests(!editInterests)} style={{float: 'right'}}/>         
                        </Tooltip>
                    </div>
                    {editInterests ? 
                        <Grid container style={{marginLeft: -20, marginBottom: 8}}>
                                <Grid item xs={12} sm={12} md={6} lg={6} >
                                    <span style={titleSmallBlue}>Category</span>
                                    <br/> <br/>
                                    <Select isMulti name="category" ref={refCo} 
                                        onChange={handleChangeListOptionsSco}  options={CATEGORY}
                                        styles={optionColours} placeholder='Category*'
                                        value={convertToOptions(user['category'])}/>
                                    <br/>
                                    <span style={titleSmallBlue}>Subcategory</span>
                                    <br/> <br/> 
                                    <Select isMulti name="subcategory" 
                                        onChange={(e) => handleChangeList(e, 'subcategory')} options={sco}
                                        styles={optionColours} placeholder='Subcategory*'
                                        value={convertToOptions(user['subcategory'])}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} >
                                    <span style={titleSmallBlue}>Scientific Domain</span>
                                    <br/> <br/>
                                    <Select isMulti name="scientific_domain" ref={refSo} 
                                        onChange={handleChangeListOptionsSso}  options={SCIENTIFIC_DOMAIN}
                                        styles={optionColours} placeholder='Scientific Domain *'
                                        value={convertToOptions(user['scientific_domain'])}/>
                                    <br/>
                                    <span style={titleSmallBlue}>Scientific subdomain</span>
                                    <br/> <br/>
                                    <Select isMulti name="scientific_subdomain" 
                                        onChange={(e) => handleChangeList(e, 'scientific_subdomain')} options={sso}
                                        styles={optionColours} placeholder='Scientific Subdomain *'
                                        value={convertToOptions(user['scientific_subdomain'])}/>
                                </Grid>
                                <Grid item xs={10} sm={10} md={11} lg={11}>
                                    <Button variant='outlined' onClick={save} style={{marginTop: 30, width: 200, height: 40, float: 'right'}}> Save </Button>
                                </Grid>
                            </Grid>
                    : 
                    <>
                        {user['caterogy'] || user['subcaterogy'] || user['scientific_domain'] || user['scientific_subdomain'] ?
                        <Grid container style={{marginLeft: -20}}>
                            {user['category'] !== null ? 
                                <Grid item xs={12} sm={12} md={6} lg={6} style={{fontSize: 10, paddingBottom: 10}}>
                                    {user['category'] ? 
                                        <StaticMultipleSelect value={user.category} title='Category' />
                                        : null}
                                    <br/>
                                    {user['subcategory'] ? 
                                        <StaticMultipleSelect value={user.subcategory} title='Subcategory' />
                                        : null}
                                </Grid>
                            : null}
                            <Grid item xs={12} sm={12} md={6} lg={6} style={{fontSize: 10, paddingBottom: 10}}>
                                {user['scientific_domain'] ? 
                                    <StaticMultipleSelect value={user.scientific_domain} title='Scientific domain' />                            
                                    : null}
                                <br/>
                                {user['scientific_subdomain'] ? 
                                    <StaticMultipleSelect value={user.scientific_subdomain} title='Scientific subdomain' />
                                : null}
                            </Grid>
                        </Grid> 
                        :
                        <Button variant='contained' style={{borderRadius: '0 20px 20px 20px', marginLeft: 10}} onClick={() => setEditInterests(true)}>
                            Add your interests!
                        </Button> }
                    </>
                    }
                <br/>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={7} >
                <Tabs value={value} onChange={(e, newValue) => setValue(newValue)} variant='scrollable'
                    style={{backgroundColor: 'white', borderLeft: `1px solid`,  borderColor: '#E8E8E8', 
                            maxWidth: mobile ? '92%' : '58%', borderRadius: 10 ,}} orientation='horizontal'  textColor="secondary" >
                    <Tab value="one" label="My providers" style={tab}/>
                    <Tab value="four" label="Favourites" style={{textTransform: 'none'}}/>
                </Tabs>
                <Grid item xs={11} sm={11} md={7} lg={7} style={{ backgroundColor: 'white', borderRadius: 10, padding: 15, minHeight: 720}}>
                    {value === 'one'? 
                        <MyProviders two={false} />
                    : null}
                    {value === 'four' ? 
                        <MyFavourites />
                    : null}
                </Grid>
            </Grid>
            <Footer/>
        </Grid>
    )
}