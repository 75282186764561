import { React, useEffect, useState } from 'react'
import { blackNormalText } from './styles.js'
import { Link } from '@material-ui/core'
import {URLS} from './util.js'


export const Description = ({value, profile, provider, uuid}) => {
    const [show, setShow] = useState(false) 

    useEffect(() => {}, [show])

    return (
        <div>
            {show ? 
            <>
                <span style={blackNormalText}> {value} </span> 
                <Link onClick={() => profile ? setShow(false) 
                    : null } 
                    style={{fontSize: '0.8rem', float: 'right', color: 'gray'}}> Show less</Link>
            </>
            : 
            value.length < (profile ? 200 : 50) ?
                        <span style={blackNormalText}> {value} </span> 
                    : 
                    <div style={{width: '100%'}}>
                        <span style={blackNormalText}> {value.slice(0, profile ? 200 : 100) + '...'} </span> 
                        <Link onClick={() => profile ? setShow(true) : 
                                                provider ? window.location.href=URLS.PROVIDER + uuid
                                                    : window.location.href=URLS.RESOURCES + '/resource/' + uuid } 
                            style={{fontSize: '0.8rem', float: 'right', color: 'gray', }}> Show more</Link>
                    </div>
            }
        </div>
    )
} 