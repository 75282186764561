import { React, useState, useRef, useEffect } from 'react'
import axios from 'axios'
import { useParams } from "react-router-dom";
import CreatableSelect from 'react-select/creatable'
import Select, {components} from 'react-select'
import validator from 'validator'
import {CgWebsite} from 'react-icons/cg'
import { TextField, Button, Grid, Icon } from '@material-ui/core'    
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Loader from "react-loader-spinner";

import {SCIENTIFIC_DOMAIN, SCIENTIFIC_SUBDOMAIN, CATEGORY, SUBCATEGORY, 
        TARGET_USERS, ACCESS_TYPE, ACCESS_MODE, LIFE_CYCLE_STATUS, 
        GEOGRAPHICAL_AVAILABILITY, LANGUAGE_AVAILABILITY, TRL, GEOGRAPHIC_LOCATION }  from './constants.js'
import {optionColours, textfield, controlStyles, Label, leftContainerResource, 
        rightContainer, tab, textfield90, titleSmall,} from './styles.js'
import {URLS, convertToOptions} from './util.js'
import { notify } from './notifications.js'
import Header from './Header.js';
import CustomAvatar from "./CustomAvatar";
import Footer from './Footer'
import OtherFilter from './OtherFilter'
import { EditableTextField } from './EditableTextField.js';
import {Versions} from './Versions'



export default function ResourceProfile() {
    const params = useParams()    

    const [sso, setSso] = useState([])
    const [sco, setSco] = useState([])
    const [other, setOther] = useState('')
    const [open, setOpen] = useState(false)
    const [proposal, setProposal] = useState('')
    const refSo = useRef(null)
    const refCo = useRef(null)
    const [resource, setResource] = useState({})
    const [updates, setUpdates] = useState()
    const [rerender, setRerender] = useState(0)
    const [value, setValue] = useState('one')
    const [logo, setLogo] = useState('')
    const [edit, setEdit] = useState(false)  
    const [showLoader, setShowLoader] = useState(false)  
    const [resourceContact, setResourceContact] = useState({}) 


    const handleChangeListOptionsInitialS = (value) => { 
        var scientific_subdomain_options = []
        value.map((item, i) => {
            scientific_subdomain_options = [...scientific_subdomain_options, {label: item, options:  SCIENTIFIC_SUBDOMAIN[item]}]
        })
        setSso(scientific_subdomain_options)
    }

    const handleChangeListOptionsInitialC = (value) => { 
        var subcategory_options = []
        value.map((item, i) => {
            subcategory_options = [...subcategory_options, {label: item.value, options:  SUBCATEGORY[item.value]}]
        })
        setSco(subcategory_options)
    }
    

    useEffect(() => {
        async function fetch() {
            try {
                const r = await axios.get(URLS.SERVER + '/resources/resource/?id=' + params['id'], 
                            {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                setResource(r.data)
                setLogo(r.data['logo'])
                handleChangeListOptionsInitialS(r.data['scientific_domain'])
                handleChangeListOptionsInitialC(r.data['category'])
                const r1 = await axios.get(URLS.SERVER + '/resources/edit_dates/?id=' + params['id'], 
                                {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                setUpdates(r1.data)
                const r2 = await axios.get(URLS.SERVER + '/resources_details/by/?id=' + params['id'], 
                                                {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                setResourceContact(r2.data['contact'])
                const r3 = await axios.get(URLS.SERVER + '/providers/edit/?id=' + r.data['provider']['uuid'], 
                                        {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                setEdit(r3.data)
                if (!r3.data) {
                    setShowLoader(true)
                    window.location.href = URLS.RESOURCES + '/resource/' + params['id']
                }
            } catch (e) {
                console.log(e)
                try {            
                    notify(e.response.data, 'warning')
                } catch (e) {
                    notify('There is no connection to server. Please try again later.', 'danger')
                }
            }
        }
        fetch()
    }, [])

    
    const handleChangeUrl = (event) => {
        if(event.target.value === "") {

        } else if (validator.isURL(event.target.value)) {
            if (event.target.value.startsWith('http')) {
                resource[event.target.name] = event.target.value
            } else {
                resource[event.target.name] = 'http://' + event.target.value
            }
        } else {
            notify('This is not a valid url.', 'warning')
        }
    }

    const handleChangeEmail = (event) => {
        if(event.target.value === "") {

        } else if (validator.isURL(event.target.value)) {
            resource[event.target.name] = event.target.value
        } else {
            notify('This is not a valid email.', 'warning')
        }
    }
    
    const handleChange = (event) => resource[event.target.name] = event.target.value
    const handleChangeList = (value, name) => {
        var lista = []
        value.map((item, i) => {
            if (item.value === 'Other') {
                setOther(name)
                setOpen(true)
            }
            lista = [...lista, item.value] 
        })
        resource[name] = lista
        setRerender(rerender+1)
    }

    const handleChangeOne = (event, name) => {
        if (event) { 
            resource[name] = event.value
        } else {
            resource[name] = null
        }
        setRerender(rerender+1)
    }




    const handleChangeListOptionsSso = (value) => { 
        var lista = []
        var scientific_subdomain_options = []
        value.map((item, i) => {
            if (item.value === 'Other') {
                setOther('scientific domain')
                setOpen(true)
            }
            lista = [...lista, item.value] 
            scientific_subdomain_options = [...scientific_subdomain_options, {label: item.value, options:  SCIENTIFIC_SUBDOMAIN[item.value]}]
        })
        setSso(scientific_subdomain_options)
        resource[refSo.current.props.name] = lista
        setRerender(rerender+1)

    }

    const handleChangeListOptionsSco = (value) => { 
        var lista = []
        var subcategory_options = []
        value.map((item, i) => {
            if (item.value === 'Other') {
                setOther('category')
                setOpen(true)
            }
            lista = [...lista, item.value] 
            subcategory_options = [...subcategory_options, {label: item.value, options:  SUBCATEGORY[item.value]}]
        })
        setSco(subcategory_options)
        resource[refCo.current.props.name] = lista
        setRerender(rerender+1)

    }

    const saveResource = async (event) => {
        event.preventDefault()
        try {
                resource['logo'] = logo
                const r = await axios.post(URLS.SERVER + '/resources/update_resource/?id=' + params['id'] , {...resource}, 
                                        {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                window.location.href = URLS.RESOURCES + '/resource/' + params['id']
            } catch (error) {
                try {            
                        notify(error.response.data, 'warning')
                } catch (e) {
                        console.log(error)
                        if(error.response.status === 403) {
                                notify('You are not allowed to do this action.', 'danger')
                        } else {
                                notify('There is no connection to server. Please try again later.', 'danger')
                        }
                }
        }
    }

    const updateContact = async (event) => {
        event.preventDefault()
        try {
                const r = await axios.post(URLS.SERVER + '/resources_details/update_contact/?id=' + params['id'] , {...resourceContact}, 
                                        {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                setRerender(rerender+1)
        } catch (error) { 
                try {            
                        notify(error.response.data, 'warning')
                } catch (e) {
                        console.log(error)
                        if(error.response.status === 403) {
                                notify('You are not allowed to do this action.', 'danger')
                        } else {
                                notify('There is no connection to server. Please try again later.', 'danger')
                        }
                }
        }
    }


    useEffect(() => {}, [rerender, logo])

    const ProviderIcon = (
        <Icon style={{color: '#E8E8E8', marginLeft: -15, marginBottom: 20, width: 25, height: 10}}>
          <img src="/prov.svg" />
        </Icon>
      );


    if (edit) { 
    return (
        <Grid container style={{height: '110vh', opacity: open ? 0.3 : 1}} >
            <Header/>
            <OtherFilter open={open} setOpen={setOpen} proposal={proposal} setProposal={setProposal} other={other}/>
            <Grid container style={{borderBottom: '1px solid', borderColor: '#E8E8E8', marginLeft: -5, marginBottom: 20}}>
                <CustomAvatar logo={logo} setLogo={setLogo}/>
                <Grid item xs={7} sm={8} md={4} lg={4} style={{marginBottom: 5, marginTop: -5}}>
                    <EditableTextField value={resource['name']} label="Name" handleChange={handleChange} name='name' 
                        style={textfield}/>
                    <br/> 
                    <EditableTextField value={resource['tagline']} label="Tagline" handleChange={handleChange} name='tagline' 
                        style={textfield}/>
                </Grid>
            </Grid>
            <Grid item xs={11} sm={11} md={4} lg={5} style={leftContainerResource}>
                <div style={{marginBottom: 10, paddingBottom: 10, borderBottom: '1px solid', borderColor: '#E8E8E8',}}>
                    <TextField variant="outlined" margin="normal"  label="Description" name="description" 
                        onChange={handleChange} placeholder={resource['description']}  InputProps={{style: {color: '#0492C1'}}}
                        style={{width: '80%', color: 'white',}} InputLabelProps={{ shrink: true,}} multiline={true} rows={3}/>  
                </div>
                <div style={{borderBottom: '1px solid', borderColor: '#E8E8E8', marginBottom: 10, paddingBottom: 10}}>
                    <Button variant='filled' startIcon={ProviderIcon} style={{textTransform: 'none', color: '#0492C1', fontSize: '16pt'}}
                            href={URLS.PROVIDER + resource.provider.uuid}>
                                {resource.provider.abbreviation} 
                    </Button>
                </div>
                <div style={{borderBottom: '1px solid', borderColor: '#E8E8E8', marginBottom: 10, paddingBottom: 10}}>
                        <Label value='Available ' disableUnderline readOnly/>
                        <Select isMulti  name="geographical_availability" 
                            onChange={(e) => handleChangeList(e, 'geographical_availability')} options={GEOGRAPHICAL_AVAILABILITY}
                            styles={optionColours} placeholder='Geographical Availability *'
                            value={convertToOptions(resource['geographical_availability'])}/> 
                        <Select isMulti name="access_type" 
                                onChange={(e) => handleChangeList(e, 'access_type')} options={ACCESS_TYPE}
                                styles={optionColours} placeholder='Access Type'
                                value={convertToOptions(resource['access_type'])} components={{ Control: props => <div style={controlStyles}>
                                {<p> Access type</p>}
                                <components.Control {...props} />
                                </div> }} />       
                        <Select isMulti name="access_mode" 
                                onChange={(e) => handleChangeList(e, 'access_mode')} options={ACCESS_MODE}
                                styles={optionColours} placeholder='Access Mode'
                                value={convertToOptions(resource['access_mode'])} components={{ Control: props => <div style={controlStyles}>
                                {<p> Access mode</p>}
                                <components.Control {...props} />
                                </div> }} />      
                </div>
                <div style={{borderBottom: '1px solid', borderColor: '#E8E8E8', marginBottom: 10, paddingBottom: 10}}>
                    <Label value='Language ' disableUnderline readOnly/> 
                    <Select isMulti name="language_availability" 
                        onChange={(e) => handleChangeList(e, 'language_availability')} options={LANGUAGE_AVAILABILITY}
                        styles={optionColours} placeholder='Language Availability *'
                        value={convertToOptions(resource['language_availability'])} />  
                </div> 
                <div style={{borderBottom: '1px solid', borderColor: '#E8E8E8', marginBottom: 10, paddingBottom: 10}}>
                    <Select isMulti name="target_users" 
                        onChange={(e) => handleChangeList(e, 'target_users')} options={TARGET_USERS}
                        styles={optionColours} placeholder='Target Users *'
                        value={convertToOptions(resource['target_users'])} components={{ Control: props => <div style={controlStyles}>
                        {<p> Target users</p>}
                        <components.Control {...props} />
                        </div> }} />       
                </div>           
                <div style={{borderBottom: '1px solid', borderColor: '#E8E8E8', marginBottom: 10, paddingBottom: 10, hidden: resource.required_resources.length != 0}}>
                    <Label value='Required resources' disableUnderline readOnly />                    
                    <CreatableSelect isMulti  name='required_resources' onChange={(e) => handleChangeList(e, 'required_resources')}
                        placeholder='Required Resources' value={convertToOptions(resource['required_resources'])} styles={optionColours}
                        components={{DropdownIndicator:() => null, IndicatorSeparator:() => null, }} />  
                </div> 
                <br/> 
                <Grid container style={{backgroundColor: 'white', padding: 15, borderRadius: 10}}>
                    <div style={{ borderBottom: '1px solid',  borderColor: '#0A50C7', marginLeft: 10, width: '96%', 
                                marginBottom: 10, marginLeft: 10}}>
                        <span style={titleSmall}> Contact information </span> 
                    </div>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{marginLeft: -20}}>
                        <TextField variant="outlined" margin="normal" placeholder={resource['website']} size='small'
                                        InputLabelProps={{ shrink: true,}} style={textfield} label="Website" name="website" 
                                            onBlur={handleChangeUrl} InputProps={{style: {color: '#0492C1'}}}/>   
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{marginLeft: -20}}>
                        <Select isMulti  name="resource_geographical_location" 
                                onChange={(e) => handleChangeList(e, 'resource_geographical_location')} options={GEOGRAPHIC_LOCATION}
                                styles={optionColours} placeholder='Resource Geographic Location' 
                                value={convertToOptions(resource['resource_geographical_location'])} 
                                components={{ Control: props => <div style={controlStyles}>
                                {<p> Resource geographic location</p>}
                                <components.Control {...props} />
                                </div> }} />  
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={7} >
                <Tabs value={value} onChange={(e, newValue) => setValue(newValue)} variant='scrollable'
                    style={{backgroundColor: 'white',  borderLeft: `1px solid`,  borderColor: '#E8E8E8', 
                            maxWidth: '92%', borderRadius: 10 }}
                        orientation='horizontal'  textColor="secondary" >
                    <Tab value="one" label="About" style={tab}/>
                    <Tab value="three" label="Maturity" style={tab} />
                    <Tab value="four" label="Management" style={tab} />
                    <Tab value="five" label="Contact" style={tab} />
                    <Tab value="six" label="Order" style={tab} />
                    <Tab value="seven" label="Versions" style={{textTransform: 'none'}}/>
                </Tabs>
                <Grid item xs={11} sm={11} md={11} lg={11} style={rightContainer}>
                    {value === 'one' ?
                        <Grid container style={{marginLeft: -15}}>
                            <Grid item xs={12} sm={4} md={5} lg={4}>
                                    <img src='/multimedia.jpg' style={{width: 200}} />
                            </Grid>
                            <Grid item xs={12} sm={7} md={6} lg={8}>                       
                                <CreatableSelect isMulti name='multimedia' onChange={(e) => handleChangeList(e, 'multimedia')}
                                    placeholder='Add video/photo urls' value={convertToOptions(resource['multimedia'])} styles={optionColours}
                                    components={{DropdownIndicator:() => null, IndicatorSeparator:() => null, Control: props => <div style={controlStyles}>
                                    {<p> Add video/photo urls</p>}
                                    <components.Control {...props} />
                                    </div> }} />      
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} > 
                                <Select isMulti name="scientific_domain" ref={refSo} 
                                    onChange={handleChangeListOptionsSso}  options={SCIENTIFIC_DOMAIN}
                                    styles={optionColours} placeholder='Scientific Domain *'
                                    value={convertToOptions(resource['scientific_domain'])} components={{Control: props => <div style={controlStyles}>
                                    {<p> Scientific domain</p>}
                                    <components.Control {...props} />
                                    </div> }} /> 
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} > 
                                <Select isMulti name="scientific_subdomain" 
                                    onChange={(e) => handleChangeList(e, 'scientific_subdomain')} options={sso}
                                    styles={optionColours} placeholder='Scientific Subdomain *'
                                    value={convertToOptions(resource['scientific_subdomain'])} components={{Control: props => <div style={controlStyles}>
                                    {<p> Scientific subdomain</p>}
                                    <components.Control {...props} />
                                    </div> }} /> 
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} > 
                                <Select isMulti name="category" ref={refCo} 
                                    onChange={handleChangeListOptionsSco}  options={CATEGORY}
                                    styles={optionColours} placeholder='Category*'
                                    value={convertToOptions(resource['category'])} components={{Control: props => <div style={controlStyles}>
                                    {<p> Category</p>}
                                    <components.Control {...props} />
                                    </div> }} /> 
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} > 
                                <Select isMulti name="subcategory" 
                                    onChange={(e) => handleChangeList(e, 'subcategory')} options={sco}
                                    styles={optionColours} placeholder='Subcategory*'
                                    value={convertToOptions(resource['subcategory'])} components={{Control: props => <div style={controlStyles}>
                                    {<p> Subcategory</p>}
                                    <components.Control {...props} />
                                    </div> }} /> 
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} > 
                                <CreatableSelect isMulti name='tags' onChange={(e) => handleChangeList(e, 'tags')}
                                    placeholder='Tags' styles={optionColours}
                                    value={convertToOptions(resource['tags'])} 
                                    components={{DropdownIndicator:() => null, IndicatorSeparator:() => null, Control: props => <div style={controlStyles}>
                                    {<p> Tags</p>}
                                    <components.Control {...props} />
                                    </div> }} />      
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} > 
                                <CreatableSelect isMulti name='use_cases' onChange={(e) => handleChangeList(e, 'use_cases')}
                                    placeholder='Use Cases' value={convertToOptions(resource['use_cases'])} styles={optionColours}
                                    components={{DropdownIndicator:() => null, IndicatorSeparator:() => null, Control: props => <div style={controlStyles}>
                                    {<p> Use cases</p>}
                                    <components.Control {...props} />
                                    </div> }} />                 
                            </Grid> 
                        </Grid>
                    : null}
                    {value === 'three' ? 
                        <Grid container style={{marginLeft: -15, marginTop: -15}}>
                            <Grid item xs={12} sm={12} md={12} lg={6} > 
                                <Select name="technology_readiness_level" isClearable={true}
                                    onChange={(e) => handleChangeOne(e, 'technology_readiness_level')} options={TRL}
                                    styles={optionColours} placeholder='Technology readiness level *'
                                    value={resource['technology_readiness_level'] ? {label: resource['technology_readiness_level'], value: resource['technology_readiness_level']} : null}
                                    components={{ Control: props => <div style={controlStyles}>
                                            {<p> Technology readiness level</p>}
                                            <components.Control {...props} />
                                            </div> }} />                                             
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6} > 
                                <Select  name="lifecycle_status" 
                                    onChange={(e) => handleChangeOne(e, 'lifecycle_status')} options={LIFE_CYCLE_STATUS}
                                    styles={optionColours} placeholder='Lifecycle status' 
                                    value={resource['lifecycle_status'] ? {label: resource['lifecycle_status'], value: resource['lifecycle_status']} : null}
                                    components={{ Control: props => <div style={controlStyles}>
                                    {<p> Lifecycle status </p>}
                                    <components.Control {...props} />
                                    </div> }} />      
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6} > 
                                    <CreatableSelect isMulti  name='certifications' onChange={(e) => handleChangeList(e, 'certifications')}
                                        placeholder='Certifications' value={convertToOptions(resource['certifications'])}  styles={optionColours}
                                        components={{DropdownIndicator:() => null, IndicatorSeparator:() => null, Control: props => <div style={controlStyles}>
                                        {<p> Certifications</p>}
                                        <components.Control {...props} />
                                        </div> }} />       
                                </Grid>
                    </Grid> : null}
                    {value === 'four' ? 
                        <Grid container style={{marginLeft: -15, marginTop: -10}}>
                            <Grid item xs={12} sm={12} md={6} lg={6} > 
                                <EditableTextField value={resource['helpdesk_email']} label="Helpdesk email" 
                                    handleChange={(e) => resource[e.target.name] = e.target.value} name='helpdesk_email' />
                            </Grid>                   
                            <Grid item xs={12} sm={12} md={6} lg={6} > 
                                <EditableTextField value={resource['helpdesk_page']} label="Helpdesk page" 
                                        handleChange={(e) => resource[e.target.name] = e.target.value} name='helpdesk_page' />                                 
                            </Grid>  
                            <Grid item xs={12} sm={12} md={6} lg={6} > 
                                <EditableTextField value={resource['user_manual']} label="User manual" 
                                            handleChange={(e) => resource[e.target.name] = e.target.value} name='user_manual' />    
                            </Grid>  
                            <Grid item xs={12} sm={12} md={6} lg={6} > 
                                <EditableTextField value={resource['terms_of_use']} label="Terms of use" 
                                            handleChange={(e) => resource[e.target.name] = e.target.value} name='terms_of_use' />   
                            </Grid>  
                            <Grid item xs={12} sm={12} md={6} lg={6} > 
                                <EditableTextField value={resource['privacy_policy']} label="Privacy policy" 
                                            handleChange={(e) => resource[e.target.name] = e.target.value} name='privacy_policy' />   
                            </Grid>  
                            <Grid item xs={12} sm={12} md={6} lg={6} > 
                                <EditableTextField value={resource['access_policy']} label="Access policy" 
                                            handleChange={(e) => resource[e.target.name] = e.target.value} name='access_policy' />
                            </Grid>  
                            <Grid item xs={12} sm={12} md={6} lg={6} > 
                                <EditableTextField value={resource['service_level']} label="Service level" 
                                            handleChange={(e) => resource[e.target.name] = e.target.value} name='service_level' />       
                            </Grid>  
                            <Grid item xs={12} sm={12} md={6} lg={6} > 
                                <EditableTextField value={resource['training_information']} label="Training information" 
                                            handleChange={(e) => resource[e.target.name] = e.target.value} name='training_information' />
                            </Grid>   
                            <Grid item xs={12} sm={12} md={6} lg={6} > 
                                <EditableTextField value={resource['status_monitoring']} label="Status monitoring" 
                                            handleChange={(e) => resource[e.target.name] = e.target.value} name='status_monitoring' />                                
                            </Grid>  
                        </Grid> : null}
                    {value === 'five' ? 
                        <Grid container style={{marginLeft: -15, marginTop: -12}}>
                                <Grid item xs={12} sm={12} md={6} lg={6} > 
                                    <TextField variant="outlined" margin="normal" required
                                                label="First name" name="first_name" InputLabelProps={{ shrink: true, style: {color: 'black'}}}                                 
                                                onChange={(e) => resourceContact[e.target.name] = e.target.value} size='small'
                                                style={textfield} placeholder={resourceContact ? resourceContact['first_name'] : 'First Name'} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} > 
                                        <TextField variant="outlined" margin="normal" required
                                                label="Last name" name="last_name" InputLabelProps={{ shrink: true, style: {color: 'black'}}}
                                                onChange={(e) => resourceContact[e.target.name] = e.target.value} size='small'
                                                style={textfield} placeholder={resourceContact ? resourceContact['last_name'] : 'Last Name'} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} > 
                                        <TextField variant="outlined" margin="normal" required
                                                label="Email" name="email" InputLabelProps={{ shrink: true, style: {color: 'black'}}}
                                                onChange={(e) => resourceContact[e.target.name] = e.target.value} size='small'
                                                style={textfield} placeholder={resourceContact ? resourceContact['email'] : 'Email'} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} > 
                                        <TextField variant="outlined" margin="normal" 
                                                label="Phone" name="phone" InputLabelProps={{ shrink: true, style: {color: 'black'}}}
                                                onChange={(e) => resourceContact[e.target.name] = e.target.value}  size='small'
                                                style={textfield} placeholder={resourceContact ? resourceContact['phone'] : 'Phone'} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} > 
                                        <TextField variant="outlined" margin="normal" 
                                                label="Position" name="position" InputLabelProps={{ shrink: true, style: {color: 'black'}}}
                                                onChange={(e) => resourceContact[e.target.name] = e.target.value} size='small'
                                                style={textfield} placeholder={resourceContact ? resourceContact['position'] : 'Position'} />
                                </Grid>
                                <Grid item xs={false} ms={6} md={6} lg={6}></Grid>
                        </Grid>  : null}
                    {value === 'six' ? 
                        <Grid container style={{marginLeft: -15, marginTop: -15}}>
                            <Grid item xs={12} sm={12} md={6} lg={6} > 
                                <TextField variant="outlined" margin="normal" size='small'
                                    label="Order" name="order" onChange={handleChange} placeholder={resource['order']} style={textfield}
                                    InputLabelProps={{ shrink: true, style: {color: 'black'}}}/>                                  
                            </Grid>  
                            <Grid item xs={12} sm={12} md={6} lg={6} > 
                                <TextField variant="outlined" margin="normal"  label="Payment model" name="payment_model" 
                                    onChange={handleChange} placeholder={resource['payment_model']} style={textfield} size='small'
                                    InputLabelProps={{ shrink: true, style: {color: 'black'}}}/> 
                            </Grid>  
                            <Grid item xs={12} sm={12} md={6} lg={6} > 
                                <TextField variant="outlined" margin="normal"  label="Pricing" name="pricing" size='small'
                                    onChange={handleChange} placeholder={resource['pricing']} style={textfield}
                                    InputLabelProps={{ shrink: true, style: {color: 'black'}}}/> 
                            </Grid>   
                        </Grid> : null}
                {value === 'seven' ? 
                    <Versions updates={updates} provider={false} edit={edit }/>           
                : null}
            </Grid>
            <Grid item xs={8} sm={11} md={11} lg={11}>
                        <Button variant='outlined' onClick={(e) => {saveResource(e); 
                                                        if (Object.keys(resourceContact).length !== 0 ) {updateContact(e)} }}
                                        style={{width: 200, height: 40, float: 'right'}}> 
                            Save & Exit 
                        </Button>
                        <button className="exitButton" onClick={(e) => window.location.href = URLS.RESOURCES + '/resource/' + params['id']} > 
                                Exit without saving
                        </button>
                </Grid>
            </Grid>
            <Footer/>
        </Grid>  
    )} else {
        return( 
            <Loader type="Oval" color="#0A50C7" height={100} width={100} visible={showLoader} style={{position: 'fixed', top: '50%', left: '50%'}}/>
        )}
}