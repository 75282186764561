export const SCIENTIFIC_DOMAIN = [{value: 'Natural Sciences', label: 'Natural Sciences'}, 
                                {value: 'Engineering & Technology', label: 'Engineering & Technology'},
                                {value: 'Medical & Health Sciences', label: 'Medical & Health Sciences'}, 
                                {value: 'Agricultural Sciences', label: 'Agricultural Sciences'},
                                {value: 'Social Sciences', label: 'Social Sciences'},
                                {value: 'Humanities', label: 'Humanities'},
                                {value: 'Generic', label: 'Generic'}, {value: 'Other', label: 'Other'}]

export const SCIENTIFIC_SUBDOMAIN = {
    'Natural Sciences':
     [
      { label: 'Mathematics', value: 'Mathematics' },
      { label: 'Computer and information sciences', value: 'Computer and information sciences' },
      { label: 'Physical sciences', value: 'Physical sciences' },
      { label: 'Chemical sciences', value: 'Chemical sciences' },
      { label: 'Earth and related environmental sciences', value: 'Earth and related environmental sciences' },
      { label: 'Biological sciences', value: 'Biological sciences' },
      { label: 'Other natural sciences', value: 'Other natural sciences' },

    ],
    'Engineering & Technology':
        [
          { label: 'Civil engineering', value: 'Civil engineering' },
          { label: 'Electrical, electronic and information engineering', value: 'Electrical, electronic and information engineering' },
          { label: 'Mechanical engineering', value: 'Mechanical engineering' },
          { label: 'Chemical engineering', value: 'Chemical engineering' },
          { label: 'Materials engineering', value: 'Materials engineering' },
          { label: 'Medical engineering', value: 'Medical engineering' },
          { label: 'Environmental engineering', value: 'Environmental engineering' },
          { label: 'Environmental biotechnology', value: 'Environmental biotechnology' },
          { label: 'Industrial biotechnology', value: 'Industrial biotechnology' },
          { label: 'Nano-technology', value: 'Nano-technology' },
          { label: 'Other engineering and technology sciences', value: 'Other engineering and technology sciences' },
        ],
    'Medical & Health Sciences':
        [
          { label: 'Basic Medicine', value: 'Basic Medicine' },
          { label: 'Clinical medicine', value: 'Clinical medicine' },
          { label: 'Health sciences', value: 'Health sciences' },
          { label: 'Medical biotechnology', value: 'Medical biotechnology' },
          { label: 'Other medical sciences', value: 'Other medical sciences' },
        ],
    'Agricultural Sciences':
        [
          { label: 'Agriculture, forestry, and fisheries', value: 'Agriculture, forestry, and fisheries' },
          { label: 'Animal and dairy sciences', value: 'Animal and dairy sciences' },
          { label: 'Veterinary sciences', value: 'Veterinary sciences' },
          { label: 'Agricultural biotechnology', value: 'Agricultural biotechnology' },
          { label: 'Other agricultural sciences', value: 'Other agricultural sciences' },
        ],
    'Social Sciences':
        [
          { label: 'Psychology', value: 'Psychology' },
          { label: 'Economics and business', value: 'Economics and business' },
          { label: 'Educational sciences', value: 'Educational sciences' },
          { label: 'Sociology', value: 'Sociology' },
          { label: 'Law', value: 'Law' },
          { label: 'Political sciences', value: 'Political sciences' },
          { label: 'Social and economic geography', value: 'Social and economic geography' },
          { label: 'Media and communication', value: 'Media and communication' },
          { label: 'Other social sciences', value: 'Other social sciences' },
        ],
    'Humanities':
        [
          { label: 'History and archaeology', value: 'History and archaeology' },
          { label: 'Languages and literature', value: 'Languages and literature' },
          { label: 'Philosophy, ethics and religion', value: 'Philosophy, ethics and religion' },
          { label: 'Arts', value: 'Arts' },
          { label: 'Other humanities', value: 'Other humanities' },
        ],
    'Generic':
        [
          { label: 'Generic', value: 'Generic' },
        ],
    'Other':
        [
          { label: 'Other', value: 'Other' },
        ]
  }

export const LEGAL_STATUS = [{value: 'Association', label: 'Association'},
                            {value: 'Consortium', label: 'Consortium'},
                            {value: 'Corporation', label: 'Corporation'},
                            {value: 'European Research Infrastructure Consortium (ERIC)', label: '4 European Research Infrastructure Consortium (ERIC)'},
                            {value: 'European Economic Interest Grouping', label: 'European Economic Interest Grouping'},
                            {value: 'Foundation', label: 'Foundation'},
                            {value: 'Grouping', label: 'Grouping'},
                            {value: 'International or Intergovernmental Organisation or Framework', label: 'International or Intergovernmental Organisation or Framework'},
                            {value: 'Non-Governmental Organisation', label: 'Non-Governmental Organisation'},
                            {value: 'Non For Profit Company', label: 'Non For Profit Company'},
                            {value: 'Partnership', label: 'Partnership'},
                            {value: 'Private Company', label: 'Private Company'},
                            {value: 'Public Company', label: 'Public Company'},
                            {value: 'Public Legal Entity', label: 'Public Legal Entity'},
                            {value: 'Public Legal Entity under Private Law', label: 'Public Legal Entity under Private Law'},
                            {value: 'Self Employed', label: 'Self Employed'},
                            {value: 'Society', label: 'Society'},
                            {value: 'Trust', label: 'Trust'},
                            {value: 'Union', label: 'Union'},
                            {value: 'Other', label: 'Other'}] 
                           
export const PROV_LIFE_CYCLE_STATUS = [{value: 'Under Construction', label: 'Under Construction'},
                                    {value: 'Operational', label: 'Operational'},
                                    {value: 'Being Upgraded', label: 'Being Upgraded'},
                                    {value: 'Other', label: 'Other'},]

export const CATEGORY = [{value: 'Instrument & Equipment', label: 'Instrument & Equipment'},
                            {value: 'Material Storage', label: 'Material Storage'},
                            {value: 'Network', label: 'Network'},
                            {value: 'Compute', label: 'Compute'},
                            {value: 'Data Storage', label: 'Data Storage'},
                            {value: 'Data', label: 'Data'},
                            {value: 'Scholarly Communication', label: 'Scholarly Communication'},
                            {value: 'Software', label: 'Software'},
                            {value: 'Application', label: 'Application'},
                            {value: 'Development Resource', label: 'Development Resource'},
                            {value: 'Samples', label: 'Samples'},
                            {value: 'Data Management', label: 'Data Management'},
                            {value: 'Data Analysis', label: 'Data Analysis'},
                            {value: 'Measurement & Materials Analysis', label: 'Measurement & Materials Analysis'},
                            {value: 'Security & Identity', label: 'Security & Identity'},
                            {value: 'Operations & Infrastructure Management Services', label: 'Operations & Infrastructure Management Services'},
                            {value: 'Education & Training', label: 'Education & Training'},
                            {value: 'Consultancy & Support', label: 'Consultancy & Support'},
                            {value: 'Aggregators & Integrators', label: 'Aggregators & Integrators'},
                            {value: 'Other', label: 'Other'},]
 
export const SUBCATEGORY = {
    'Instrument & Equipment':
      [
        { label: 'Spectrometer', value: 'Spectrometer' },
        { label: 'Radiation', value: 'Radiation' },
        { label: 'Microscopy', value: 'Microscopy' },
        { label: 'Laser', value: 'Laser' },
        { label: 'Geophysical', value: 'Geophysical' },
        { label: 'Chromatographer', value: 'Chromatographer' },
        { label: 'Cytometer', value: 'Cytometer' },
        { label: 'Spectrophotometer', value: 'Spectrophotometer' },
        { label: 'Digitisation equipment', value: 'Digitisation equipment' },
        { label: 'Monument maintenance equipment', value: 'Monument maintenance equipment' },
        { label: 'Other', value: 'Other' },
    ],
    'Material Storage':
      [
        { label: 'Warehousing', value: 'Warehousing' },
        { label: 'Fulfilment', value: 'Fulfilment' },
        { label: 'Assembly', value: 'Assembly' },
        { label: 'Sorting', value: 'Sorting' },
        { label: 'Re-working', value: 'Re-working' },
        { label: 'Packaging', value: 'Packaging' },
        { label: 'Quality inspecting', value: 'Quality inspecting' },
        { label: 'Archiving', value: 'Archiving' },
        { label: 'Disposal', value: 'Disposal' },
        { label: 'Repository', value: 'Repository' },
        { label: 'Preservation', value: 'Preservation' },
        { label: 'Other', value: 'Other' },
    ],
    'Network':
      [
        { label: 'Direct Connect', value: 'Direct Connect' },
        { label: 'Virtual Network', value: 'Virtual Network' },
        { label: 'Load Balancer', value: 'Load Balancer' },
        { label: 'VPN Gateway', value: 'VPN Gateway' },
        { label: 'Exchange', value: 'Exchange' },
        { label: 'Content Delivery Network', value: 'Content Delivery Network' },
        { label: 'Traffic Manager', value: 'Traffic Manager' },
        { label: 'Other', value: 'Other' },
    ],
    'Compute':
      [
        { label: 'Virtual Machine Management', value: 'Virtual Machine Management' },
        { label: 'Container Management', value: 'Container Management' },
        { label: 'Job Execution', value: 'Job Execution' },
        { label: 'Workload Management', value: 'Workload Management' },
        { label: 'Orchestration', value: 'Orchestration' },
        { label: 'Serverless Applications Repository', value: 'Serverless Applications Repository' },
        { label: 'Other', value: 'Other' },
    ],
    'Data Storage':
      [
        { label: 'Data', value: 'Data' },
        { label: 'File', value: 'File' },
        { label: 'Queue', value: 'Queue' },
        { label: 'Disk', value: 'Disk' },
        { label: 'Online', value: 'Online' },
        { label: 'Archive', value: 'Archive' },
        { label: 'Backup', value: 'Backup' },
        { label: 'Synchronised', value: 'Synchronised' },
        { label: 'Replicated', value: 'Replicated' },
        { label: 'Recovery', value: 'Recovery' },
        { label: 'Digital preservation', value: 'Digital preservation' },
        { label: 'Other', value: 'Other' },
    ],
    'Data':
      [
        { label: 'Government and agency data', value: 'Government and agency data' },
        { label: 'Statistical data', value: 'Statistical data' },
        { label: 'Scientific/Research data', value: 'Scientific/Research data' },
        { label: 'Online service data', value: 'Online service data' },
        { label: 'Clinical trial data', value: 'Clinical trial data' },
        { label: 'Epidemiological data', value: 'Epidemiological data' },
        { label: 'Data archives', value: 'Data archives' },
        { label: 'Other', value: 'Other' },
    ],
    'Scholarly Communication':
      [
        { label: 'Preparation', value: 'Preparation' },
        { label: 'Discovery', value: 'Discovery' },
        { label: 'Analysis', value: 'Analysis' },
        { label: 'Writing', value: 'Writing' },
        { label: 'Publication', value: 'Publication' },
        { label: 'Outreach', value: 'Outreach' },
        { label: 'Assessment', value: 'Assessment' },
        { label: 'Other', value: 'Other' },
    ],
    'Software':
      [
        { label: 'Software Repository', value: 'Software Repository' },
        { label: 'Platform', value: 'Platform' },
        { label: 'Software Package', value: 'Software Package' },
        { label: 'Libraries', value: 'Libraries' },
        { label: 'Other', value: 'Other' },
    ],
    'Application':
      [
        { label: 'Communication', value: 'Communication' },
        { label: 'Collaboration', value: 'Collaboration' },
        { label: 'Productivity', value: 'Productivity' },
        { label: 'Business', value: 'Business' },
        { label: 'Education', value: 'Education' },
        { label: 'Social/Networking', value: 'Social/Networking' },
        { label: 'Utilities', value: 'Utilities' },
        { label: 'Applications Repository', value: 'Applications Repository' },
        { label: 'Other', value: 'Other' },
    ],
    'Development Resource':
      [
        { label: 'Developer Tools', value: 'Developer Tools' },
        { label: 'Software Development Kits', value: 'Software Development Kits' },
        { label: 'Software Libraries', value: 'Software Libraries' },
        { label: 'APIs Repository/Gateway', value: 'APIs Repository/Gateway' },
        { label: 'Other', value: 'Other' },
    ],
    'Samples':
      [
        { label: 'Biological samples', value: 'Biological samples' },
        { label: 'Chemical compounds library', value: 'Chemical compounds library' },
        { label: 'Preparation', value: 'Preparation' },
        { label: 'Characterisation', value: 'Characterisation' },
        { label: 'Other', value: 'Other' },
    ],
    'Data Management':
      [
        { label: 'Mining', value: 'Mining' },
        { label: 'Access', value: 'Access' },
        { label: 'Transfer', value: 'Transfer' },
        { label: 'Registration', value: 'Registration' },
        { label: 'Persistent Identifier', value: 'Persistent Identifier' },
        { label: 'Interlinking', value: 'Interlinking' },
        { label: 'Publishing', value: 'Publishing' },
        { label: 'Discovery', value: 'Discovery' },
        { label: 'Anonymisation', value: 'Anonymisation' },
        { label: 'Preservation', value: 'Preservation' },
        { label: 'Brokering', value: 'Brokering' },
        { label: 'Annotation', value: 'Annotation' },
        { label: 'Validation', value: 'Validation' },
        { label: 'Maintenance', value: 'Maintenance' },
        { label: 'Embargo', value: 'Embargo' },
        { label: 'Digitisation', value: 'Digitisation' },
        { label: 'Other', value: 'Other' },
    ],
    'Data Analysis':
      [
        { label: 'Machine Learning', value: 'Machine Learning' },
        { label: 'Artificial Intelligence', value: 'Artificial Intelligence' },
        { label: 'Forecast', value: 'Forecast' },
        { label: 'Visualization', value: 'Visualization' },
        { label: 'Data extrapolation ', value: 'Data extrapolation ' },
        { label: 'Image/data analysis', value: 'Image/data analysis' },
        { label: 'Workfows', value: 'Workfows' },
        { label: '2D/3D Digitisation', value: '2D/3D Digitisation' },
        { label: 'Other', value: 'Other' },
    ],
    'Measurement & Materials Analysis':
      [
        { label: 'Analysis', value: 'Analysis' },
        { label: 'Maintenance and Modification', value: 'Maintenance and Modification' },
        { label: 'Production', value: 'Production' },
        { label: 'Testing and Validation', value: 'Testing and Validation' },
        { label: 'Characterisation', value: 'Characterisation' },
        { label: 'Validation', value: 'Validation' },
        { label: 'Workflows', value: 'Workflows' },
        { label: 'Other', value: 'Other' },
    ],
    'Security & Identity':
      [
        { label: 'User authentication', value: 'User authentication' },
        { label: 'Identity and access management', value: 'Identity and access management' },
        { label: 'Threat protection', value: 'Threat protection' },
        { label: 'Coordination', value: 'Coordination' },
        { label: 'Tools', value: 'Tools' },
        { label: 'Certification authority', value: 'Certification authority' },
        { label: 'Single Sign-On', value: 'Single Sign-On' },
        { label: 'Firewall', value: 'Firewall' },
        { label: 'Group Management', value: 'Group Management' },
        { label: 'Other', value: 'Other' },

    ],
    'Operations & Infrastructure Management Services':
      [
        { label: 'Accounting', value: 'Accounting' },
        { label: 'Helpdesk', value: 'Helpdesk' },
        { label: 'Monitoring', value: 'Monitoring' },
        { label: 'Analysis', value: 'Analysis' },
        { label: 'Configuration', value: 'Configuration' },
        { label: 'Utilities', value: 'Utilities' },
        { label: 'Coordination', value: 'Coordination' },
        { label: 'Billing', value: 'Billing' },
        { label: 'Order Management', value: 'Order Management' },
        { label: 'Transportation', value: 'Transportation' },
        { label: 'Other', value: 'Other' },
    ],
    'Education & Training':
      [
        { label: 'Online Courses', value: 'Online Courses' },
        { label: 'Open Registration Courses', value: 'Open RegistrationCourses' },
        { label: 'In-house Courses', value: 'In-house Courses' },
        { label: 'Training Tool', value: 'Training Tool' },
        { label: 'Training Platform', value: 'Training Platform' },
        { label: 'Related Training', value: 'Related Training' },
        { label: 'Required Training', value: 'Required Training' },
        { label: 'Other', value: 'Other' },
    ],
    'Consultancy & Support':
      [
        { label: 'Consulting', value: 'Consulting' },
        { label: 'Audit and Assessment', value: 'Audit and Assessment' },
        { label: 'Application Porting', value: 'Application Porting' },
        { label: 'Application Scaling', value: 'Application Scaling' },
        { label: 'Application Optimisation', value: 'Application Optimisation' },
        { label: 'Software Development', value: 'Software Development' },
        { label: 'Software Improvement', value: 'Software Improvement' },
        { label: 'Modeling and Simulation', value: 'Modeling and Simulation' },
        { label: 'Prototype Development', value: 'Prototype Development' },
        { label: 'Testing', value: 'Testing' },
        { label: 'Certification', value: 'Certification' },
        { label: 'Calibration', value: 'Calibration' },
        { label: 'Benchmarking', value: 'Benchmarking' },
        { label: 'Technology Transfer', value: 'Technology Transfer' },
        { label: 'Methodology Development', value: 'Methodology Development' },
        { label: 'Other', value: 'Other' },
    ],
    'Aggregators & Integrators':
      [
        { label: 'Services', value: 'Services' },
        { label: 'Data', value: 'Data' },
        { label: 'Applications', value: 'Applications' },
        { label: 'Software', value: 'Software' },
        { label: 'Other', value: 'Other' },
      ],
    'Other':
      [
        { label: 'Other', value: 'Other' },
    ],
  }
                            
export const TARGET_USERS = [{value: 'Researchers', label: 'Researchers'},
                            {value: 'Research groups', label: 'Research groups'},
                            {value: 'Research communities', label: 'Research communities'},
                            {value: 'Research projects', label: 'Research projects'},
                            {value: 'Research networks', label: 'Research networks'},
                            {value: 'Research managers', label: 'Research managers'},
                            {value: 'Research organisations', label: 'Research organisations'},
                            {value: 'Students', label: 'Students'},
                            {value: 'Innovators', label: 'Innovators'},
                            {value: 'Businesses', label: 'Businesses'},
                            {value: 'Providers', label: 'Providers'},
                            {value: 'Funders', label: 'Funders'},
                            {value: 'Policy Makers', label: 'Policy Makers'},
                            {value: 'Research Infrastructure Managers', label: 'Research Infrastructure Managers'},
                            {value: 'Provider Managers', label: 'Provider Managers'},
                            {value: 'Resource Managers', label: 'Resource Managers'},
                            {value: 'Other', label: 'Other'},
                          ]

export const ACCESS_TYPE = [{value: 'Physical', label: 'Physical'},
                          {value: 'Remote', label: 'Remote'},
                          {value: 'Virtual', label: 'Virtual'},
                          {value: 'Mail-in', label: 'Mail-in'},
                          {value: 'Other', label: 'Other'},
                        ]

export const ACCESS_MODE = [{value: 'Free', label: 'Free'},
                        {value: 'Free conditionally', label: 'Free conditionally'},
                        {value: 'Peer-reviewed', label: 'Peer-reviewed'},
                        {value: 'Paid', label: 'Paid'},
                        {value: 'Other', label: 'Other'},
                      ]

export const GEOGRAPHICAL_AVAILABILITY = [{value: 'Worldwide (WW)', label: 'Worldwide (WW)'},
                      {value: 'Europe (EO)', label: 'Europe (EO)'},
                      {value: 'European Union (EU)', label: 'European Union (EU)'},
                      {value: 'Euro Zone (EZ)', label: 'Euro Zone (EZ)'},
                      {value: 'Schengen Area (AH)', label: 'Schengen Area (AH)'},
                      {value: 'Other', label: 'Other'},
                    ]

export const LANGUAGE_AVAILABILITY = [{value: 'Abkhazian (ab)', label: 'Abkhazian (ab)'},
                    {value: 'Afar (aa)', label: 'Afar (aa)'},
                    {value: 'Afrikaans (af)', label: 'Afrikaans (af)'},
                    {value: 'Akan (ak)', label: 'Akan (ak)'},
                    {value: 'Albanian (sq)', label: 'Albanian (sq)'},
                    {value: 'Amharic (am)', label: 'Amharic (am)'},
                    {value: 'Arabic (ar)', label: 'Arabic (ar)'},
                    {value: 'Aragonese (an)', label: 'Aragonese (an)'},
                    {value: 'Armenian (hy)', label: 'Armenian (hy)'},
                    {value: 'Assamese (as)', label: 'Assamese (as)'},
                    {value: 'Avaric (av)', label: 'Avaric (av)'},
                    {value: 'Avestan (ae)', label: 'Avestan (ae)'},
                    {value: 'Aymara (ay)', label: 'Aymara (ay)'},
                    {value: 'Azerbaijani (az)', label: 'Azerbaijani (az)'},
                    {value: 'Bambara (bm)', label: 'Bambara (bm)'},
                    {value: 'Bashkir (ba)', label: 'Bashkir (ba)'},
                    {value: 'Basque (eu)', label: 'Basque (eu)'},
                    {value: 'Belarusian (be)', label: 'Belarusian (be)'},
                    {value: 'Bengali (bn)', label: 'Bengali (bn)'},
                    {value: 'Bihari (bh)', label: 'Bihari (bh)'},
                    {value: 'Bislama (bi)', label: 'Bislama (bi)'},
                    {value: 'Bosnian (bs)', label: 'Bosnian (bs)'},
                    {value: 'Breton (br)', label: 'Breton (br)'},
                    {value: 'Bulgarian (bg)', label: 'Bulgarian (bg)'},
                    {value: 'Burmese (my)', label: 'Burmese (my)'},
                    {value: 'Catalan (ca)', label: 'Catalan (ca)'},
                    {value: 'Chamorro (ch)', label: 'Chamorro (ch)'},
                    {value: 'Chechen (ce)', label: 'Chechen (ce)'},
                    {value: 'Chichewa (ny)', label: 'Chichewa (ny)'},
                    {value: 'Chinese (zh)', label: 'Chinese (zh)'},
                    {value: 'Chuvash (cv)', label: 'Chuvash (cv)'},
                    {value: 'Cornish (kw)', label: 'Cornish (kw)'},
                    {value: 'Corsican (co', label: 'Corsican (co'},
                    {value: 'Cree (cr)', label: 'Cree (cr)'},
                    {value: 'Croatian (hr)', label: 'Croatian (hr)'},
                    {value: 'Czech (cs)', label: 'Czech (cs)'},
                    {value: 'Danish (da)', label: 'Danish (da)'},
                    {value: 'Divehi (dv)', label: 'Divehi (dv)'},
                    {value: 'Dutch (nl)', label: 'Dutch (nl)'},
                    {value: 'Dzongkha (dx)', label: 'Dzongkha (dx)'},
                    {value: 'English (en)', label: 'English (en)'},
                    {value: 'Esperanto (eo)', label: 'Esperanto (eo)'},
                    {value: 'Estonian (et)', label: 'Estonian (et)'},
                    {value: 'Ewe (ee)', label: 'Ewe (ee)'},
                    {value: 'Faroese (fo)', label: 'Faroese (fo)'},
                    {value: 'Fijian (fj)', label: 'Fijian (fj)'},
                    {value: 'Finnish (fi)', label: 'Finnish (fi)'},
                    {value: 'French (fr)', label: 'French (fr)'},
                    {value: 'Fula (ff)', label: 'Fula (ff)'},
                    {value: 'Gaelic (gl)', label: 'Gaelic (gl)'},
                    {value: 'Galician (gd)', label: 'Galician (gd)'},
                    {value: 'Georgian (ka)', label: 'Georgian (ka)'},
                    {value: 'German (de)', label: 'German (de)'},
                    {value: 'Greek (el)', label: 'Greek (el)'},
                    {value: 'Guarani (gn)', label: 'Guarani (gn)'},
                    {value: 'Gujarati (gu)', label: 'Gujarati (gu)'},
                    {value: 'Haitian (ht)', label: 'Haitian (ht)'},
                    {value: 'Hausa (ha)', label: 'Hausa (ha)'},
                    {value: 'Hebrew (he)', label: 'Hebrew (he)'},
                    {value: 'Herero (hz)', label: 'Herero (hz)'},
                    {value: 'Hindi (hi)', label: 'Hindi (hi)'},
                    {value: 'Hiri Motu (ho)', label: 'Hiri Motu (ho)'},
                    {value: 'Hungarian (hu)', label: 'Hungarian (hu)'},
                    {value: 'Icelandic (is)', label: 'Icelandic (is)'},
                    {value: 'Ido (io)', label: 'Ido (io)'},
                    {value: 'Igbo (ig)', label: 'Igbo (ig)'},
                    {value: 'Indonesian (id)', label: 'Indonesian (id)'},
                    {value: 'Interlingua (ia)', label: 'Interlingua (ia)'},
                    {value: 'Interlingue (ie)', label: 'Interlingue (ie)'},
                    {value: 'Inuktitut (iu)', label: 'Inuktitut (iu)'},
                    {value: 'Inupiak (ik)', label: 'Inupiak (ik)'},
                    {value: 'Irish (ga)', label: 'Irish (ga)'},
                    {value: 'Italian (it)', label: 'Italian (it)'},
                    {value: 'Japanese (ja)', label: 'Japanese (ja)'},
                    {value: 'Javanese (jv)', label: 'Javanese (jv)'},
                    {value: 'Kalaallisut (kl)', label: 'Kalaallisut (kl)'},
                    {value: 'Kannada (kn)', label: 'Kannada (kn)'},
                    {value: 'Kanuri (kr)', label: 'Kanuri (kr)'},
                    {value: 'Kashmiri (ks)', label: 'Kashmiri (ks)'},
                    {value: 'Kazakh (kk)', label: 'Kazakh (kk)'},
                    {value: 'Khmer (km)', label: 'Khmer (km)'},
                    {value: 'Kikuyu (ki)', label: 'Kikuyu (ki)'},
                    {value: 'Kinyarwanda (rw)', label: 'Kinyarwanda (rw)'},
                    {value: 'Kirundi (rn)', label: 'Kirundi (rn)'},
                    {value: 'Komi (kv)', label: 'Komi (kv)'},
                    {value: 'Kongo (kg)', label: 'Kongo (kg)'},
                    {value: 'Korean (ko)', label: 'Korean (ko)'},
                    {value: 'Kurdish (ku)', label: 'Kurdish (ku)'},
                    {value: 'Kwanyama (kj)', label: 'Kwanyama (kj)'},
                    {value: 'Kyrgyz (ky)', label: 'Kyrgyz (ky)'},
                    {value: 'Lao (lo)', label: 'Lao (lo)'},
                    {value: 'Latin (la)', label: 'Latin (la)'},
                    {value: 'Latvian (lv)', label: 'Latvian (lv)'},
                    {value: 'Limburgish (li)', label: 'Limburgish (li)'},
                    {value: 'Lingala (ln)', label: 'Lingala (ln)'},
                    {value: 'Lithuanian (lt)', label: 'Lithuanian (lt)'},
                    {value: 'Luba-Katanga (lu)', label: 'Luba-Katanga (lu)'},
                    {value: 'Luxembourgish (lb)', label: 'Luxembourgish (lb)'},
                    {value: 'Macedonian (mk)', label: 'Macedonian (mk)'},
                    {value: 'Malagasy (mg)', label: 'Malagasy (mg)'},
                    {value: 'Malay (ms)', label: 'Malay (ms)'},
                    {value: 'Malayalam (ml)', label: 'Malayalam (ml)'},
                    {value: 'Maltese (mt)', label: 'Maltese (mt)'},
                    {value: 'Manx (gv)', label: 'Manx (gv)'},
                    {value: 'Maori (mi)', label: 'Maori (mi)'},
                    {value: 'Marathi (mr)', label: 'Marathi (mr)'},
                    {value: 'Marshallese (mh)', label: 'Marshallese (mh)'},
                    {value: 'Mongolian (mn)', label: 'Mongolian (mn)'},
                    {value: 'Nauru (na)', label: 'Nauru (na)'},
                    {value: 'Navajo (nv)', label: 'Navajo (nv)'},
                    {value: 'Ndonga (ng)', label: 'Ndonga (ng)'},
                    {value: 'Nepali (ne)', label: 'Nepali (ne)'},
                    {value: 'Northern Ndebele (nd)', label: 'Northern Ndebele (nd)'},
                    {value: 'Norwegian (no)', label: 'Norwegian (no)'},
                    {value: 'Norwegian bokmål (nb)', label: 'Norwegian bokmål (nb)'},
                    {value: 'Norwegian nynorsk (nn)', label: 'Norwegian nynorsk (nn)'},
                    {value: 'Nuosu (ii)', label: 'Nuosu (ii)'},
                    {value: 'Occitan (oc)', label: 'Occitan (oc)'},
                    {value: 'Ojibwe (oj)', label: 'Ojibwe (oj)'},
                    {value: 'Old Church Slavonic (cu)', label: 'Old Church Slavonic (cu)'},
                    {value: 'Oriya (or)', label: 'Oriya (or)'},
                    {value: 'Oromo (om)', label: 'Oromo (om)'},
                    {value: 'Ossetian (os)', label: 'Ossetian (os)'},
                    {value: 'Pāli (pi)', label: 'Pāli (pi)'},
                    {value: 'Pashto (ps)', label: 'Pashto (ps)'},
                    {value: 'Persian (fa)', label: 'Persian (fa)'},
                    {value: 'Polish (pl)', label: 'Polish (pl)'},
                    {value: 'Portuguese (pt)', label: 'Portuguese (pt)'},
                    {value: 'Panjabi (pa)', label: 'Panjabi (pa)'},
                    {value: 'Quechua (qu)', label: 'Quechua (qu)'},
                    {value: 'Romanian (ro)', label: 'Romanian (ro)'},
                    {value: 'Romansh (rm)', label: 'Romansh (rm)'},
                    {value: 'Russian (ru)', label: 'Russian (ru)'},
                    {value: 'Sami (se)', label: 'Sami (se)'},
                    {value: 'Samoan (sm)', label: 'Samoan (sm)'},
                    {value: 'Sango (sg)', label: 'Sango (sg)'},
                    {value: 'Sanskrit (sa)', label: 'Sanskrit (sa)'},
                    {value: 'Sardinian (sc)', label: 'Sardinian (sc)'},
                    {value: 'Serbian (sr)', label: 'Serbian (sr)'},
                    {value: 'Sesotho (st) ', label: 'Sesotho (st) '},
                    {value: 'Setswana (tn)', label: 'Setswana (tn)'},
                    {value: 'Shona (sn)', label: 'Shona (sn)'},
                    {value: 'Sindhi (sd)', label: 'Sindhi (sd)'},
                    {value: 'Sinhalese (si)', label: 'Sinhalese (si)'},
                    {value: 'Slovak (sk)', label: 'Slovak (sk)'},
                    {value: 'Slovenian (sl)', label: 'Slovenian (sl)'},
                    {value: 'Somali (so)', label: 'Somali (so)'},
                    {value: 'Southern Ndebele (nr)', label: 'Southern Ndebele (nr)'},
                    {value: 'Spanish (es)', label: 'Spanish (es)'},
                    {value: 'Sundanese (su)', label: 'Sundanese (su)'},
                    {value: 'Swahili (sw)', label: 'Swahili (sw)'},
                    {value: 'Swati (ss)', label: 'Swati (ss)'},
                    {value: 'Swedish (sv)', label: 'Swedish (sv)'},
                    {value: 'Tagalog (tl)', label: 'Tagalog (tl)'},
                    {value: 'Tahitian (ty)', label: 'Tahitian (ty)'},
                    {value: 'Tajik (tg)', label: 'Tajik (tg)'},
                    {value: 'Tamil (ta)', label: 'Tamil (ta)'},
                    {value: 'Tatar (tt)', label: 'Tatar (tt)'},
                    {value: 'Telugu (te)', label: 'Telugu (te)'},
                    {value: 'Thai (th)', label: 'Thai (th)'},
                    {value: 'Tibetan (bo)', label: 'Tibetan (bo)'},
                    {value: 'Tigrinya (ti)', label: 'Tigrinya (ti)'},
                    {value: 'Tonga (to)', label: 'Tonga (to)'},
                    {value: 'Tsonga (ts)', label: 'Tsonga (ts)'},
                    {value: 'Turkish (tr)', label: 'Turkish (tr)'},
                    {value: 'Turkmen (tk)', label: 'Turkmen (tk)'},
                    {value: 'Twi (tw)', label: 'Twi (tw)'},
                    {value: 'Ukrainian (uk)', label: 'Ukrainian (uk)'},
                    {value: 'Urdu (ur)', label: 'Urdu (ur)'},
                    {value: 'Uyghur (ug)', label: 'Uyghur (ug)'},
                    {value: 'Uzbek (uz)', label: 'Uzbek (uz)'},
                    {value: 'Venda (ve)', label: 'Venda (ve)'},
                    {value: 'Vietnamese (vi)', label: 'Vietnamese (vi)'},
                    {value: 'Volapük (vo)', label: 'Volapük (vo)'},
                    {value: 'Wallon (wa)', label: 'Wallon (wa)'},
                    {value: 'Welsh (cy)', label: 'Welsh (cy)'},
                    {value: 'Western Frisian (fy)', label: 'Western Frisian (fy)'},
                    {value: 'Wolof (wo)', label: 'Wolof (wo)'},
                    {value: 'Xhosa (xh)', label: 'Xhosa (xh)'},
                    {value: 'Yiddish (yi)', label: 'Yiddish (yi)'},
                    {value: 'Yoruba (yo)', label: 'Yoruba (yo)'},
                    {value: 'Zhuang (za)', label: 'Zhuang (za)'},
                    {value: 'Zulu (zu)', label: 'Zulu (zu)'},
                    {value: 'Other', label: 'Other'},
                  ] 
            

export const GEOGRAPHIC_LOCATION = [{value: 'Afghanistan (AF)', label: 'Afghanistan (AF)'},
                    {value: 'Åland Islands (AX)', label: 'Åland Islands (AX)'},
                    {value: 'Albania (AL)', label: 'Albania (AL)'},
                    {value: 'Algeria (DZ', label: 'Algeria (DZ'},
                    {value: 'American Samoa (AS)', label: 'American Samoa (AS)'},
                    {value: 'Andorra (AD)', label: 'Andorra (AD)'},
                    {value: 'Angola (AO)', label: 'Angola (AO)'},
                    {value: 'Anguilla (AI)', label: 'Anguilla (AI)'},
                    {value: 'Antarctica (AQ)', label: 'Antarctica (AQ)'},
                    {value: 'Antigua and Barbuda (AG)', label: 'Antigua and Barbuda (AG)'},
                    {value: 'Argentina (AR)', label: 'Argentina (AR)'},
                    {value: 'Armenia (AM)', label: 'Armenia (AM)'},
                    {value: 'Aruba (AW)', label: 'Aruba (AW)'},
                    {value: 'Australia (AU)', label: 'Australia (AU)'},
                    {value: 'Austria (AT)', label: 'Austria (AT)'},
                    {value: 'Azerbaijan (AZ)', label: 'Azerbaijan (AZ)'},
                    {value: 'Bahamas (BS)', label: 'Bahamas (BS)'},
                    {value: 'Bahrain (BH)', label: 'Bahrain (BH)'},
                    {value: 'Bangladesh (BD)', label: 'Bangladesh (BD)'},
                    {value: 'Barbados (BB)', label: 'Barbados (BB)'},
                    {value: 'Belarus (BY)', label: 'Belarus (BY)'},
                    {value: 'Belgium (BE)', label: 'Belgium (BE)'},
                    {value: 'Belize (BZ)', label: 'Belize (BZ)'},
                    {value: 'Benin (BJ)', label: 'Benin (BJ)'},
                    {value: 'Bermuda (BM)', label: 'Bermuda (BM)'},
                    {value: 'Bhutan (BT)', label: 'Bhutan (BT)'},
                    {value: 'Bolivia (BO)', label: 'Bolivia (BO)'},
                    {value: 'Bonaire, Sint Eustatius and Saba (BQ)', label: 'Bonaire, Sint Eustatius and Saba (BQ)'},
                    {value: 'Bosnia and Herzegovina (BA)', label: 'Bosnia and Herzegovina (BA)'},
                    {value: 'Botswana (BW)', label: 'Botswana (BW)'},
                    {value: 'Bouvet Island BV)', label: 'Bouvet Island BV)'},
                    {value: 'Brazil (BR)', label: 'Brazil (BR)'},
                    {value: 'British Indian Ocean Territory (IO)', label: 'British Indian Ocean Territory (IO)'},
                    {value: 'Brunei Darussalam (BN)', label: 'Brunei Darussalam (BN)'},
                    {value: 'Bulgaria (BG)', label: 'Bulgaria (BG)'},
                    {value: 'Burkina Faso (BF)', label: 'Burkina Faso (BF)'},
                    {value: 'Burundi (BI)', label: 'Burundi (BI)'},
                    {value: 'Cabo Verde (CV)', label: 'Cabo Verde (CV)'},
                    {value: 'Cambodia (KH)', label: 'Cambodia (KH)'},
                    {value: 'Cameroon (CM)', label: 'Cameroon (CM)'},
                    {value: 'Canada (CA)', label: 'Canada (CA)'},
                    {value: 'Cayman Islands (KY)', label: 'Cayman Islands (KY)'},
                    {value: 'Central African Republic (CF)', label: 'Central African Republic (CF)'},
                    {value: 'Chad (TD)', label: 'Chad (TD)'},
                    {value: 'Chile (CL)', label: 'Chile (CL)'},
                    {value: 'China (CN)', label: 'China (CN)'},
                    {value: 'Christmas Island (CX)', label: 'Christmas Island (CX)'},
                    {value: 'Cocos Islands (CC)', label: 'Cocos Islands (CC)'},
                    {value: 'Colombia (CO)', label: 'Colombia (CO)'},
                    {value: 'Comoros (KM)', label: 'Comoros (KM)'},
                    {value: 'Congo (Democratic Republic) (CD)', label: 'Congo (Democratic Republic) (CD)'},
                    {value: 'Congo (CG)', label: 'Congo (CG)'},
                    {value: 'Cook Islands (CK)', label: 'Cook Islands (CK)'},
                    {value: 'Costa Rica (CR)', label: 'Costa Rica (CR)'},
                    {value: 'Côte d’Ivoire (CI)', label: 'Côte d’Ivoire (CI)'},
                    {value: 'Croatia (HR)', label: 'Croatia (HR)'},
                    {value: 'Cuba (CU)', label: 'Cuba (CU)'},
                    {value: 'Curaçao (CW)', label: 'Curaçao (CW)'},
                    {value: 'Cyprus (CY)', label: 'Cyprus (CY)'},
                    {value: 'Czechia (CZ)', label: 'Czechia (CZ)'},
                    {value: 'Denmark (DK)', label: 'Denmark (DK)'},
                    {value: 'Djibouti (DJ)', label: 'Djibouti (DJ)'},
                    {value: 'Dominica (DM)', label: 'Dominica (DM)'},
                    {value: 'Dominican Republic (DO)', label: 'Dominican Republic (DO)'},
                    {value: 'Ecuador (EC)', label: 'Ecuador (EC)'},
                    {value: 'Ecuador (EC)', label: 'Ecuador (EC)'},
                    {value: 'El Salvador (SV)', label: 'El Salvador (SV)'},
                    {value: 'Equatorial Guinea (GQ)', label: 'Equatorial Guinea (GQ)'},
                    {value: 'Eritrea (ER)', label: 'Eritrea (ER)'},
                    {value: 'Estonia (EE)', label: 'Estonia (EE)'},
                    {value: 'Eswatini (SZ)', label: 'Eswatini (SZ)'},
                    {value: 'Ethiopia (ET)', label: 'Ethiopia (ET)'},
                    {value: 'Falkland Islands (FK)', label: 'Falkland Islands (FK)'},
                    {value: 'Faroe Islands (FO)', label: 'Faroe Islands (FO)'},
                    {value: 'Fiji (FJ)', label: 'Fiji (FJ)'},
                    {value: 'Finland (FI)', label: 'Finland (FI)'},
                    {value: 'France (FR)', label: 'France (FR)'},
                    {value: 'French Guiana (GF)', label: 'French Guiana (GF)'},
                    {value: 'French Polynesia (PF)', label: 'French Polynesia (PF)'},
                    {value: 'Gabon (GA)', label: 'Gabon (GA)'},
                    {value: 'Gambia (GM)', label: 'Gambia (GM)'},
                    {value: 'Georgia (GE)', label: 'Georgia (GE)'},
                    {value: 'Germany (DE)', label: 'Germany (DE)'},
                    {value: 'Ghana (GH)', label: 'Ghana (GH)'},
                    {value: 'Gibraltar (GI)', label: 'Gibraltar (GI)'},
                    {value: 'Greece (EL)', label: 'Greece (EL)'},
                    {value: 'Greenland (GL)', label: 'Greenland (GL)'},
                    {value: 'Grenada (GD)', label: 'Grenada (GD)'},
                    {value: 'Guadeloupe (GP)', label: 'Guadeloupe (GP)'},
                    {value: 'Guam (GU)', label: 'Guam (GU)'},
                    {value: 'Guatemala (GT)', label: 'Guatemala (GT)'},
                    {value: 'Guernsey (GC)', label: 'Guernsey (GC)'},
                    {value: 'Guinea (GN)', label: 'Guinea (GN)'},
                    {value: 'Guinea-Bissau (GW)', label: 'Guinea-Bissau (GW)'},
                    {value: 'Guyana (GY)', label: 'Guyana (GY)'},
                    {value: 'Haiti (HT)', label: 'Haiti (HT)'},
                    {value: 'Holy See (VA)', label: 'Holy See (VA)'},
                    {value: 'Honduras (HN)', label: 'Honduras (HN)'},
                    {value: 'Hong Kong (HK)', label: 'Hong Kong (HK)'},
                    {value: 'Hungary (HU)', label: 'Hungary (HU)'},
                    {value: 'Iceland (IS)', label: 'Iceland (IS)'},
                    {value: 'India (IN)', label: 'India (IN)'},
                    {value: 'Indonesia (ID)', label: 'Indonesia (ID)'},
                    {value: 'Iran (IR)', label: 'Iran (IR)'},
                    {value: 'Iraq (IQ)', label: 'Iraq (IQ)'},
                    {value: 'Ireland (IE)', label: 'Ireland (IE)'},
                    {value: 'Isle of Man (IM)', label: 'Isle of Man (IM)'},
                    {value: 'Israel (IL)', label: 'Israel (IL)'},
                    {value: 'Italy (IT)', label: 'Italy (IT)'},
                    {value: 'Jamaica (JM)', label: 'Jamaica (JM)'},
                    {value: 'Japan (JP)', label: 'Japan (JP)'},
                    {value: 'Jersey (JE)', label: 'Jersey (JE)'},
                    {value: 'Jordan (JO)', label: 'Jordan (JO)'},
                    {value: 'Kazakhstan (KZ)', label: 'Kazakhstan (KZ)'},
                    {value: 'Kenya (KE)', label: 'Kenya (KE)'},
                    {value: 'Kiribati (KI)', label: 'Kiribati (KI)'},
                    {value: 'Korea (Democratic People\'s Republic) (KP)', label: 'Korea (Democratic People\'s Republic) (KP)'},
                    {value: 'Korea (Republic) (KR)', label: 'Korea (Republic) (KR)'},
                    {value: 'Kuwait (KW)', label: 'Kuwait (KW)'},
                    {value: 'Kyrgyzstan (KG)', label: 'Kyrgyzstan (KG)'},
                    {value: 'Lao People\'s Democratic Republic (LA)', label: 'Lao People\'s Democratic Republic (LA)'},
                    {value: 'Latvia (LV)', label: 'Latvia (LV)'},
                    {value: 'Lebanon (LB)', label: 'Lebanon (LB)'},
                    {value: 'Lesotho (LS)', label: 'Lesotho (LS)'},
                    {value: 'Liberia (LR)', label: 'Liberia (LR)'},
                    {value: 'Libya (LY)', label: 'Libya (LY)'},
                    {value: 'Liechtenstein (LI)', label: 'Liechtenstein (LI)'},
                    {value: 'Lithuania (LT)', label: 'Lithuania (LT)'},
                    {value: 'Luxembourg (LU)', label: 'Luxembourg (LU)'},
                    {value: 'Macao (MO)', label: 'Macao (MO)'},
                    {value: 'Madagascar (MG)', label: 'Madagascar (MG)'},
                    {value: 'Malawi (MW)', label: 'Malawi (MW)'},
                    {value: 'Malaysia (MY)', label: 'Malaysia (MY)'},
                    {value: 'Maldives (MV)', label: 'Maldives (MV)'},
                    {value: 'Mali (ML)', label: 'Mali (ML)'},
                    {value: 'Malta (MT)', label: 'Malta (MT)'},
                    {value: 'Marshall Islands (MH)', label: 'Marshall Islands (MH)'},
                    {value: 'Martinique (MQ)', label: 'Martinique (MQ)'},
                    {value: 'Mauritania (MR)', label: 'Mauritania (MR)'},
                    {value: 'Mauritius (MU)', label: 'Mauritius (MU)'},
                    {value: 'Mayotte (YT)', label: 'Mayotte (YT)'},
                    {value: 'Mexico (MX)', label: 'Mexico (MX)'},
                    {value: 'Micronesia (FM)', label: 'Micronesia (FM)'},
                    {value: 'Moldova (MD)', label: 'Moldova (MD)'},
                    {value: 'Monaco (MC)', label: 'Monaco (MC)'},
                    {value: 'Mongolia (MN)', label: 'Mongolia (MN)'},
                    {value: 'Montenegro (ME)', label: 'Montenegro (ME)'},
                    {value: 'Montserrat (MS)', label: 'Montserrat (MS)'},
                    {value: 'Morocco (MA)', label: 'Morocco (MA)'},
                    {value: 'Mozambique (MZ)', label: 'Mozambique (MZ)'},
                    {value: 'Myanmar (MM)', label: 'Myanmar (MM)'},
                    {value: 'Namibia (NA)', label: 'Namibia (NA)'},
                    {value: 'Nauru (NR)', label: 'Nauru (NR)'},
                    {value: 'Nepal (NP)', label: 'Nepal (NP)'},
                    {value: 'Netherlands (NL)', label: 'Netherlands (NL)'},
                    {value: 'New Caledonia (NC)', label: 'New Caledonia (NC)'},
                    {value: 'New Zealand (NZ)', label: 'New Zealand (NZ)'},
                    {value: 'Nicaragua (NI)', label: 'Nicaragua (NI)'},
                    {value: 'Niger (NE)', label: 'Niger (NE)'},
                    {value: 'Nigeria (NG)', label: 'Nigeria (NG)'},
                    {value: 'Niue (NU)', label: 'Niue (NU)'},
                    {value: 'Norfolk Island (NF)', label: 'Norfolk Island (NF)'},
                    {value: 'North Macedonia MK)', label: 'North Macedonia MK)'},
                    {value: 'Northern Mariana Islands (MP)', label: 'Northern Mariana Islands (MP)'},
                    {value: 'Norway (NO)', label: 'Norway (NO)'},
                    {value: 'Oman (OM)', label: 'Oman (OM)'},
                    {value: 'Pakistan (PK)', label: 'Pakistan (PK)'},
                    {value: 'Palau (PW)', label: 'Palau (PW)'},
                    {value: 'Palestine, State of (PS)', label: 'Palestine, State of (PS)'},
                    {value: 'Panama (PA)', label: 'Panama (PA)'},
                    {value: 'Papua New Guinea (PG)', label: 'Papua New Guinea (PG)'},
                    {value: 'Paraguay (PY)', label: 'Paraguay (PY)'},
                    {value: 'Peru (PE)', label: 'Peru (PE)'},
                    {value: 'Philippines (PH)', label: 'Philippines (PH)'},
                    {value: 'Pitcairn (PN)', label: 'Pitcairn (PN)'},
                    {value: 'Poland (PL)', label: 'Poland (PL)'},
                    {value: 'Portugal (PT)', label: 'Portugal (PT)'},
                    {value: 'Puerto Rico (PR)', label: 'Puerto Rico (PR)'},
                    {value: 'Qatar (QA)', label: 'Qatar (QA)'},
                    {value: 'Réunion (RE)', label: 'Réunion (RE)'},
                    {value: 'Romania (RO)', label: 'Romania (RO)'},
                    {value: 'Russian Federation (RU)', label: 'Russian Federation (RU)'},
                    {value: 'Rwanda (RW)', label: 'Rwanda (RW)'},
                    {value: 'Saint Barthélemy (BL)', label: 'Saint Barthélemy (BL)'},
                    {value: 'Saint Helena, Ascension and Tristan da Cunha (SH)', label: 'Saint Helena, Ascension and Tristan da Cunha (SH)'},
                    {value: 'Saint Kitts and Nevis (KN)', label: 'Saint Kitts and Nevis (KN)'},
                    {value: 'Saint Lucia (LC)', label: 'Saint Lucia (LC)'},
                    {value: 'Saint Martin (MF)', label: 'Saint Martin (MF)'},
                    {value: 'Saint Pierre and Miquelon (PM)', label: 'Saint Pierre and Miquelon (PM)'},
                    {value: 'Saint Vincent and the Grenadines (VC)', label: 'Saint Vincent and the Grenadines (VC)'},
                    {value: 'Samoa (WS)', label: 'Samoa (WS)'},
                    {value: 'San Marino (SM)', label: 'San Marino (SM)'},
                    {value: 'São Tomé and Príncipe (ST)', label: 'São Tomé and Príncipe (ST)'},
                    {value: 'Saudi Arabia (SA)', label: 'Saudi Arabia (SA)'},
                    {value: 'Senegal (SN)', label: 'Senegal (SN)'},
                    {value: 'Serbia (RS)', label: 'Serbia (RS)'},
                    {value: 'Seychelles (SC)', label: 'Seychelles (SC)'},
                    {value: 'Sierra Leone (SL)', label: 'Sierra Leone (SL)'},
                    {value: 'Singapore (SG)', label: 'Singapore (SG)'},
                    {value: 'Sint Maarten (SX)', label: 'Sint Maarten (SX)'},
                    {value: 'Slovakia (SK)', label: 'Slovakia (SK)'},
                    {value: 'Slovenia (SI)', label: 'Slovenia (SI)'},
                    {value: 'Solomon Islands (SB)', label: 'Solomon Islands (SB)'},
                    {value: 'Somalia (SO)', label: 'Somalia (SO)'},
                    {value: 'South Africa (ZA)', label: 'South Africa (ZA)'},
                    {value: 'South Sudan (SS)', label: 'South Sudan (SS)'},
                    {value: 'Spain (ES)', label: 'Spain (ES)'},
                    {value: 'Sri Lanka (LK)', label: 'Sri Lanka (LK)'},
                    {value: 'Sudan (SD)', label: 'Sudan (SD)'},
                    {value: 'Suriname (SR)', label: 'Suriname (SR)'},
                    {value: 'Svalbard and Jan Mayen (SJ)', label: 'Svalbard and Jan Mayen (SJ)'},
                    {value: 'Sweden (SE)', label: 'Sweden (SE)'},
                    {value: 'Switzerland (CH)', label: 'Switzerland (CH)'},
                    {value: 'Syrian Arab Republic (SY)', label: 'Syrian Arab Republic (SY)'},
                    {value: 'Taiwan (TW)', label: 'Taiwan (TW)'},
                    {value: 'Tajikistan (TJ)', label: 'Tajikistan (TJ)'},
                    {value: 'Tanzania (TZ)', label: 'Tanzania (TZ)'},
                    {value: 'Thailand (TH)', label: 'Thailand (TH)'},
                    {value: 'Timor-Leste (TL)', label: 'Timor-Leste (TL)'},
                    {value: 'Togo (TG)', label: 'Togo (TG)'},
                    {value: 'Tokelau (TK)', label: 'Tokelau (TK)'},
                    {value: 'Tonga (YO)', label: 'Tonga (YO)'},
                    {value: 'Trinidad and Tobago (TT)', label: 'Trinidad and Tobago (TT)'},
                    {value: 'Tunisia (TN)', label: 'Tunisia (TN)'},
                    {value: 'Turkey (TR)', label: 'Turkey (TR)'},
                    {value: 'Turkmenistan (TM)', label: 'Turkmenistan (TM)'},
                    {value: 'Turks and Caicos Islands (TC)', label: 'Turks and Caicos Islands (TC)'},
                    {value: 'Tuvalu (TV)', label: 'Tuvalu (TV)'},
                    {value: 'Uganda (UG)', label: 'Uganda (UG)'},
                    {value: 'Ukraine (UA)', label: 'Ukraine (UA)'},
                    {value: 'United Arab Emirates (AE)', label: 'United Arab Emirates (AE)'},
                    {value: 'United Kingdom (UK)', label: 'United Kingdom (UK)'},
                    {value: 'United States Minor Outlying Islands (UM)', label: 'United States Minor Outlying Islands (UM)'},
                    {value: 'United States of America (US)', label: 'United States of America (US)'},
                    {value: 'Uruguay (UY)', label: 'Uruguay (UY)'},
                    {value: 'Uzbekistan (UZ)', label: 'Uzbekistan (UZ)'},
                    {value: 'Vanuatu (VU)', label: 'Vanuatu (VU)'},
                    {value: 'Venezuela (VE)', label: 'Venezuela (VE)'},
                    {value: 'Viet Nam (VN)', label: 'Viet Nam (VN)'},
                    {value: 'Virgin Islands (British) (VG)', label: 'Virgin Islands (British) (VG)'},
                    {value: 'Virgin Islands (U.S.) (VI)', label: 'Virgin Islands (U.S.) (VI)'},
                    {value: 'Wallis and Futuna (WF)', label: 'Wallis and Futuna (WF)'},
                    {value: 'Western Sahara (EH)', label: 'Western Sahara (EH)'},
                    {value: 'Yemen (YE)', label: 'Yemen (YE)'},
                    {value: 'Zambia (ZM)', label: 'Zambia (ZM)'},
                    {value: 'Zimbabwe (ZW)', label: 'Zimbabwe (ZW)'},
                    {value: 'Other', label: 'Other'},
                ] 

export const TRL = [{value: 'TRL1', label: 'TRL1: Basic Principles observed'},
                {value: 'TRL2', label: 'TRL2: Technology concept formulated'},
                {value: 'TRL3', label: 'TRL3: Experimental proof of concept'},
                {value: 'TRL4', label: 'TRL4: Technology validated in lab'},
                {value: 'TRL5', label: 'TRL5: Technology validated in relevant environment'},
                {value: 'TRL6', label: 'TRL6: Technology demonstrated in relevant environment'},
                {value: 'TRL7', label: 'TRL7: System prototype demonstration in operational environment'},
                {value: 'TRL8', label: 'TRL8: System complete and qualified'},
                {value: 'TRL9', label: 'TRL9: Actual system proven in operational environment'},
              ] 

export const LIFE_CYCLE_STATUS = [{value: 'Concept', label: 'Concept'},
              {value: 'Discovery', label: 'Discovery'},
              {value: 'Design', label: 'Design'},
              {value: 'Preparation', label: 'Preparation'},
              {value: 'Planned', label: 'Planned'},
              {value: 'Alpha', label: 'Alpha'},
              {value: 'Beta', label: 'Beta'},
              {value: 'Implementation', label: 'Implementation'},
              {value: 'Production', label: 'Production'},
              {value: 'Operation', label: 'Operation'},
              {value: 'In containment', label: 'In containment'},
              {value: 'Retirement', label: 'Retirement'},
              {value: 'Termination', label: 'Termination'},
              {value: 'Other', label: 'Other'},
            ] 

export const RESOURCE_ORDER_TYPE = [{value: 'Request/Order required ', label: 'Request/Order required '},
              {value: 'Open access', label: 'Open access'},
              {value: 'Fully open access', label: 'Fully open access'},
              {value: 'Other', label: 'Other'},        
            ]   

export const FUNDING_BODY = [
              {value: 'Academy of Finland (AKA) - Finland', label: 'Academy of Finland (AKA) - Finland'},
              {value: 'Agency for Environment and Energy Management (ADEME) - France', label: 'Agency for Environment and Energy Management (ADEME) - France'},
              {value: 'Agency for Innovation by Science and Technology (IWT) - Belgium', label: 'Agency for Innovation by Science and Technology (IWT) - Belgium'},
              {value: 'Agency for Science, Innovation and Technology (MITA) - Lithuania', label: 'Agency for Science, Innovation and Technology (MITA) - Lithuania'},
              {value: 'Alfred Wegener Institute for Polar and Marine Research (AWI) - Germany', label: 'Alfred Wegener Institute for Polar and Marine Research (AWI) - Germany'},
              {value: 'Alternative Energies and Atomic Energy Commission (CEA) - France', label: 'Alternative Energies and Atomic Energy Commission (CEA) - France'},
              {value: 'Arts and Humanities Research Council (AHRC) - United Kingdom', label: 'Arts and Humanities Research Council (AHRC) - United Kingdom'},
              {value: 'Australian Research Council (ARC) - Australia', label: 'Australian Research Council (ARC) - Australia'},
              {value: 'Austrian Academy of Sciences (OeAW) - Austria', label: 'Austrian Academy of Sciences (OeAW) - Austria'},
              {value: 'Austrian Research Promotion Agency (FFG) - Austria', label: 'Austrian Research Promotion Agency (FFG) - Austria'},
              {value: 'Austrian Science Fund (FWF) - Austria', label: 'Austrian Science Fund (FWF) - Austria'},
              {value: 'Belmont Forum', label: 'Belmont Forum'},
              {value: 'Biotechnology and Biological Sciences Research Council (BBSRC) - United Kingdom', label: 'Biotechnology and Biological Sciences Research Council (BBSRC) - United Kingdom'},
              {value: 'Brussels Institute for Research and Innovation (INNOVIRIS) - Belgium', label: 'Brussels Institute for Research and Innovation (INNOVIRIS) - Belgium'},
              {value: 'Canadian Institutes of Health Research (CIHR) - Canada', label: 'Canadian Institutes of Health Research (CIHR) - Canada'},
              {value: 'Carlos III Health Institute (ISCIII) - Spain', label: 'Carlos III Health Institute (ISCIII) - Spain'},
              {value: 'Center for Industrial Technological Development (CDTI) - Spain', label: 'Center for Industrial Technological Development (CDTI) - Spain'},
              {value: 'Croatian Science Foundation (CSF) - Croatia', label: 'Croatian Science Foundation (CSF) - Croatia'},
              {value: 'Czech Science Foundation (GACR) - Czechia', label: 'Czech Science Foundation (GACR) - Czechia'},
              {value: 'Danish Agency for Science and Higher Education (DASHE) - Denmark', label: 'Danish Agency for Science and Higher Education (DASHE) - Denmark'},
              {value: 'Danish Agency for Science, Technology and Innovation (DASTI) - Denmark', label: 'Danish Agency for Science, Technology and Innovation (DASTI) - Denmark'},
              {value: 'Danish Council for Independent Research (DFF) - Denmark', label: 'Danish Council for Independent Research (DFF) - Denmark'},
              {value: 'Danish National Research Foundation (DNRF) - Denmark', label: 'Danish National Research Foundation (DNRF) - Denmark'},
              {value: 'German Research Foundation (DFG) - Germany', label: 'German Research Foundation (DFG) - Germany'},
              {value: 'Economic and Social Research Council (ESRC) - United Kingdom', label: 'Economic and Social Research Council (ESRC) - United Kingdom'},
              {value: 'Engineering and Physical Sciences Research Council (EPSRC) - United Kingdom', label: 'Engineering and Physical Sciences Research Council (EPSRC) - United Kingdom'},
              {value: 'Estonian Research Council (ETAG) - Estonia', label: 'Estonian Research Council (ETAG) - Estonia'},
              {value: 'European Comission (EC)', label: 'European Comission (EC)'},
              {value: 'European Space Agency (ESA)', label: 'European Space Agency (ESA)'},
              {value: 'Executive Agency for Higher Education, Research, Development and Innovation Funding (UEFISCDI - CNCS) - Romania', label: 'Executive Agency for Higher Education, Research, Development and Innovation Funding (UEFISCDI - CNCS) - Romania'},
              {value: 'Federal Department of Economic Affairs, Education and Research (EAER) - Switzerland', label: 'Federal Department of Economic Affairs, Education and Research (EAER) - Switzerland'},
              {value: 'Federal Ministry of Education and Research (BMBF) - Germany', label: 'Federal Ministry of Education and Research (BMBF) - Germany'},
              {value: 'Finnish Funding Agency for Innovation (TEKES)', label: 'Finnish Funding Agency for Innovation (TEKES)'},
              {value: 'Flanders Innovation & Entrepeneurship (VLAIO) - Belgium', label: 'Flanders Innovation & Entrepeneurship (VLAIO) - Belgium'},
              {value: 'Fonds National de la Recherche Scientifique (FNRS) - Belgium', label: 'Fonds National de la Recherche Scientifique (FNRS) - Belgium'},
              {value: 'Foundation for Fundamental Research on Matter (FOM) - Netherlands', label: 'Foundation for Fundamental Research on Matter (FOM) - Netherlands'},
              {value: 'Foundation for Polish Science (FNP) - Poland', label: 'Foundation for Polish Science (FNP) - Poland'},
              {value: 'General Secretariat for Research and Technology (GSRT) - Greece', label: 'General Secretariat for Research and Technology (GSRT) - Greece'},
              {value: 'Foundation for Science and Technology (FCT) - Portugal', label: 'Foundation for Science and Technology (FCT) - Portugal'},
              {value: 'French National Aerospace Research Center (ONERA) - France', label: 'French National Aerospace Research Center (ONERA) - France'},
              {value: 'French National Research Agency (ANR) - France', label: 'French National Research Agency (ANR) - France'},
              {value: 'French Polar Institute (IPEV) - France', label: 'French Polar Institute (IPEV) - France'},
              {value: 'French Research Institute for Exploitation of the Sea (IFREMER) - France', label: 'French Research Institute for Exploitation of the Sea (IFREMER) - France'},
              {value: 'Fritz Thyssen Foundation (FTS) - Germany', label: 'Fritz Thyssen Foundation (FTS) - Germany'},
              {value: 'General Operational Directorate for Economy, Employment and ResearchDGO6) - Belgium', label: 'General Operational Directorate for Economy, Employment and ResearchDGO6) - Belgium'},
              {value: 'German Aerospace Center (DLR) - Germany', label: 'German Aerospace Center (DLR) - Germany'},
              {value: 'Icelandic Centre for Research (RANNIS) - Iceland', label: 'Icelandic Centre for Research (RANNIS) - Iceland'},
              {value: 'Innovation Fund Denmark', label: 'Innovation Fund Denmark'},
              {value: 'Innovation Fund of the Ministry of Economy of the Slovak Republic (IMSR) - Slovakia', label: 'Innovation Fund of the Ministry of Economy of the Slovak Republic (IMSR) - Slovakia'},
              {value: 'International Science Council (ISC)', label: 'International Science Council (ISC)'},
              {value: 'Irish Research Council (IRC) - Ireland', label: 'Irish Research Council (IRC) - Ireland'},
              {value: 'Israel Science Foundation (ISF) - Israel', label: 'Israel Science Foundation (ISF) - Israel'},
              {value: 'Knowledge Foundation (KKS) - Sweden', label: 'Knowledge Foundation (KKS) - Sweden'},
              {value: 'Knut and Alice Wallenberg Foundation (KAWS) - Sweden', label: 'Knut and Alice Wallenberg Foundation (KAWS) - Sweden'},
              {value: 'La Caixa Foundation (CAIXA) - Spain', label: 'La Caixa Foundation (CAIXA) - Spain'},
              {value: 'Malta Council for Science and Technology (MCST) - Malta', label: 'Malta Council for Science and Technology (MCST) - Malta'},
              {value: 'Max Planck Society for the Advancement of Science (MPG) - Germany', label: 'Max Planck Society for the Advancement of Science (MPG) - Germany'},
              {value: 'Medical Research Council (MRC) - United Kingdom', label: 'Medical Research Council (MRC) - United Kingdom'},
              {value: 'Ministry for Economic Development and Technology (MGRT) - Slovenia', label: 'Ministry for Economic Development and Technology (MGRT) - Slovenia'},
              {value: 'Ministry for Economy and Competitveness (MINECO) - Spain', label: 'Ministry for Economy and Competitveness (MINECO) - Spain'},
              {value: 'Ministry for Education and Scientific Research (MECR) - Romania', label: 'Ministry for Education and Scientific Research (MECR) - Romania'},
              {value: 'Ministry for Education, University and Research (MIUR) - Italy', label: 'Ministry for Education, University and Research (MIUR) - Italy'},
              {value: 'Ministry of Education, Science and Technological Development of Republic of Serbia (MESTD) - Serbia', label: 'Ministry of Education, Science and Technological Development of Republic of Serbia (MESTD) - Serbia'},
              {value: 'Ministry of Higher Education and Research (MESR) - France', label: 'Ministry of Higher Education and Research (MESR) - France'},
              {value: 'Ministry of Science and Education (MSE) - Croatia', label: 'Ministry of Science and Education (MSE) - Croatia'},
              {value: 'National Aeronautics and Space Administration (NASA) - United States', label: 'National Aeronautics and Space Administration (NASA) - United States'},
              {value: 'National Authority for Scientific Research (ANCS) - Romania', label: 'National Authority for Scientific Research (ANCS) - Romania'},
              {value: 'National Centre for Research and Development (NCBiR) - Poland', label: 'National Centre for Research and Development (NCBiR) - Poland'},
              {value: 'National Centre for Scientific Research (CNRS) - France', label: 'National Centre for Scientific Research (CNRS) - France'},
              {value: 'National Centre for Space Studies (CNES) - France', label: 'National Centre for Space Studies (CNES) - France'},
              {value: 'National Council for Scientific and Technological Development (CNPq) - Brazil', label: 'National Council for Scientific and Technological Development (CNPq) - Brazil'},
              {value: 'National Foundation for Research, Technology and Development (OeNFTE) - Austria', label: 'National Foundation for Research, Technology and Development (OeNFTE) - Austria'},
              {value: 'National institute of Agricultural Research (INRA) - France', label: 'National institute of Agricultural Research (INRA) - France'},
              {value: 'National Institute of Health and Medical Research (INSERM) - France', label: 'National Institute of Health and Medical Research (INSERM) - France'},
              {value: 'National Institutes of Health (NIG) - United States', label: 'National Institutes of Health (NIG) - United States'},
              {value: 'National Research Council (CNR) - Italy', label: 'National Research Council (CNR) - Italy'},
              {value: 'National Research Fund (FNR) - Luxembourg', label: 'National Research Fund (FNR) - Luxembourg'},
              {value: 'National Science Center (NCN) - Poland', label: 'National Science Center (NCN) - Poland'},
              {value: 'National Science Foundation (NSF) - United States', label: 'National Science Foundation (NSF) - United States'},
              {value: 'Netherlands Organisation for Scientific Research (NWO) - Netherlands', label: 'Netherlands Organisation for Scientific Research (NWO) - Netherlands'},
              {value: 'Research Council UK (RCUK) - United Kingdo', label: 'Research Council UK (RCUK) - United Kingdo'},
              {value: 'Research and Development Agency (APVV) - Slovakia', label: 'Research and Development Agency (APVV) - Slovakia'},
              {value: 'Research Council of Lithuania (LMT) - Lithuania', label: 'Research Council of Lithuania (LMT) - Lithuania'},
              {value: 'Research Council of Norway (RCN) - Norway', label: 'Research Council of Norway (RCN) - Norway'},
              {value: 'Research Foundation Flanders (FWO) - Belgium', label: 'Research Foundation Flanders (FWO) - Belgium'},
              {value: 'Research Promotion Foundation (RPF) - Cyprus', label: 'Research Promotion Foundation (RPF) - Cyprus'},
              {value: 'Science and Technology Facilities Council (STFC) - United Kingdom', label: 'Science and Technology Facilities Council (STFC) - United Kingdom'},
              {value: 'Science Foundation Ireland (SFI) - Ireland', label: 'Science Foundation Ireland (SFI) - Ireland'},
              {value: 'Scientific Grant Agency (VEGA) - Slovakia', label: 'Scientific Grant Agency (VEGA) - Slovakia'},
              {value: 'Secretariat-General for Investment (SGPI) - France', label: 'Secretariat-General for Investment (SGPI) - France'},
              {value: 'Spanish National Research Council (CSIC) - Spain', label: 'Spanish National Research Council (CSIC) - Spain'},
              {value: 'Swedish Foundation for Strategic Research (SSF) - Sweden', label: 'Swedish Foundation for Strategic Research (SSF) - Sweden'},
              {value: 'Swedish National Space Board (SNSB) - Sweden', label: 'Swedish National Space Board (SNSB) - Sweden'},
              {value: 'Swiss National Science Foundation (SNF) - Switzerland', label: 'Swiss National Science Foundation (SNF) - Switzerland'},
              {value: 'Finnish Funding Agency for Technology and Innovation (TEKES) - Finland', label: 'Finnish Funding Agency for Technology and Innovation (TEKES) -Finland'},
              {value: 'Technology Foundation (STW) - Netherlands', label: 'Technology Foundation (STW) - Netherlands'},
              {value: 'The Danish Council for Independent Research (DDF) - Denmark', label: 'The Danish Council for Independent Research (DDF) - Denmark'},
              {value: 'The Research Council of Norway - Norway', label: 'The Research Council of Norway - Norway'},
              {value: 'UK Research and Innovation (UKRI) - United Kingdom', label: 'UK Research and Innovation (UKRI) - United Kingdom'},
              {value: 'Vienna Science and Technology Fund (WWTF) - Austria', label: 'Vienna Science and Technology Fund (WWTF) - Austria'},
              {value: 'Other', label: 'Other'}
          ]

export const FUNDING_PROGRAM = [
              {value: 'Anti Fraud Information System (AFIS2020)', label: 'Anti Fraud Information System (AFIS2020)'},
              {value: 'European Agricultural Guarantee Fund (after transfers between EAGF and EAFRD) (AGR)', label: 'European Agricultural Guarantee Fund (after transfers between EAGF and EAFRD) (AGR)'},
              {value: 'Net transfer between EAGF and EAFRD (AGRNET)', label: 'Net transfer between EAGF and EAFRD (AGRNET)'},
              {value: 'Asylum, Migration and Integration Fund (AMF)', label: 'Asylum, Migration and Integration Fund (AMF)'},
              {value: 'Rights, equality and citizenship programme (CDF2020)', label: 'Rights, equality and citizenship programme (CDF2020)'},
              {value: 'Connecting Europe Facility (CEF)', label: 'Connecting Europe Facility (CEF)'},
              {value: 'Cohesion Fund (CF)', label: 'Cohesion Fund (CF)'},
              {value: 'Common foreign and security policy (CFSP2020)', label: 'Common foreign and security policy (CFSP2020)'},
              {value: 'Europe for Citizens (CIT2020)', label: 'Europe for Citizens (CIT2020)'},
              {value: 'Competitiveness (more developed regions) (COMPREG)', label: 'Competitiveness (more developed regions) (COMPREG)'},
              {value: 'Consumer programme (CONS)', label: 'Consumer programme (CONS)'},
              {value: 'European Earth Observation Programme (COPERNICUS)', label: 'European Earth Observation Programme (COPERNICUS)'},
              {value: 'Programme for the competitiveness of enterprises and small and medium-sized enterprises (COSME)', label: 'Programme for the competitiveness of enterprises and small and medium-sized enterprises (COSME)'},
              {value: 'Union Civil Protection Mechanism — Member States (CPM_H3)', label: 'Union Civil Protection Mechanism — Member States (CPM_H3)'},
              {value: 'Union Civil Protection Mechanism — Outside EU (CPM_H4)', label: 'Union Civil Protection Mechanism — Outside EU (CPM_H4)'},
              {value: 'Creative Europe programme (CREA)', label: 'Creative Europe programme (CREA)'},
              {value: 'Action programme for customs in the European Union (CUST2020)', label: 'Action programme for customs in the European Union (CUST2020)'},
              {value: 'Development Cooperation Instrument (DCI2020)', label: 'Development Cooperation Instrument (DCI2020)'},
              {value: 'The Union programme for education, training, youth and sport (Erasmus+) (E4A)', label: 'The Union programme for education, training, youth and sport (Erasmus+) (E4A)'},
              {value: 'European Agricultural Fund for Rural Development (after transfers between EAGF and EAFRD) (EAFRD)', label: 'European Agricultural Fund for Rural Development (after transfers between EAGF and EAFRD) (EAFRD)'},
              {value: 'European Agricultural Fund for Rural Development (EAFRD2020)', label: 'European Agricultural Fund for Rural Development (EAFRD2020)'},
              {value: 'European Agricultural Guarantee Fund (EAGF2020)', label: 'European Agricultural Guarantee Fund (EAGF2020)'},
              {value: 'Emergency Aid Reserve (EAR2020)', label: 'Emergency Aid Reserve (EAR2020)'},
              {value: 'Energy projects to aid economic recovery (EERP)', label: 'Energy projects to aid economic recovery (EERP)'},
              {value: 'European Fund for Sustainable Development (EFSD)', label: 'European Fund for Sustainable Development (EFSD)'},
              {value: 'European Fund for Strategic Investments (EFSI)', label: 'European Fund for Strategic Investments (EFSI)'},
              {value: 'European Globalisation Adjustment Fund (EGF2020)', label: 'European Globalisation Adjustment Fund (EGF2020)'},
              {value: 'European Instrument for Democracy and Human Rights (EIDHR2020)', label: 'European Instrument for Democracy and Human Rights (EIDHR2020)'},
              {value: 'Comparison of fingerprints for the effective application of the Dublin Convention (EURODAC2020)', label: 'Comparison of fingerprints for the effective application of the Dublin Convention (EURODAC2020)'},
              {value: 'European Union Solidarity Fund (EUSF2020)', label: 'European Union Solidarity Fund (EUSF2020)'},
              {value: 'European Union Solidarity Fund (EUSF) — Member States (EUSF_H3)', label: 'European Union Solidarity Fund (EUSF) — Member States (EUSF_H3)'},
              {value: 'Fund for European Aid to the Most Deprived (FEAD)', label: 'Fund for European Aid to the Most Deprived (FEAD)'},
              {value: 'Supplementary high flux reactor (HFR) programmes (HFR2015)', label: 'Supplementary high flux reactor (HFR) programmes (HFR2015)'},
              {value: 'Humanitarian aid (HUMA2020)', label: 'Humanitarian aid (HUMA2020)'},
              {value: 'Instrument for Nuclear Safety Cooperation (INSC2020)', label: 'Instrument for Nuclear Safety Cooperation (INSC2020)'},
              {value: 'Instrument for Pre-accession Assistance (IPA2)', label: 'Instrument for Pre-accession Assistance (IPA2)'},
              {value: 'Interoperability Solutions for European Public Administrations (ISA2015)', label: 'Interoperability Solutions for European Public Administrations (ISA2015)'},
              {value: 'Internal Security Fund (ISF)', label: 'Internal Security Fund (ISF)'},
              {value: 'International thermonuclear experimental reactor (ITER)', label: 'International thermonuclear experimental reactor (ITER)'},
              {value: 'Justice programme (JUST)', label: 'Justice programme (JUST)'},
              {value: 'Programme for the Environment and Climate Action (LIFE2020)', label: 'Programme for the Environment and Climate Action (LIFE2020)'},
              {value: 'Macro financial assistance (MFA)', label: 'Macro financial assistance (MFA)'},
              {value: 'Partnership instrument for cooperation with third countries (PI)', label: 'Partnership instrument for cooperation with third countries (PI)'},
              {value: 'Technical assistance and innovative actions (TA_IA)', label: 'Technical assistance and innovative actions (TA_IA)'},
              {value: 'European territorial cooperation (TERRCOOP)', label: 'European territorial cooperation (TERRCOOP)'},
              {value: 'Transition regions (TRANSREG)', label: 'Transition regions (TRANSREG)'},
              {value: 'Visa Information System (VIS2020)', label: 'Visa Information System (VIS2020)'},
              {value: 'Youth employment initiative (specific top-up allocation) (YEI)', label: 'Youth employment initiative (specific top-up allocation) (YEI)'},
              {value: 'Other', label: 'Other'},
]
            
