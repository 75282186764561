import { React, useState, useRef } from 'react'
import axios from 'axios'
import Select from 'react-select'
import validator from 'validator'
import {Button, Grid, TextField, Checkbox, FormControlLabel, Tooltip } from '@material-ui/core'   
import {CgWebsite} from 'react-icons/cg'  
import {AiOutlineInstagram, AiOutlineFacebook, AiOutlineLinkedin, AiOutlineYoutube} from 'react-icons/ai'
import Modal from 'react-modal';
import {AiOutlineQuestion} from 'react-icons/ai'
import {TiDelete} from 'react-icons/ti'
import {SCIENTIFIC_DOMAIN, SCIENTIFIC_SUBDOMAIN}  from './constants.js'
import {optionColours, textfield, modal, divWithBorder, iconLeftFromTextForm, divIconWithText, titleSmall} from './styles.js'
import {URLS} from './util.js'
import { notify } from './notifications.js'
import './App.css'
import Header from './Header'
import CustomAvatar from './CustomAvatar.js'
import Footer from './Footer'


export default function AddProvider() { 
    const [other, setOther] = useState('')
    const [open, setOpen] = useState(false)
    const [proposal, setProposal] = useState('')
    const [sso, setSso] = useState([])
    const ref = useRef(null)
    const [checked, setChecked] = useState(false)
    const [logo, setLogo] = useState('')
    const [first, setFirst] = useState(true)
    const [provider, setProvider] = useState({'name': '', 'abbreviation': '', 'website': '', 'legal_entity': false, 
                                            'legal_status': [], 'description': '', 'logo': '', 'multimedia': [], 
                                            'tags': [], 'lifecycle_status': null, 'certifications': [], 
                                            'hosting_legal_entity': null, 'participating_countries': [], 
                                            'affiliations': [], 'networks': [], 'structure_types': [],
                                            'esfri_domains': [], 'esfri_type': null, 'areas_of_activity': [], 
                                            'societal_grand_challenges': [], 'national_roadmaps': [], 'tax_id': null, 
                                            'company_registration_name': null, 'expertise': [],
                                            'scientific_domain': [], 'scientific_subdomain': [], 'partners': null, 
                                            'yt': null, 'linkedin': null, 'fb': null, 'insta': null, 'main_contact_collaborator': null})
   const handleChangeUrl = (event) => {
        if(event.target.value === "") {

        } else if (validator.isURL(event.target.value)) {
            if (event.target.value.startsWith('http')) {
                provider[event.target.name] = event.target.value
            } else {
                provider[event.target.name] = 'http://' + event.target.value
                }
        } else {
                notify('This is not a valid url.', 'warning')
        }
    }
    
        const handleChange = (event) => {
            if (event.target.value === "" || event.target.value === " ") {
                provider[event.target.name] = null  
            } else {
                provider[event.target.name] = event.target.value
        }}

        const handleChangeLogo = (event) => {
                if (event.target.value === "" || event.target.value === " ") {
                    provider[event.target.name] = null  
                } else {
                    provider[event.target.name] = event.target.value
                    setLogo(event.target.value)
            }}


    const handleChangeList = (value, name) => { 
        var lista = []
        value.map((item, i) => {
            if (item.value === 'Other') { 
                setOther(name)
                setOpen(true)
            }
            lista = [...lista, item.value] 
        })
        provider[name] = lista
    } 


    const handleChangeListOptions = (value) => { 
        var lista = []
        var scientific_subdomain_options = []
        value.map((item, i) => {
            if (item.value === 'Other') {
                setOther('scientific domain')
                setOpen(true)
            }
            lista = [...lista, item.value] 
            scientific_subdomain_options = [...scientific_subdomain_options, {label: item.value, options:  SCIENTIFIC_SUBDOMAIN[item.value]}]
        })
        setSso(scientific_subdomain_options)
        provider[ref.current.props.name] = lista
    }

    const addProvider = async (event) => {
        event.preventDefault()
        provider['logo'] = logo
        if (provider['legal_entity'] === true && provider['logo']) {
                try {
                        const r = await axios.post(URLS.SERVER + '/providers/add/', {...provider}, 
                                                {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                        localStorage.setItem('first', true)
                        window.location.href = URLS.PROVIDER + r.data;
                } catch (e) {
                        try {      
                                notify(e.response.data, 'warning')
                        } catch (error) {
                                console.log(error)
                                if(error.response.status === 403) {
                                        notify('You are not allowed to do this action.', 'danger')
                                } else {
                                        notify('There is no connection to server. Please try again later.', 'danger')
                                }
                        }
                }
        } else if (!provider['logo']) {
                notify('Please add a logo.', 'warning')       
        }
        else {
                notify('Must be legal entity', 'warning')      
        }
    }
    
    return (
        <Grid container style={{height: '100vh'}}> 
                <Header/>
                <Modal isOpen={first} style={modal}>
                        <TiDelete style={{float: 'right', width: 30, height: 30}} onClick={() => {setFirst(false); window.location.href=URLS.FEED}} />
                        <h4 style={{color: 'black'}}>Please consent that you are an authorized representative and confirm that the provider you are adding is a legal entity.</h4>
                        <FormControlLabel control={
                                <Checkbox name='legal_entity' onChange={e => {provider['legal_entity'] = provider['legal_entity']=true; setChecked(!checked); setFirst(false)}}
                                checked={checked ? true : false}/> } label='Consent *' labelPlacement="start"/>

                </Modal> 
                <Grid item xs={false} sm={1} md={1} lg={3}></Grid>
                <Grid item xs={12} sm={9} md={10} lg={7} style={{marginTop: 40}} >
                        <div style={{backgroundColor: 'white', borderRadius: 20, padding: 15, minHeight: 700, maxWidth: 900}}>
                        <div style={divWithBorder}>
                                <span style={titleSmall}> Add provider </span>                        
                        </div>
                        <form onSubmit={addProvider} style={{opacity: first || open ? 0.2 : 1}}>
                                <Grid container>
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                                <TextField variant="outlined" margin="normal" required textAlign='center'
                                                        label="Name" name="name" autoFocus onChange={handleChange} size='small'
                                                        style={textfield} defaultValue={provider['name']}/>  
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                                <TextField variant="outlined" margin="normal" required
                                                                label="Abbreviation" name="abbreviation" style={textfield} size='small'
                                                                autoFocus onChange={handleChange} defaultValue={provider['abbreviation']}/>     

                                        </Grid>
                                        <Grid item xs={10} sm={5} md={5} lg={5}>
                                                <TextField variant="outlined" margin="normal" value={logo} size='small' 
                                                        label="Logo" name="logo" style={{  width: '97%', backgroundColor: '#E8E8E8', borderRadius: '5px',}}
                                                        autoFocus onChange={handleChangeLogo} />
                                        </Grid>
                                        <Grid item xs={false} sm={1} md={1} lg={1}>
                                                <Tooltip title="You can either add logo url or upload from your device.">
                                                        <Button variant='none' style={{height: 100, paddingLeft: 0}}>
                                                                <AiOutlineQuestion size={30}/>
                                                        </Button>
                                                </Tooltip>
                                        </Grid>
                                        <Grid item xs={12} sm={3} md={3} lg={3}>
                                                <div style={{border: '1px dashed black', width: 170, paddingTop: 10, marginBottom: 10}}>
                                                        <CustomAvatar hide={false} logo={logo} setLogo={setLogo}/>   
                                                </div>                                                    
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6} style={{marginTop: 20, marginBottom: 10,}}>
                                                <Select isMulti isClearable={false} name="scientific_domain" ref={ref} 
                                                        onChange={handleChangeListOptions}  options={SCIENTIFIC_DOMAIN}
                                                        styles={optionColours} placeholder='Scientific Domain' />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6} style={{marginTop: 20, marginBottom: 10, }}>
                                                <Select isMulti isClearable={false} name="scientific_subdomain" 
                                                        onChange={(e) => handleChangeList(e, 'scientific_subdomain')} options={sso} 
                                                        styles={optionColours} placeholder='Scientific Subdomain' />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <TextField variant="outlined" margin="normal" required style={{width: '90.5%', color: 'white'}}
                                                        label="Description" name="description" multiline={true}
                                                        autoFocus onChange={handleChange} rows={6}
                                                        defaultValue={provider['description']}/>  
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6} >
                                                <div style={divIconWithText} >
                                                        <CgWebsite style={iconLeftFromTextForm}/>
                                                        <TextField variant="outlined" margin="normal" required size='small'
                                                                label="Website" name="website" style={{width: '80%', backgroundColor: '#E8E8E8'}}
                                                                autoFocus onBlur={handleChangeUrl} defaultValue={provider['website']}/>     
                                                </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6} >
                                                <div style={divIconWithText} >
                                                        <AiOutlineLinkedin style={iconLeftFromTextForm}/>
                                                        <TextField variant="outlined" margin="normal" size='small'
                                                                label="LinkedIn" name="linkedin" style={{width: '80%', backgroundColor: '#E8E8E8'}}
                                                                autoFocus onBlur={handleChangeUrl} defaultValue={provider['linkedin']}/>
                                                </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6} >
                                                <div style={divIconWithText} >
                                                        <AiOutlineFacebook style={iconLeftFromTextForm}/>
                                                        <TextField variant="outlined" margin="normal" size='small'
                                                                label="Facebook" name="fb" style={{width: '80%', backgroundColor: '#E8E8E8'}}
                                                                autoFocus onBlur={handleChangeUrl} defaultValue={provider['fb']}/>      
                                                </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6} >
                                                <div style={divIconWithText} >
                                                        <AiOutlineYoutube  style={iconLeftFromTextForm}/>
                                                        <TextField variant="outlined" margin="normal" size='small'
                                                                label="Youtube" name="yt" style={{width: '80%', backgroundColor: '#E8E8E8'}}
                                                                autoFocus onBlur={handleChangeUrl} defaultValue={provider['yt']}/>    
                                                </div> 
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6} >
                                                <div style={divIconWithText} >
                                                        <AiOutlineInstagram style={iconLeftFromTextForm}/>
                                                        <TextField variant="outlined" margin="normal" size='small'
                                                                label="Instagram" name="insta" style={{width: '80%', backgroundColor: '#E8E8E8'}}
                                                                autoFocus onBlur={handleChangeUrl} defaultValue={provider['insta']}/>     
                                                </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6} > </Grid>
                                        <Button type='submit' variant='outlined' style={{width: 200, height: 40, marginLeft: 'auto', marginRight: 'auto', marginTop: 40}}>
                                                Add 
                                        </Button>
                                </Grid>        
                        </form>
                </div>
                </Grid>
                <Footer/>
        </Grid> 
    )
}