import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Modal from 'react-modal';
import {ThemeProvider, CssBaseline} from '@material-ui/core'   
import axios from 'axios'

import AddResource from './AddResource.js';
import Feed from './Feed'
import AddProvider from './AddProvider.js';
import SignIn from './SignIn.js'
import SignUp from './SignUp.js'
import Provider from './Provider.js'
import ProviderDetails from './ProviderDetails.js'
import ProviderPreviousProfile from './ProviderPreviousProfile.js'
import ProviderProfile from './ProviderProfile.js'
import Resource from './Resource.js'
import ResourceProfile from './ResourceProfile.js'
import ResourcePreviousProfile from './ResourcePreviousProfile.js'
import LandingPage from './LandingPage.js';
import {URLS} from './util'
import Profile from './Profile.js'
import Forgot from './Forgot'
import {theme} from './styles.js'
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';
import ResetPassword from './ResetPassword'
import About from './About'
import Terms from './Terms'
import Privacy from './Privacy'
import Partners from './Partners'


Modal.setAppElement('#root')

function App() { 


  async function isValid() {
    const r = await axios.get(URLS.SERVER + '/auth/',
      {headers: {"Authorization": "Token " + localStorage.getItem('token')}})    
    return r.data
  }
  
  return (
    <ThemeProvider theme={theme}>
    <CssBaseline/>
    <Router>
        { !isValid() || !localStorage.getItem('token') ? 
        <Switch>
          <Route exact path={URLS.FORGOT}>
            <Forgot/>
          </Route>
          <Route exact path="/linkedin" component={LinkedInPopUp} />
          <Route exact path={URLS.SIGNUP}>
              <SignUp/>
          </Route>
          <Route exact path={URLS.SIGNIN}>
            <SignIn/>
          </Route>
          <Route exact path='/reset_password/:uid/:token'>
            <ResetPassword />
          </Route>
          <Route exact path={URLS.ABOUT} component={About}/>
          <Route exact path={URLS.PARTNERS} component={Partners}/>
          <Route exact path={URLS.TERMS} component={Terms}/>
          <Route exact path={URLS.PRIVACY} component={Privacy}/>
          <Route path={URLS.HOME} >
            <LandingPage/>
          </Route>
      </Switch>
      : 
      <Switch>
        <Route exact path={URLS.ONBOARD + '/details/:name'} component={ProviderDetails}/>
        <Route path={URLS.ONBOARD} component={AddProvider}/>
        <Route exact path={URLS.FEED}>
          <Feed/>
        </Route>
        <Route path={URLS.RESOURCES + "/add/:name"}>
          <AddResource/>
        </Route>
        <Route path={URLS.RESOURCES + "/edit/:id"}>
          <ResourceProfile/>
        </Route>
        <Route path={URLS.RESOURCES + "/resource/:id"}>
          <Resource/>
        </Route>
        <Route path={URLS.RESOURCES + '/version/:id/:update/:edit'} component={ResourcePreviousProfile}/>
        <Route exact path={URLS.PROVIDER + ':id'} component={Provider}/>
        <Route path={URLS.PROVIDER + 'version/:id/:update/:edit'} component={ProviderPreviousProfile}/>
        <Route path={URLS.PROVIDER + 'edit/:id'} component={ProviderProfile}/>
        <Route exact path="/user/:id/:value" component={Profile}/>
        <Route exact path={URLS.HOME}>
          <Feed/>
        </Route>
        <Route exact path="/linkedin" component={LinkedInPopUp} />
        <Route exact path={URLS.SIGNUP}>
            <SignUp/>
        </Route>
        <Route exact path={URLS.FORGOT}>
          <Forgot/>
        </Route>
        <Route path='/reset_password'>
          <ResetPassword />
        </Route>
        <Route exact path={URLS.ABOUT} component={About}/>
        <Route exact path={URLS.PARTNERS} component={Partners}/>
        <Route exact path={URLS.TERMS} component={Terms}/>
        <Route exact path={URLS.PRIVACY} component={Privacy}/>
        <Route path={URLS.HOME} >
            <Feed/>
          </Route>
      </Switch>
        }
    </Router>
    </ThemeProvider>
  );
}

export default App;
