import { React, useState, useEffect } from 'react'
import {Grid, Link, Paper, Button, Tooltip, Zoom, Icon } from '@material-ui/core'
import axios from 'axios'
import {notify} from './notifications'
import {RiBookmarkFill, RiBookmarkLine} from 'react-icons/ri'
import GetRenders, {URLS} from './util.js'
import { titleSmaller} from './styles.js'
import { Description } from './Description';
import { Multimedia } from './Multimedia.js';

export default function FeedCard ({item}) {
    const [isFav, setIsFav] = useState() 
    const [isFavProvider, setIsFavProvider] = useState() 

    useEffect(() => {
        if (item.provider) {
            setIsFav(item.isFav)
        } else {
            setIsFavProvider(item.isFav)
        }
    }, [])

    const ProviderIcon = (
        <Icon style={{color: '#E8E8E8', marginLeft: -15, marginBottom: 20, width: 25, height: 10}}>
          <img src="/prov.svg" />
        </Icon>
      );


    const addFavouriteProvider = async (id) => {
        try {
                await axios.post(URLS.SERVER + '/favourites/add_provider/', {'provider_id': id}, 
                                {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                setIsFavProvider(1)
        } catch (e) {
                console.log(e)
                try {            
                notify(e.response.data, 'warning')
                } catch (e) {
                notify('There is no connection to server. Please try again later.', 'danger')
                }
        }

}

    const removeFavouriteProvider = async (id) => {
            try {
                    await axios.post(URLS.SERVER + '/favourites/remove_provider/', {'provider_id': id}, 
                                    {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                    setIsFavProvider(0)
            } catch (e) {
                    try {            
                    notify(e.response.data, 'warning')
                    } catch (e) {
                    notify('There is no connection to server. Please try again later.', 'danger')
                    }
            }
    }

    useEffect(() => {

    }, [isFavProvider, isFav])

    const addFavourite = async (id) => {
        try {
            await axios.post(URLS.SERVER + '/favourites/add/', {'resource_id': id}, 
                            {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
            setIsFav(1)
        } catch (e) {
            console.log(e)
            try {            
                notify(e.response.data, 'warning')
            } catch (e) {
                notify('There is no connection to server. Please try again later.', 'danger')
            }
        }

    }
    
    const removeFavourite = async (id) => {
        try {
            await axios.post(URLS.SERVER + '/favourites/remove/', {'resource_id': id}, 
                            {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
            setIsFav(0)
        } catch (e) {
            try {            
                notify(e.response.data, 'warning')
            } catch (e) {
                notify('There is no connection to server. Please try again later.', 'danger')
            }
        }
    }


    return ( 
        <Grid container style={{width: '95%', marginLeft: 15, padding: 15, maxHeight: 740, marginTop: 20, backgroundColor: 'white'}} component={Paper} elevation={3}>
            <Grid item xs={false} sm={4} md={5} lg={3}>
                <img src={!item.logo ? '/placeholder.jpg' : item.logo } style={{width: 135, marginTop: 20}}/>
            </Grid>
            {item.provider ? //resource
            <> 
                <Grid item xs={10} sm={7} md={6} lg={8} style={{paddingTop: GetRenders().XS ? 0 : 20}}>
                    <Link style={titleSmaller} href={URLS.RESOURCES + '/resource/' + item.uuid} >{item.name} </Link>
                    {GetRenders().XS ? null : 
                    <>
                        <br/>
                        <span style={{fontSize: '0.95rem', }}> {item.tagline}</span> 
                    </>}
                    <br/> 
                    <Button variant='none' startIcon={ProviderIcon} style={{textTransform: 'none', color: 'black', fontSize: '1.1rem'}}
                        href={URLS.PROVIDER + item.provider.uuid}>
                            {item.provider.abbreviation} 
                    </Button>
                    <br/> 
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1}>
                    <Tooltip title='Resource' TransitionComponent={Zoom} placement='bottom' >
                        <img src='/res1.svg' style={{width: 30, height: 30, float: 'right', marginTop: -10}}/>
                    </Tooltip>
                </Grid>
                <Grid item xs={11} sm={11} md={11} lg={11}>
                    <Description value={item.description} profile={false} provider={false} uuid={item.uuid}/>
                    <br/>
                    {item.tags.map((tag, i) => 
                        <Link key={i} > #{tag} </Link>
                    )}
                    {item.multimedia.length !=0 ? 
                    <>
                        <br/> <br/>
                        <Multimedia media={item.multimedia} />
                    </>
                        : null} 
                </Grid>
                <br/>
                <Grid item xs={11} sm={11} md={11} lg={11} style={{marginLeft: -15}} >
                    {isFav ? 
                            <Tooltip title='Remove resource from favourites' TransitionComponent={Zoom} placement='right' style={{width: 40}}>
                                <Button startIcon={<RiBookmarkFill size={25} style={{color: '#0A50C7', marginTop: 4}}/>} onClick={() => removeFavourite(item.uuid)}/>
                            </Tooltip>
                        :
                            <Tooltip title='Add resource to favourites' TransitionComponent={Zoom} placement='right' style={{width: 40}}>
                                    <Button startIcon={<RiBookmarkLine size={25} style={{color: '#0A50C7', marginTop: 4}}/>} onClick={() =>  addFavourite(item.uuid) } />
                            </Tooltip>
                        }
                </Grid>
            </>
            : 
            <>
                <Grid item xs={10} sm={7} md={6} lg={8} style={{paddingTop: GetRenders().XS ? 0 : 20}}>
                    <Link href={URLS.PROVIDER + item.uuid} style={titleSmaller}>
                        {item.abbreviation} 
                    </Link> 
                    {GetRenders().XS ? null : 
                    <>
                        <br/>
                        <span style={{textTransform: 'none', color: 'gray', fontSize: '0.9rem'}}> {item.name}</span> 
                    </>}
                    <br/> 
                </Grid>
                <Grid item xs={1} sm={1} md={false} lg={1}>
                    <Tooltip title='Provider' TransitionComponent={Zoom} placement='bottom' >
                        <img src='/prov.svg' style={{width: 30, height: 30, float: 'right', marginTop: -10}}/>
                    </Tooltip>
                </Grid>
                <Grid item xs={12} sm={11} md={11} lg={11} >
                    <Description value={item.description} profile={false} provider={true} uuid={item.uuid}/> 
                    <br/> 
                    {item.tags.map((tag, i) => 
                        <Link key={i} > #{tag} </Link>
                    )} 
                    {item.multimedia.length !=0 ? 
                    <>
                        <br/> <br/>
                        <Multimedia media={item.multimedia} provider_id={item.uuid}/>
                    </>
                        : null} 
                </Grid>
                <br/>
                <Grid item xs={11} sm={11} md={11} lg={11} style={{marginLeft: -15}} >
                    {isFavProvider ? 
                        <Tooltip title='Remove provider from favourites' TransitionComponent={Zoom} placement='right' style={{width: 40}}>
                            <Button startIcon={<RiBookmarkFill size={25} style={{color: '#0A50C7', marginTop: 4}}/>} onClick={() => removeFavouriteProvider(item.uuid)}/>
                        </Tooltip>
                    :
                        <Tooltip title='Add provider to favourites' TransitionComponent={Zoom} placement='right' style={{width: 40}}>
                                <Button startIcon={<RiBookmarkLine size={25} style={{color: '#0A50C7', marginTop: 4}}/>} onClick={() =>  addFavouriteProvider(item.uuid) } />
                        </Tooltip>
                    }
                </Grid>
            </> }
        </Grid>
    )

}