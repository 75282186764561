import { React, useEffect, useState } from 'react'
import axios from 'axios'
import { titleSmall, blackNormalText } from './styles.js'
import { Button, Grid, } from '@material-ui/core'
import {URLS} from './util.js'
import {notify} from './notifications'


export default function TrendingResources() {
    const [top3, setTop3] = useState([])


    useEffect(() => {
        async function fetch() {
            try {
                const r2 = await axios.get(URLS.SERVER + '/resources/top3/', 
                                    {headers: {"Authorization": "Token " + localStorage.getItem('token')}})                
                setTop3(r2.data)
            } catch (e) {
                try {            
                    notify(e.response.data, 'warning')
                } catch (e) {
                    notify('There is no connection to server. Please try again later.', 'danger')
                }
            }
        }
        fetch()
    }, [])

    
    
    return (
        <div style={{backgroundColor: 'white', borderRadius: '10px', padding: 15}}>
            <div style={{borderBottom: '1px solid', borderColor: '#0A50C7', width: '100%', marginBottom: 10, paddingBottom: 5, marginTop: -5}}>
                <span style={titleSmall}> Trending resources </span> 
            </div>
            <div style={{marginBottom: 5, width: '99.4%'}}>
                {top3.map((item, i) => 
                    <Grid item style={{marginTop: 5}} key={i}>
                        <Button variant='filled' href={URLS.RESOURCES + '/resource/' + item.uuid} style={{marginLeft: -40}}>
                            <img style={{marginRight: 10, width: 60, }} src={item.logo ? item.logo : '/placeholder.jpg'}/>
                            <div>
                                <span style={blackNormalText}> {item.name} </span>
                                {/* <br/>
                                <span style={smallSubtitle}> {item.tagline} </span> */}
                            </div>
                        </Button>
                        <br/>
                    </Grid>
                )}
            </div>
        </div>
    )
}
        