import { React, useEffect, useState } from 'react'
import axios from 'axios'
import qs from 'qs'
import LazyLoad from 'react-lazyload'
import {Button, Grid, FormControl, List, NativeSelect} from '@material-ui/core'    
import {TiArrowSortedDown} from 'react-icons/ti'
import Loader from "react-loader-spinner";

import FeedCard from './FeedCard'
import GetRenders, {URLS} from './util.js'
import {notify} from './notifications'
import ModalFilters from './ModalFilters'
import Footer from './Footer'
import Header from './Header'
import { title } from './styles.js'
import TrendingResources from './TrendingResources'
import MyProviders from './MyProviders'
import MyRecommendations from './MyRecommendations'
import MyProfile from './MyProfile'


export default function Feed() {
    const [sd, setSd] = useState({})
    const [ssd, setSsd] = useState({})
    const [c, setC] = useState({})
    const [sc, setSc] = useState({})
    const [at, setAt] = useState({})
    const [am, setAm] = useState({})
    const [ga, setGa] = useState({})
    const [typeOf, setTypeOf] = useState({})
    const [orderField, setOrderField] = useState('')
    const [open, setOpen] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [showLoader, setShowLoader] = useState(false)  

    const [expandSd, setExpandSd] = useState('')
    const [expandC, setExpandC] = useState('')
    const [content, setContent] = useState([])

    const removeParameters = (fields, type) => {
        if (type === 'sd') {
            {fields.map((item, _) => delete ssd[item.value])}
        }
    }

    const addFilterParameter = (e, type) => {
        if (type === 'sd') {
            setSsd({})
            if (e.target.name in sd) {
                delete sd[e.target.name]
            } else {
                sd[e.target.name] = e.target.name
            }
        } else if (type === 'ssd') {
            setSd({})
            if (e.target.name in ssd) {
                delete ssd[e.target.name]
            } else {
                ssd[e.target.name] = e.target.name
            }
        } else if (type === 'c') {
            setSc({})
            if (e.target.name in c) {
                delete c[e.target.name]
            } else {
                c[e.target.name] = e.target.name
            }
        } else if (type === 'sc') {
            setC({})
            if (e.target.name in sc) {
                delete sc[e.target.name]
            } else {
                sc[e.target.name] = e.target.name
            }
        } else if (type === 'at') {
            if (e.target.name in at) {
                delete at[e.target.name]
            } else {
                at[e.target.name] = e.target.name
            }
        } else if (type === 'am') {
            if (e.target.name in am) {
                delete am[e.target.name]
            } else {
                am[e.target.name] = e.target.name
            }
        } else if (type === 'ga') {
            if (e.target.name in ga) {
                delete ga[e.target.name]
            } else {
                ga[e.target.name] = e.target.name
            }
        } else if (type === 'type') {
            if (e.target.name in typeOf) {
                delete typeOf[e.target.name]
            } else {
                typeOf[e.target.name] = e.target.name
            }
        } else {
            console.log('wrong type')
        }
    }

    const filter = async () => {
        try {
            console.log(orderField)
            setShowLoader(true)
            const r = await axios.get(URLS.SERVER + '/feed/filter/', 
            {
                headers: {"Authorization": "Token " + localStorage.getItem('token')},
                params: {
                  scientific_domain: Object.keys(sd),
                  scientific_subdomain: Object.keys(ssd),
                  category: Object.keys(c),
                  subcategory: Object.keys(sc),
                  access_type: Object.keys(at),
                  access_mode: Object.keys(am),
                  geographical_availability: Object.keys(ga),
                  order_field: orderField,
                  type: Object.keys(typeOf)
                },
                paramsSerializer: params => {
                  return qs.stringify(params)
                }
              }, )
            setContent(r.data)
            setShowLoader(false)
        } catch (e) {
            console.log(e)
            if (e.response.status === 404){
                setContent([])
            } else {
                notify(e.response.data, 'danger')
            }
            setShowLoader(false)
        }
    }


    useEffect(() => {
        async function fetch() {
            try {
                setShowLoader(true)
                const r = await axios.get(URLS.SERVER + '/feed/all/', 
                    {headers: {"Authorization": "Token " + localStorage.getItem('token')}})    
                setContent(r.data)
                setShowLoader(false)
            } catch (e) {
                setShowLoader(false)
                try {            
                    notify(e.response.data, 'warning')
                } catch (e) {
                    notify('There is no connection to server. Please try again later.', 'danger')
                }
            }
        }
        fetch()
    }, [])

    useEffect(() => {
    }, [content])


    return (
        <Grid container style={{opacity: openModal ? 0.3 : 1, flexGrow: 1}}>
            <Header/>
            <Grid item style={{paddingRight: GetRenders().DOWN_SM ? '2vw' : 10, marginTop: 10}} xs={11} sm={12} md={3} lg={3}>
                <MyProfile profile={false}/>
                <br/> 
                {GetRenders().UP_MD ? 
                    <MyProviders two={true}/>
                : null}
            </Grid>
            <ModalFilters open={openModal} setOpen={setOpenModal} filter={filter} addFilterParameter={addFilterParameter}
                    removeParameters={removeParameters} expandSd={expandSd} setExpandSd={setExpandSd} 
                    expandC={expandC} setExpandC={setExpandC} sd={sd} ssd={ssd} c={c} sc={sc} at={at}
                    am={am} ga={ga} type={typeOf}/>
            <Grid item style={{paddingLeft: 30, paddingRight: GetRenders().DOWN_SM ? '2vw' : 10, marginTop: 10}} xs={11} sm={12} md={6} lg={6} >
                <Loader type="Oval" color="#0A50C7" height={100} width={100} visible={showLoader} style={{position: 'absolute', top: '50%', left: '45%'}}/>
                <div style={{backgroundColor: 'white', borderRadius: '10px', padding: 15, height: '100%' }}>
                    <div style={{display: 'inline-block', borderBottom: '1px solid', borderColor: '#0A50C7', paddingLeft: 5, width: '99.4%', marginTop: -8}}>
                        <span style={title}> Feed </span>
                    </div>
                    <FormControl style={{marginLeft: '1vw', top: 2}}>
                        <NativeSelect value={orderField} open={open} onClose={() => setOpen(!open)} onClick={() => setOpen(!open)} 
                                onChange={(e) => {setOrderField(e.target.value) ; filter()}}  disableUnderline style={{maxWidth: 100, marginTop: 15 }}>
                            <option value={''}> Order </option>
                            <option value={'recent'}>Recently added</option>
                            <option value={'quality'}>Quality rating</option>
                            <option value={'communication'}>Communication rating</option>
                            <option value={'price'}>Price rating</option>
                        </NativeSelect>
                    </FormControl > 
                    <Button variant='filled' endIcon={<TiArrowSortedDown style={{color: '#0492C1', marginLeft: 20}} size={15}  />} onClick={() => setOpenModal(true)} 
                            style={{textTransform: 'none', float: 'right', marginTop: 10}} >
                            Filter
                    </Button>
                    <List style={{maxHeight: '90vh', overflow: 'auto', width: '100%', opacity: showLoader ? 0.3 : 1}} >
                    {content.map((item, i) => 
                        <LazyLoad key={i} overflow height={300} throttle> 
                            <FeedCard item={item}/>
                        </LazyLoad>
                    )}
                    </List>
                </div>
                <br/>
            </Grid>
            <Grid item style={{paddingLeft: 30, paddingRight: '2vw', marginTop: 10}} xs={11} sm={12} md={3} lg={3}>
                <TrendingResources />
                <MyRecommendations />
                <br/> 
            </Grid>
            <Footer />
        </Grid>
    )
}