import {TextField } from '@material-ui/core'  
import { textfield} from './styles.js'


export const EditableTextField = (props) => {

    return (
        <TextField variant="outlined" margin="normal" placeholder={props.value}
            InputLabelProps={{ shrink: true, style: {color: 'black'} }} 
            label={props.label} name={props.name} style={props.style ? props.style : textfield} size='small'
            onChange={props.handleChange} /> 
        
    )
} 