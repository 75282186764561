import { Checkbox, Grid, FormControlLabel } from '@material-ui/core'

export default function Filtering(props) {

    return (
        <Grid container>
            {props.dict.map((item, i) => 
                <Grid item xs={6} sm={6} md={6} lg={4} key={i}>
                    <FormControlLabel control={
                            <Checkbox name='is_maincontact' name={item.value} onChange={(e) => {props.addFilterParameter(e, props.type); props.filter()}}
                                disabled={props.disabled ? props.disabled : false} 
                                checked={props.checked_dict[item.value] && props.checked_dict[item.value] !== 'undefined' ? true : false}/> }
                            label={item.value} labelPlacement="end"/>
                </Grid>
            )}
        </Grid>
    )
}