import { React } from 'react'
import { Grid, Link } from '@material-ui/core'  
import {AiOutlineInstagram, AiOutlineFacebook, AiOutlineLinkedin} from 'react-icons/ai'
import './App.css'
import {URLS} from './util'
import Copyright from './Copyright.js'

export default function Footer() {


    return (
        <Grid container style={{marginTop: 20,}}> 
            <Grid container style={{backgroundImage: 'url(/footer.jpg)', backgroundRepeat: 'no-repeat', width: '100%', paddingBottom: 10}}>
                <Grid item xs={3} sm={7} md={9} lg={9} style={{paddingTop: 30}}>
                    <AiOutlineFacebook size={40} onClick={() =>  window.location.href=''} style={{marginRight: 10, color: 'white'}}/>
                    <AiOutlineLinkedin size={40} onClick={() =>  window.location.href=''} style={{marginRight: 10, color: 'white'}}/>
                    <AiOutlineInstagram size={40} onClick={() =>  window.location.href=''} style={{marginRight: 10, color: 'white'}}/>
                </Grid>
                <Grid item xs={4} sm={2} md={1} lg={1} style={{paddingTop: 20, paddingBottom:  20}}>
                    <Link href={URLS.ABOUT} style={{fontSize: '0.9rem', color: 'white'}}> About </Link>
                    <br/>
                    <Link href='' style={{fontSize: '0.9rem', color: 'white'}}> News </Link>
                    <br/>
                    <Link href={URLS.PARTNERS} style={{fontSize: '0.9rem', color: 'white'}}> Partners </Link>                   
                </Grid>
                <Grid item xs={4} sm={3} md={2} lg={2} style={{paddingTop: 20, paddingBottom:  20}}>
                    <Link href='' style={{fontSize: '0.9rem', color: 'white'}}> Help Center </Link>
                    <br/>
                    <Link href={URLS.TERMS} style={{fontSize: '0.9rem', color: 'white'}}> Terms of Use </Link>
                    <br/>
                    <Link href={URLS.PRIVACY} style={{fontSize: '0.9rem', color: 'white'}}> Privacy Policy </Link>
                </Grid>
            </Grid>
            <div style={{width: '100vw', backgroundColor: '#172E65', minHeight: 60}}>
                <Copyright footer={true}/>
            </div>
        </Grid>
    )

}