import {useTheme, useMediaQuery} from '@material-ui/core'  

export default function GetRenders() {
    const theme = useTheme();
    return {
        UP_MD: useMediaQuery(theme.breakpoints.up('md')),
        DOWN_SM: useMediaQuery(theme.breakpoints.down('sm')),
        XS: useMediaQuery(theme.breakpoints.down('xs')),
        LG: useMediaQuery(theme.breakpoints.up('lg')),
        DOWN_MD : useMediaQuery(theme.breakpoints.down('md'))
    }
}


export const URLS = {
    SERVER: 'https://hermoupolis.jnp.gr/server',
    HOME: '/',
    SIGNIN: '/signin',
    SIGNUP: '/signup',
    ONBOARD: '/onboard',
    RESOURCES: '/resources',
    FEED: '/feed',
    PROVIDER: '/provider/',
    USER: '/user',
    FORGOT: '/forgot',
    RESET_PASSWORD: '/reset_password',
    WHY: '/why',
    PARTNERS: '/partners',
    ABOUT: '/about',
    TERMS: '/terms',
    PRIVACY: '/privacy'
}



export const checkPassword = (pass, notify) => {
    if (RegExp('(?=.*[A-Z])').test(pass) && RegExp('(?=.*[1-9])').test(pass)) {
        if(!RegExp('(?=.*[!@$%^&*()_{}/|;:"<>,.?+\\=\\\\\-])').test(pass)){
        if (pass.length < 8) {
            notify('The password must contain at least 8 characters', 'warning')
        } else {
            return true;
        }
        } else {
        notify('The password cannot contain special characters', 'warning')
        }
    } else {
        notify('The password must contain at least one capital letter, one number', 'warning')
    }
    return false;
}

export const checkName = (name, notify, target) => {
    var tar = target.split('_')
    if (tar.length > 1) {
        tar = tar[0] + ' ' + tar[1]
    }
    if(!RegExp('(?=.*[!@$%^&*()_{}/|;:"<>,.?+\\=\\\\\-])').test(name)){
        return true
    }
    notify('The ' + tar + ' cannot contain special characters.', 'warning')
    return false
  }


export const convertToOptionsDict = (dict) => {
    var converted = dict
    Object.entries(converted).forEach(([key, value]) => {
        var all = []
        if (Array.isArray(value)) {
            value.map((item, i) => all = [...all, {label: item, value: item}])
            converted[key] = all
        }
    })
    return converted
}

export const convertToOptions = (lis) => {
    var all = []
    if (lis) {
        lis.map((item, i) => all = [...all, {label: item, value: item, isFixed: true}])
        return all
    }
    return []
}

