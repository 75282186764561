import React from "react";
import { useDropzone } from "react-dropzone";
import {BiCamera} from 'react-icons/bi'
import {Avatar} from '@material-ui/core'

export default function Dropzone({ onDrop, accept, hide, logo }) {

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        maxFiles: 1, onDrop, accept
    });

  return (
    <div {...getRootProps()}>
      <input className="dropzone-input" {...getInputProps()} />
      <div className="text-center">
        {isDragActive ? (
            <>
            </>
        ) : <>
          <BiCamera style={{marginLeft: hide ? '38vw' : 45, top: hide ? -50 : -20}} size={hide ? 30: 25}/>
          { hide ?
            <>
            {logo ?
              <Avatar src={logo} style={{marginLeft: '19vw', marginTop: -30}}/>
              : 
              null}
            </>
          : null}
          </>
        }
      </div>
    </div>
  );
};

