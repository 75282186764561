import { React, useState } from 'react'
import axios from 'axios'
import {Button, Grid, TextField, Paper } from '@material-ui/core'

import {textfield90} from './styles.js'
import {URLS} from './util.js'
import Copyright from './Copyright'
import { notify } from './notifications.js'
import Header from './Header'
import Loader from "react-loader-spinner";
import {title} from './styles.js'


export default function Forgot() {
    const [ email, setEmail ] = useState();
    const [ showLoader, setShowLoader ] = useState(false)

    const submit = async (e)  => {
        e.preventDefault()
        try {
            setShowLoader(true)
            const r =  await axios.get(URLS.SERVER + '/users/is_social/?email=' + email)
            if (!r.data) {
                await axios.post(URLS.SERVER + '/password_reset/users/reset_password/', {'email': email})
                setShowLoader(false)
                window.location.href = URLS.SIGNIN   
            } else {
                notify('You have to change your password at the social provider you used to join wizbee.', 'warning')
                setShowLoader(true)
                window.location.href = URLS.FEED   
            }
        } catch (e) {
            setShowLoader(false)
            try {            
                notify('There is no user with this email.', 'warning')
            } catch (e) {
                notify('There is no connection to server. Please try again later.', 'danger')
            }
        }

    }

    return ( 
        <Grid container style={{height: '100vh'}}>
            <Header hide={true}/>
            <Grid item xs={false} sm={false} md={3} lg={4}></Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} component={Paper} elevation={5} style={{height: '80vh', marginTop: 20, marginBottom: 20, opacity: showLoader ? 0.2 : null}}>
            <div style={{alignItems: 'center'}}>
                <div style={{marginTop: 20, marginBottom: 10}}>
                    <span style={title}> Reset password </span>
                </div>
                <form onSubmit={submit}>
                    <TextField variant="outlined" margin="normal" required style={textfield90}
                        fullWidth label="Email" name="email" size='small'
                        autoComplete="email" autoFocus onChange={e => setEmail(e.target.value)} />
                    <br/> <br/>
                    <Button type="submit" fullWidth variant="contained" style={{width: '90%', marginBottom: 10}} >
                        Reset password
                    </Button>
                    <Grid container style={{marginTop: 40}}>
                        <Grid item xs={12} ms={12} md={12}>
                            <Copyright />
                        </Grid>
                    </Grid>
                    </form>
                </div> 
            </Grid>
            <Loader type="Oval" color="#0A50C7" height={100} width={100} visible={showLoader} style={{top: '50%', left: '48%', position: 'fixed'}}/>
        </Grid>
    )
}