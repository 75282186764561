import { React, useEffect, useState } from 'react'
import axios from 'axios'
import { titleSmall, blackNormalText, smallSubtitle } from './styles.js'
import { Button, Avatar, Grid, List } from '@material-ui/core'
import {URLS} from './util.js'
import {notify} from './notifications'


export default function MyFavourites(props) {
    const [favourites, setFavourites] = useState({'resources': [], 'providers': []})


    useEffect(() => {
        async function fetch() {
            try {
                const r5 = await axios.get(URLS.SERVER + '/favourites/user' ,
                            {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                setFavourites(r5.data)
            } catch (e) {
                try {            
                    notify(e.response.data, 'warning')
                } catch (e) {
                    notify('There is no connection to server. Please try again later.', 'danger')
                }
            }
        }
        fetch()
    }, [])


    useEffect(() => {}, [favourites])
    
    return (
    <>
        {favourites['resources'].length !== 0 || favourites['providers'].length !== 0 ?
            <div style={{height: 685}}>
            {favourites['resources'].length !== 0 ? 
                <>
                <div style={{borderBottom: '1px solid', borderColor: 'black', width: '100%', marginBottom: 10, paddingBottom: 5}}>
                    <span style={titleSmall}> Favourite resources </span> 
                </div>
                <List style={{height: 300, overflow: 'auto', width: '100%'}} >
                    {favourites['resources'].map((fav, i) => 
                        <Grid item style={{marginTop: 5}} key={i}>
                            <Button variant='filled' href={URLS.RESOURCES + '/resource/' + fav.uuid} style={{marginLeft: -40}}>
                                <img style={{marginRight: 10, width: 55, }} src={fav.logo}/>  
                                <span style={blackNormalText}> {fav.name} </span>
                            </Button>
                        </Grid>
                    )} 
                </List>
                </> : null}
            {favourites['providers'].length !== 0 ? 
                <>
                <div style={{borderBottom: '1px solid', borderColor: 'black', width: '100%', marginBottom: 10, paddingBottom: 5}}>
                    <span style={titleSmall}> Favourite providers </span> 
                </div>
                <List style={{height: 300, overflow: 'auto', width: '100%'}} >
                    {favourites['providers'].map((item, i) => 
                        <Grid item style={{marginTop: 5}} key={i}>
                            <Button variant='filled' href={'/provider/' + item.uuid} style={{marginLeft: -40}}>
                                <img style={{marginRight: 10, width: 55, }} src={item.logo}/>   
                                <span style={blackNormalText}> {item.abbreviation} </span>
                            </Button>
                        </Grid>
                    )}
                </List>
                </> : null}
            </div>
        : 
        <span style={smallSubtitle}> No favourites added yet </span>
        }
    </>
    )
}
        