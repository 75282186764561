import { React, useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { Button, Grid, List, Tooltip, Zoom, Tabs, Tab, Link} from '@material-ui/core'  
import Rating from '@material-ui/lab/Rating';
import {CgWebsite} from 'react-icons/cg'
import {TiDelete} from 'react-icons/ti'
import Joyride, { STATUS } from 'react-joyride';
import {AiOutlineInstagram, AiOutlineFacebook, AiOutlineLinkedin, 
        AiOutlineYoutube, AiFillSetting, AiFillStar, AiOutlineStar} from 'react-icons/ai'
import {RiBookmarkFill, RiBookmarkLine, RiStarSFill,} from 'react-icons/ri'
import {FaMapMarker} from 'react-icons/fa'
import {MdEmail} from 'react-icons/md'
import {BsPlusCircle} from 'react-icons/bs'
import ProgressBar from "@ramonak/react-progress-bar";
import Modal from 'react-modal';
import ResourceCard from './ResourceCardProvider'
import axios from 'axios'
import GetRenders, {URLS} from './util.js'
import {notify} from './notifications'
import './App.css'
import Header from './Header'
import Footer from './Footer'
import { StaticMultipleSelect } from './StaticMultipleSelect.js';
import { Description } from './Description.js';
import {OrderResources} from './OrderResources'
import { Multimedia } from './Multimedia.js';
import { titleSmallBlue, title, modal, StyledBadge, divWithBorder,
        avatarContainerProv, titleSmall, tab, smallSubtitle, subtitle} from './styles.js'
import ScientificDomainMap from './ScientificDomainMap.js'


export default function Provider() {
    const params = useParams()
    const [value, setValue] = useState('one')
    const [provider, setProvider] = useState()
    const [resources, setResources] = useState([])
    const [edit, setEdit] = useState(false)
    const [providerContact, setProviderContact] = useState({}) 
    const [providerLocation, setProviderLocation] = useState({}) 
    const [open, setOpen] = useState(false)
    const [quality, setQuality] = useState(null)
    const [price, setPrice] = useState(null)
    const [communication, setCommunication] = useState(null)
    const [isFav, setIsFav] = useState() 
    const [isRated, setIsRated] = useState()
    const [avgRating, setAvgRating] = useState()
    const [completed, setCompleted] = useState(0)
    const [orderField, setOrderField] = useState('')
    const [openOrder, setOpenOrder] = useState(false)  
    const [hasMulti, setHasMulti] = useState(false)
    const hideMobile = GetRenders().XS
    const md = GetRenders().DOWN_MD


    const steps = [
        {
                content: <h2>This is your provider!</h2>,
                spotlightPadding: 5,
                target: '.step1',
                disableBeacon: true
        },
        {
                content: 'Fill more information to increase your profile\'s reach.',
                target: '.step2',
        },
        {
                content: 'Click the settings button to enable \'edit mode\'.',
                target: '.step3',
        },
        {
                content: 'Click here to add new resources.',
                target: '.step4',
        },
        {
                content: 'Add videos or photos to engage with your audience.',
                target: '.step5',
        },
      ]


    useEffect(() => {
        async function fetch() { 
            try {
                const r = await axios.get(URLS.SERVER + '/providers/provider/?id=' + params['id'], 
                                {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                setProvider(r.data)
                if (r.data.multimedia.length !== 0) {
                        setValue('zero')
                        setHasMulti(true)
                }
                const rounded = Math.round(r.data['cnt'] / 0.29) 
                setCompleted(rounded)
                setIsRated(r.data['isRated'])
                setIsFav(r.data['isFav'])
                const r1 = await axios.get(URLS.SERVER + '/providers/resources?id=' + params['id'], {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                setResources(r1.data) 
                const r2 = await axios.get(URLS.SERVER + '/providers/edit/?id=' + params['id'], 
                                {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                setEdit(r2.data)
                const r3 = await axios.get(URLS.SERVER + '/providers_details/by/?id=' + params['id'], 
                        {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                setProviderContact(r3.data['contact'])
                setProviderLocation(r3.data['location'])
                const r4 = await axios.get(URLS.SERVER + '/ratings/by_provider?provider_id=' + params['id'], 
                                {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                setAvgRating(r4.data)
            } catch (error) {
                console.log(error)
                if(error.response.status === 403) {
                        notify('You are not allowed to do this action.', 'danger')
                } else {
                        notify('There is no connection to server. Please try again later.', 'danger')
                }
            }
        }
        fetch()
    }, [])

        useEffect(() => {

        }, [isFav, isRated, resources])

        const addFavouriteProvider = async (id) => {
                try {
                        const r = await axios.post(URLS.SERVER + '/favourites/add_provider/', {'provider_id': id}, 
                                        {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                        setIsFav(1)
                } catch (e) {
                        try {            
                        notify(e.response.data, 'warning')
                        } catch (e) {
                        notify('There is no connection to server. Please try again later.', 'danger')
                        }
                }

        }

        const removeFavouriteProvider = async (id) => {
                try {
                        const r = await axios.post(URLS.SERVER + '/favourites/remove_provider/', {'provider_id': id}, 
                                        {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                        setIsFav(0)
                } catch (e) {
                        try {            
                        notify(e.response.data, 'warning')
                        } catch (e) {
                        notify('There is no connection to server. Please try again later.', 'danger')
                        }
                }
        }

        const addRateProvider = async () => {
                if (quality && price && communication) {
                        try {
                        const r = await axios.post(URLS.SERVER + '/ratings/add_provider/', {'provider_id': provider.uuid,
                                                        'price': price, 'quality': quality, 'communication': communication}, 
                                        {headers: {"Authorization": "Token " + localStorage.getItem('token')}})
                        setIsRated(1)
                        setOpen(false)
                        } catch (e) {
                        try {            
                                notify(e.response.data, 'warning')
                        } catch (e) {
                                notify('There is no connection to server. Please try again later.', 'danger')
                        }
                        }
                } else {
                        notify('Please add all fields.', 'warning')
                }
        }
    
        const changeOrder = async (e) =>  {
            setOrderField(e.target.value)
            try {
                const r = await axios.get(URLS.SERVER + '/resources/order/?query=False&by=' + e.target.value + '&provider_id=' + provider.uuid,
                                {headers: {"Authorization": "Token " + localStorage.getItem('token')}})                
                setResources(r.data)
            } catch (e) {
                console.log(e)
                notify('', '', e.response.status)
            }
        }

        const handleJoyrideCallback = data => {
                const { action, index, status, type } = data;
            
                if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
                        localStorage.setItem('first', false)
                }

        };

        
        if (provider) { 
        return (
                <Grid container style={{maxHeight: '110vh', opacity: open ? 0.3 : 1}}>
                        {(localStorage.getItem('first') === "true" && edit) ? 
                                <div>
                                        <Joyride steps={steps} continuous={true} run={true} showSkipButton={true} scrollToFirstStep={true} autoStart locale={{last: 'Finish!'}}
                                                          callback={handleJoyrideCallback} styles={{options: { overlayColor: '#E8E8E8', primaryColor: '#0A50C7',}, tooltipContainer: {textAlign: 'left'}}}/> 
                                </div>
                        : null}
                <Header/>
                <Modal isOpen={open} onRequestClose={() => setOpen(false)}  style={modal}>
                        <TiDelete style={{float: 'right', width: 30, height: 30}} onClick={() => {setPrice(null); setCommunication(null); setQuality(null); setOpen(false)}}/>
                        <h3 style={{color: 'black'}}>Add your rate</h3>
                        <label> Quality</label>
                        <div style={{marginTop: 10}}>
                                <Rating value={quality} onChange={(e, newValue) => setQuality(newValue)}/>
                        </div>
                        <br/>
                        <label> Price</label>
                        <div style={{marginTop: 10}}>
                                <Rating value={price} onChange={(e, newValue) => setPrice(newValue)}/>
                        </div>
                        <br/>
                        <label> Communication</label>
                        <div style={{marginTop: 10}}>
                                <Rating value={communication} onChange={(e, newValue) => setCommunication(newValue)}/>
                        </div>
                        <div style={{float: 'right'}}>
                                <Button onClick={addRateProvider} variant="outlined" style={{marginTop: 10, width: 70, height: 50, textAlign: 'center', float: 'right'}}> Add </Button>
                        </div>
                </Modal>
                <Grid container style={avatarContainerProv}>
                        <img style={{marginLeft: '2vw', width: 130, marginTop: 5}} src={provider['logo'] ? provider.logo : '/placeholder1.png'}/>
                        <Grid item xs={false} sm={edit ? 5 : 6} md={edit ? 6 : 8} lg={edit ? 7 : 8} >
                                <span style={title} className='step1'> {provider.abbreviation} </span>
                                <br/>
                                {provider.abbreviation !== provider.name ? 
                                <>
                                        <span style={subtitle}> {provider.name}</span> 
                                        <br/> <br/>
                                </>
                                : null} 
                                {provider.linkedin ? 
                                <AiOutlineLinkedin size={25} onClick={() =>  window.location.href=provider.linkedin} style={{marginRight: 10}}/>
                                : null}
                                {provider.fb ?
                                <AiOutlineFacebook size={25} onClick={() =>  window.location.href=provider.fb} style={{marginRight: 10}}/>
                                : null}
                                {provider.insta ? 
                                <AiOutlineInstagram size={25} onClick={() =>  window.location.href=provider.insta} style={{marginRight: 10}}/>
                                : null}
                                {provider.yt ?
                                <AiOutlineYoutube size={25} onClick={() =>  window.location.href=provider.yt} style={{marginRight: 10}}/>
                                : null}
                        </Grid>
                        {!edit ?
                                <Grid item xs={false} sm={3} md={2} lg={2} >
                                        {isRated ? 
                                                <Tooltip title='Thank you for rating this provider' TransitionComponent={Zoom} placement='bottom' style={{width: 40}}>
                                                        <Button variant='none' startIcon={<AiFillStar size={25} style={{marginLeft: 20, marginTop: 4, color: '#FF8300'}}/>} />
                                                </Tooltip>
                                                
                                        : 
                                                <Tooltip title='Rate provider' TransitionComponent={Zoom} placement='right-end' style={{width: 40}}>
                                                        <Button variant='none' startIcon={<AiOutlineStar size={25} style={{marginLeft: 20, marginTop: 4, color: '#FF8300'}}/>} onClick={() => setOpen(true)}/>
                                                </Tooltip>}
                                        {isFav ? 
                                                <Tooltip title='Remove provider from favourites' TransitionComponent={Zoom} placement='bottom' style={{width: 40}}>
                                                        <Button variant='none' startIcon={<RiBookmarkFill size={25} style={{color: '#0A50C7', marginTop: 4}}/>} onClick={() => removeFavouriteProvider(provider.uuid)} />
                                                </Tooltip>
                                        :
                                                <Tooltip title='Add provider to favourites' TransitionComponent={Zoom} placement='bottom' style={{width: 40}}>
                                                        <Button variant='none' startIcon={<RiBookmarkLine size={25} style={{color: '#0A50C7', marginTop: 4}}/>} onClick={() =>  addFavouriteProvider(provider.uuid) } />
                                                </Tooltip>
                                        }
                                </Grid>
                        : 
                        <Grid item xs={false} sm={false} md={4} lg={3} >
                                <div style={{display: hideMobile ? 'none' : null}}>
                                        <Tooltip title='Add more information to increase the provider performance' TransitionComponent={Zoom} placement='top'>
                                                <Button variant='none' >
                                                        <ProgressBar completed={completed} width={200} height={20} bgColor='#0A50C7' className='step2'/>
                                                </Button>
                                        </Tooltip>   
                                        <Tooltip title='Edit provider' TransitionComponent={Zoom} placement='top' >
                                                <Button variant='none' endIcon={ <AiFillSetting size={25} style={{color: 'gray'}} /> } style={{minWidth: 0, padding: 0}}
                                                        onClick={() =>  window.location.href=URLS.PROVIDER + 'edit/' + provider['uuid']} className={'step3'}/> 
                                        </Tooltip>        
                                </div> 
                        </Grid> }
                </Grid>
                <Grid item xs={11} sm={11} md={5} lg={5} style={{paddingBottom: 10, marginRight: 20}}>
                        <Description value={provider.description} profile={true}/>
                        <br/>
                        {provider.tags.length !== 0 ?
                                <>
                                        {provider.tags.map((tag, i) => 
                                                <Link key={i} > #{tag} </Link>
                                        )} 
                                        <br/> <br/>
                                </>
                        :null}
                        <Grid container style={{backgroundColor: 'white', borderRadius: '10px'}}>
                                <Tabs value={value} onChange={(e, newValue) => setValue(newValue)} variant='scrollable'
                                        style={{float: 'right', borderLeft: `1px solid`, borderColor: '#E8E8E8'}}
                                                orientation='horizontal'  textColor="secondary" >
                                        {hasMulti ? 
                                                <Tab value="zero" label="Photos & videos" style={tab}/>
                                        : null}
                                        <Tab value="one" label="About" style={tab}/>
                                        {provider['partners'] || provider['participating_countries'].length !== 0 || provider['affiliations'].length !== 0 
                                                || provider['networks'].length !== 0  || provider['areas_of_activity'].length !== 0 || provider['esfri_domains'].length !== 0 ? 
                                                <Tab value="two" label="Other" style={{textTransform: 'none'}}/>
                                        : null}
                                </Tabs>
                                <Grid item xs={12} sm={12} md={12} lg={12} style={{minHeight: 430, paddingBottom: 20}}>
                                        {value === 'zero' ?
                                                <div style={{marginLeft: -30}}>
                                                        <Multimedia media={provider.multimedia} provider_id={provider.uuid}/>
                                                </div>
                                                : null}
                                        {value === 'one' ?
                                                <Grid container style={{backgroundColor: 'white', borderRadius: '10px', padding: 5, marginLeft: -30}}>
                                                        {provider.scientific_domain.length !== 0 ?
                                                                <Grid container>  
                                                                        <Grid item xs={12} ms={12} md={12} lg={12} >  
                                                                                <span style={titleSmallBlue}>Scientific domain</span>
                                                                        </Grid>
                                                                        {provider.scientific_domain.map((item, i) => 
                                                                                <Grid item xs={12} ms={3} md={3} lg={3}>  
                                                                                        <ScientificDomainMap dm={item} key={i}/>
                                                                                </Grid>
                                                                        )}
                                                                </Grid>
                                                        : null}
                                                        {provider.scientific_subdomain.length !== 0 ?
                                                                <Grid item xs={12} ms={12} md={12} lg={12} >  
                                                                        <StaticMultipleSelect value={provider.scientific_subdomain} title='Scientific subdomain' />
                                                                </Grid> 
                                                        : null}
                                                        {provider['expertise'].length !== 0 ? 
                                                        <Grid item xs={12} sm={6} md={6} lg={6} >
                                                                <StaticMultipleSelect value={provider.expertise} title='Expertise' />        
                                                        </Grid>
                                                        : null}
                                                        {provider['certifications'].length !== 0 ? 
                                                        <Grid item xs={12} sm={6} md={6} lg={6} >
                                                                <StaticMultipleSelect value={provider.certifications} title='Certifications' />                   
                                                        </Grid>
                                                        : null}
                                                </Grid>
                                        : null}  
                                        {value === 'two' ? 
                                                <Grid container style={{backgroundColor: 'white', borderRadius: '10px', padding: 5, marginLeft: -30}}>
                                                        {provider['partners'] ? 
                                                        <Grid item xs={12} sm={12} md={12} lg={12} >
                                                                <StaticMultipleSelect value={[provider.partners]} title='Partners' />   
                                                        </Grid>
                                                        : null}
                                                        {provider.participating_countries.length !== 0 ? 
                                                        <Grid item xs={12} sm={12} md={12} lg={12} >
                                                                <StaticMultipleSelect value={provider.participating_countries} title='Participating countries' />
                                                        </Grid>
                                                        : null}
                                                        {provider['affiliations'].length !== 0 ? 
                                                        <Grid item xs={12} sm={12} md={12} lg={12} >
                                                                <StaticMultipleSelect value={provider.affiliations} title='Affiliations' />
                                                        </Grid>
                                                        : null}
                                                        {provider['networks'].length !== 0 ? 
                                                        <Grid item xs={12} sm={12} md={12} lg={12} >
                                                                <StaticMultipleSelect value={provider.networks} title='Networks' />
                                                        </Grid>
                                                        : null}
                                                        {provider['areas_of_activity'].length !== 0 ? 
                                                        <Grid item xs={12} sm={6} md={6} lg={6} >
                                                                <StaticMultipleSelect value={provider.areas_of_activity} title='Areas of activity' />
                                                        </Grid>
                                                        : null}
                                                        {provider['esfri_domains'].length !== 0 ? 
                                                        <Grid item xs={12} sm={6} md={6} lg={6} >
                                                                <StaticMultipleSelect value={provider.esfri_domains} title='Esfri domains' />
                                                        </Grid>
                                                        : null}
                                                </Grid>
                                        : null}
                                </Grid>
                        </Grid>
                </Grid>
                <Grid item xs={11} sm={11} md={6} lg={5} >
                        < >
                                {resources.length !== 0  ? 
                                        <div style={{backgroundColor: 'white', borderRadius: 10, padding: 5, marginTop: 0, height: 610}}>
                                                <div style={divWithBorder}>
                                                        <span style={titleSmall}> Resources </span> 
                                                        {edit ? 
                                                                <Tooltip title='Add new resource' TransitionComponent={Zoom} placement='top' style={{height: 30}}>
                                                                        <Button variant='none' startIcon={<BsPlusCircle size={25} style={{marginTop: -5, color: '#FF8300'}}/>} 
                                                                                onClick={() => window.location.href=URLS.RESOURCES + '/add/' + provider['name']}/>
                                                                </Tooltip>
                                                        : null}
                                                        <OrderResources open={openOrder} orderField={orderField} changeOrder={changeOrder} setOpen={setOpenOrder}/>
                                                </div>
                                                <List style={{maxHeight: 520, width: '98%',  overflow: 'auto'}} >
                                                        {resources.map((item, i) =>     
                                                                <ResourceCard key={i} item={item} edit={edit} />
                                                        )} 
                                                </List>
                                        </div>
                                :
                                <div style={{backgroundColor: 'white', borderRadius: '10px 10px 10px 10px', padding: 5, marginTop: 0, height: 550}}>
                                        <div style={divWithBorder}>
                                                <span style={titleSmall}> Resources </span> 
                                        </div>
                                        <div style={{marginLeft: 10}}>
                                                <span style={smallSubtitle}> No resources added </span>
                                                <br/> 
                                                {edit ? 
                                                        <Button variant='contained' onClick={() => window.location.href=URLS.RESOURCES + '/add/' + provider['name']}
                                                                style={{borderRadius: '0 20px 20px 20px', height: 25, marginTop: 5, width: 150}} className={'step4'}>
                                                                Add resource
                                                        </Button>
                                                : null}
                                        </div>
                                </div>
                                }
                        </>
                </Grid>
                <Grid item xs={11} sm={11} md={5} lg={5} style={{paddingBottom: 10, marginRight: 20}}>
                        <Grid container style={{backgroundColor: 'white', borderRadius: 10, minHeight: 100, paddingBottom: 10}}>
                                <div style={{borderBottom: '1px solid', borderColor: '#0A50C7',  width: '96%',  marginTop: 15, marginBottom: 10, marginLeft: 10,
                                        paddingBottom: 0}}>
                                        <span style={titleSmall}> Contact information </span> 
                                </div>
                                <Button variant='filled' startIcon={<CgWebsite style={{color: '#0492C1', width: '1.8vw', height: '1.8vw', maxWidth: 50,
                                                                                        minWidth: 25, minHeight: 25, }}/>} 
                                        style={{textTransform: 'none', marginLeft: 10}} onClick={() =>  window.open(provider.website, '_blank' )}>
                                        {provider.website.split('//').slice(1)}
                                </Button>
                                {Object.keys(providerContact).length !== 0 ? 
                                 <Button variant='filled' startIcon={<MdEmail style={{color: '#0492C1', width: '1.8vw', height: '1.8vw', maxWidth: 50,
                                                                        minWidth: 25, minHeight: 25, }}/>} 
                                        style={{textTransform: 'none', marginLeft: 10}} href={'mailto:' + providerContact['email']}>
                                        {providerContact['email']}
                                        </Button>
                                : null}
                                {Object.keys(providerLocation).length !== 0 ?
                                <Button variant='filled' startIcon={<FaMapMarker style={{color: '#0492C1', width: '1.8vw', height: '1.8vw', maxWidth: 50,
                                                                minWidth: 25, minHeight: 23, }}/>} 
                                                style={{textTransform: 'none', marginLeft: 10}} >
                                        {providerLocation['city'] + ', ' + providerLocation['country']} 
                                </Button>
                                : null}
                        </Grid>
                </Grid>
                {avgRating && avgRating['providers_cnt'] !== 0 ? 
                <Grid item xs={11} sm={11} md={6} lg={5} >
                        <Grid container style={{backgroundColor: 'white', borderRadius: 10, minHeight: 100, paddingBottom: 10}}>
                                <div style={divWithBorder}>
                                        <span style={titleSmall}> Provider's rating </span> 
                                </div>
                                <Grid item xs={12} sm={12} md={12} lg={4} style={{marginLeft: -15, paddingBottom: 10}}>
                                        <span style={{fontSize: '0.875rem', marginRight: md ? 120 : '4vw'}}>Quality</span>
                                        <StyledBadge badgeContent={avgRating['quality__avg']} >
                                                <RiStarSFill style={{color: '#FF8300'}} size={20}/>
                                        </StyledBadge>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={4} >
                                        <span style={{fontSize: '0.875rem', marginRight: md ? 140 : '5vw', marginLeft: md ? -15 : null}}>Price</span>
                                        <StyledBadge badgeContent={avgRating['price__avg']}>
                                                <RiStarSFill style={{color: '#FF8300'}} size={20}/>
                                        </StyledBadge>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={4} >
                                        <span style={{fontSize: '0.875rem', marginRight: md ? 60: '4vw', marginLeft: md ? -15 : null}}>Communication</span>
                                        <StyledBadge badgeContent={avgRating['communication__avg']} style={{marginRight: 20, }}>
                                                <RiStarSFill style={{color: '#FF8300'}} size={20}/>
                                        </StyledBadge>
                                </Grid>
                        </Grid>
                </Grid>
                        : null}
                <Footer/>
        </Grid>
        )
        } else {
                return null
        }
}
    

